<h1 class="title">{{ "ImpactedPayments.Header" | translate }}</h1>
<div>
  <p class="description">
    {{ "ImpactedPayments.Detail" | translate }} <a *ngIf="!gDisputeForm?.isPrePop">{{ "ImpactedPayments.CSV.UploadClick" | translate }}<a class="as-button" (click)="showUploadModal()">{{ "ImpactedPayments.CSV.Here" | translate }}</a></a>
  </p>
</div>

<div *ngIf="bUploadModal" class="pop-up-message" >
  <button class="close-btn" (click)="closeUploadModal()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="description">
    <p>{{ "ImpactedPayments.CSV.UploadInfo" | translate }}<a class="as-button" (click)="exportCSV('TransactionModel', arrayCSV)">
      {{ "ImpactedPayments.CSV.Here" | translate }}</a>{{ "ImpactedPayments.CSV.UploadInfo2" | translate }}</p>
  </div>
  <input #fileUploadSimple [accept]="'.csv'" type="file" onclick="this.value = null"
    (change)="importDataFromCSV($event)" hidden="true">
  <button class= "next-btn" (click)="fileUploadSimple.click()"> {{ "ImpactedPayments.CSV.UploadButton" | translate }}</button>
  
  <div class = "csv-erros-list">
    <div *ngFor="let error of csvErrors">
      <p>{{ error | translate }}</p>
    </div>
  </div>
</div>

<mat-accordion>
  <div>
    <app-purchase-device
      *ngFor="let pd of gDisputeForm?.purchaseDevices"
      [purchaseDevice]="pd"
      (removePD)="deletePurchaseDevice($event)"
      (updateStatePD)="updateState()"
    ></app-purchase-device>
  </div>
</mat-accordion>

<button class="add-btn" (click)="newPurchaseDevice()" *ngIf="!gDisputeForm?.isPrePop">
  <mat-icon>add</mat-icon>{{ "ImpactedPayments.PaymentMethod.PaymentMethodLabel" | translate }}
</button>