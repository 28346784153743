import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { Common } from "src/app/utils/common";
import Swal from "sweetalert2";
import { NavigationEnum } from "../../globalvar";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { MoneyCodeAuthShared } from "src/app/models/purchase-device/money-code-auth-shared.model";
import { TranslateService } from "@ngx-translate/core";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.MC_CREDENTIAL_SHARED;
/**
 * Represents the condition for a step in the process.
 * The step condition is set to "MONEYCODE".
 */
const stepCondition = "MONEYCODE";

@Component({
  selector: "app-money-code-credentials-share",
  templateUrl: "./money-code-credentials-share.component.html",
  styleUrls: ["./money-code-credentials-share.component.scss"],
})
/**
 * MoneyCodeCredentialsShareComponent is a component responsible for handling the sharing of money code credentials.
 * It provides functionality to share the money code credentials and update the navigation information.
 *
 * @remarks
 * This component depends on the following services:
 * - ChangeDetectorRef: Used to detect changes in the component's view.
 * - ProgressBarService: Used to update the progress bar information.
 * - NavigationService: Used to update the navigation information.
 * - FormBuilder: Used to create and manage the form controls.
 * - DisputeFormService: Used to retrieve and update the dispute form data.
 * - TranslateService: Used for translating text.
 *
 */
export class MoneyCodeCredentialsShareComponent implements OnInit {
  yesShared: boolean | undefined;

  mcAuthShared: MoneyCodeAuthShared = {} as MoneyCodeAuthShared;
  mcSharedForm: FormGroup;
  canContinue: boolean = false;
  df: DisputeForm = {} as DisputeForm;
  navInfo: NavigationInfo = new NavigationInfo();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _fb: FormBuilder,
    private _dfService: DisputeFormService,
    private _translate: TranslateService
  ) {
    //pull service dispute form
    this.df = this._dfService.getDisputeForm();
    this.updateProgressBar(stepCondition);
    //local var to store report object and send to service with new/updated values
    this.mcAuthShared = this.df?.moneyCodeAuthShared;

    this.mcSharedForm = this._fb.group({
      //id: new FormControl(this.report.id ?? crypto.randomUUID()),
      mcSharedDetail: new FormControl(this.mcAuthShared.sharedDetail, { validators: [Validators.required] }),
    });
  }

  /**
   * Initializes the component.
   *
   * @remarks
   * This method is called when the component is being initialized.
   * It checks if `mcAuthShared` and `mcAuthShared.sharedDetail` are truthy and have a non-empty value.
   * If so, it sets `yesShared` to `true` and checks if `mcSharedForm` is valid to set `canContinue`.
   * If `mcAuthShared` is truthy but `mcAuthShared.shared` is falsy, it sets `yesShared` to `mcAuthShared.shared`.
   *
   * It also subscribes to the `statusChanges` event of the `mcSharedDetail` form control and debounces the callback function by 300 milliseconds.
   * The callback function calls the `checkForNext` method.
   *
   * Finally, it calls the `checkForNext` method.
   */
  ngOnInit(): void {
    if (this.mcAuthShared && this.mcAuthShared.sharedDetail && this.mcAuthShared.sharedDetail.trim().length > 0) {
      this.yesShared = true;
      this.canContinue = this.mcSharedForm.valid;
    } else if (this.mcAuthShared) {
      this.yesShared = this.mcAuthShared.shared;
    }
    this.mcSharedForm?.get("mcSharedDetail")?.statusChanges.subscribe(
      Common.debounce(() => {
        this.checkForNext();
      }, 300)
    );
    this.checkForNext();
  }
  /**
   * Lifecycle hook that is called after every check of a component's view.
   * It is called immediately after the `ngAfterViewInit` and `ngAfterContentChecked` lifecycle hooks.
   * Use this hook to detect and respond to changes that occur after the view has been checked.
   */
  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }
  /**
   * Updates the progress bar based on the given condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.df.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.MCCredentialAccess",
        nextStepName: DFUtils.getNextRouteText(this.df.lineOfBusiness as string, currentStep) as string,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Checks if the mcSharedForm is valid and sets the canContinue flag accordingly.
   * Updates the shared and sharedDetail properties of mcAuthShared.
   * Updates the canContinue and nextRoute properties of navInfo.
   * Updates the moneyCodeSharedDetails using the _dfService.
   */
  checkForNext() {
    if (
      (this.mcSharedForm.valid)
    ) {
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }

    this.mcAuthShared.shared = this.yesShared;
    this.mcAuthShared.sharedDetail = this.mcSharedForm.get("mcSharedDetail")?.value;
    this.navInfo.canContinue = this.canContinue;
    this.navInfo.nextRoute = DFUtils.getRoute(this.df.lineOfBusiness as string, NavigationEnum.MC_CREDENTIAL_SHARED) as string;
    this._navigationService.updateNavigationInfo(this.navInfo);
    this._dfService.setMoneyCodeSharedDetails(this.mcAuthShared);
  }
}
