import { Component, OnInit } from "@angular/core";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { NavigationEnum, eProductType } from "../../globalvar";
import { Router } from "@angular/router";
import { DFUtils } from "../../../utils/dispute-form.utils";
import { NavigationService } from "src/app/services/navigation.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { TranslateService } from "@ngx-translate/core";
import { Subject, fromEvent, takeUntil } from "rxjs";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.PRODUCT_SELECTION;
/**
 * Represents the component for selecting a product.
 */
@Component({
  selector: "app-product-selection",
  templateUrl: "./product-selection.component.html",
  styleUrls: ["./product-selection.component.scss"],
})
export class ProductSelectionComponent implements OnInit {
  productType: eProductType | undefined;
  canContinue = false;
  disputeForm: DisputeForm;
  navInfo: NavigationInfo = new NavigationInfo();
  // font awesome icons
  faCreditCard = faCreditCard;
  faMoneyBillTransfer = faMoneyBillTransfer;

  // ENUMS!
  fleetCard = eProductType.FLEETCARD;
  smartfundsCard = eProductType.SMARTFUNDS;
  moneyCode = eProductType.MONEYCODE;
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _router: Router,
    private _translate: TranslateService
  ) {
    this.navInfo.showBack = false;
    this._navigationService.updateNavigationInfo(this.navInfo);

    //get the value from the disputeForm service
    this.disputeForm = this._dfService.getDisputeForm();

    //set value and calculate 'canContinue'
    if (this.disputeForm.productType != null) {
      this.productType = this.disputeForm.productType;
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }

    history.pushState(null, "");
    fromEvent(window, "popstate")
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, "");
        //this.showError = true;
      });
  }

  /**
   * Initializes the component.
   *
   * This method is called when the component is initialized.
   * It updates the navigation and progress bar.
   */
  ngOnInit(): void {
    //need to call so navigation is updated
    //this.canProceed();

    this.updateProgressBar("INITIAL", null);
  }
  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It is used to perform any necessary cleanup logic, such as unsubscribing from observables.
   */
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  /**
   * Updates the progress bar based on the given condition and next path.
   *
   * @param condition - The condition to determine the next step.
   * @param nextPath - The next path to navigate to (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.disputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    let nextStep =
      condition != undefined && condition != "INITIAL"
        ? (DFUtils.getNextRouteText(this.disputeForm.lineOfBusiness as string, currentStep, this.productType) as string)
        : "PageName.SelectOption";
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.ProductSelection",
        nextStepName: nextStep,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }

  /**
   * Checks if the product type is not null and updates the dispute form accordingly.
   * Updates the navigation information and progress bar based on the product type.
   */
  canProceed() {
    if (this.productType != null) {
      this.disputeForm.productType = this.productType;
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }
    this.navInfo.canContinue = this.canContinue;
    this.navInfo.nextRoute = DFUtils.getRoute(this.disputeForm.lineOfBusiness as string, currentStep, this.productType) as string;
    this._navigationService.updateNavigationInfo(this.navInfo);
    this.updateValue();
    this.updateProgressBar(this.productType, this.navInfo.nextRoute as NavigationEnum);
  }

  /**
   * Updates the value of the dispute form's product type and sets the updated form using the dispute form service.
   */
  private updateValue() {
    this.disputeForm.productType = this.productType;
    this._dfService.setDisputeForm(this.disputeForm);
  }
}
