<h1 class="title">{{ "ImpactedPayments.Header" | translate }}</h1>
<div>
  <p class="description">
    {{ "ImpactedPayments.Detail" | translate }}
  </p>
</div>

<mat-accordion>
  <div>
    <app-purchase-device
      *ngFor="let pd of gDisputeForm?.purchaseDevices"
      [purchaseDevice]="pd"
      (removePD)="deletePurchaseDevice($event)"
      (updateStatePD)="updateState()"
    ></app-purchase-device>
  </div>
</mat-accordion>

<button class="add-btn" (click)="newPurchaseDevice()" *ngIf="!gDisputeForm?.isPrePop">
  <mat-icon>add</mat-icon>{{ "ImpactedPayments.PaymentMethod.PaymentMethodLabel" | translate }}
</button>