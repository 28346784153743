import { FraudType, LineOfBusiness, eProductType } from "../../dispute-form-module/globalvar";
import { Transaction as Transaction } from "./transaction.model";

export class PurchaseDevice {
  id = crypto.randomUUID();
  cardNumber: string = ""; //last 5 only. ever. period.
  cardholderName: string = "";
  cardholderFirst: string = "";
  cardholderLast: string = "";
  vehicleAssetUnitDriverId!: string;
  dateAbuseBegan!: Date;
  dateAbuseEnded!: Date;
  amountOfAbuse!: number;
  purchaseDeviceType!: eProductType; //not necessary, but easier to implement AND im sure it'll be useful in a future iteration
  newCardNumber!: number;

  transactions: Transaction[] = [];
  isValid: boolean = false;

  //used by impacted payments component (and children) for conditional field labels
  lineOfBusiness!: LineOfBusiness;
  fraudType?: FraudType;

  isPrePop: boolean = false;
  cardHolderNameAppliesChanged: boolean = false;
  requiredFieldMissing: boolean = false;
  constructor() {}
}
