import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DisputeForm } from "../models/dispute-form.model";
import { PurchaseDevice } from "../models/purchase-device/purchase-device.model";
import { Suspect } from "../models/purchase-device/suspect.model";
import { LawEnforcementDetails } from "../models/purchase-device/law-enforcement-details.model";
import { FraudType, LineOfBusiness } from "../dispute-form-module/globalvar";
import { MoneyCodeAuthShared } from "../models/purchase-device/money-code-auth-shared.model";
import { SFDisputeDTO } from "../models/sf-dispute.dto";
import { EmployeeStatus } from "../models/purchase-device/employee-status.model";
import { PhysicalCardDetails } from "../models/physicalCardDetails.model";

@Injectable({
  providedIn: "root",
})
export class DisputeFormService {
  private _isValid$ = new BehaviorSubject<boolean>(false);
  isValid$ = this._isValid$.asObservable();
  private _sfDisputeForm$ = new BehaviorSubject<DisputeForm>({} as DisputeForm);
  sfDisputeForm$ = this._sfDisputeForm$.asObservable();

  constructor() {
    let df = new DisputeForm();
    //df.generateTestData();
    this._sfDisputeForm$.next(df);
  }

  setValid(valid: boolean) {
    this._isValid$.next(valid);
  }

  getValid(): boolean {
    return this._isValid$.getValue();
  }

  setSFInfo(sfInfo: SFDisputeDTO) {
    //make sure we have the required values
    this._sfDisputeForm$.value.accountName = sfInfo.accountName ? sfInfo.accountName : ""; // default to empty string
    this._sfDisputeForm$.value.accountNumber = sfInfo.accountNumber;
    this._sfDisputeForm$.value.lineOfBusiness = sfInfo.platformLob as LineOfBusiness;
    this._sfDisputeForm$.value.referenceId = sfInfo.caseNumber;
    //this._sfDisputeForm$.value.accountCurrency = sfInfo.accountCurrencyCode ? sfInfo.accountCurrencyCode : "USD"; //we aren't using this

    this._sfDisputeForm$.value.contactEmailAddress = sfInfo.contactEmail;

    //not used but required for the dispute submit
    this._sfDisputeForm$.value.contactFirstName = sfInfo.contactFirstName ? sfInfo.contactFirstName : ""; // default to empty string - this passes through from salesforce -> angular -> RP -> salesforce
    this._sfDisputeForm$.value.contactLastName = sfInfo.contactLastName ? sfInfo.contactLastName : ""; // default to empty string - this passes through from salesforce -> angular -> RP -> salesforce

    this._sfDisputeForm$.value.tmxSessionId;
    this._sfDisputeForm$.value.tmxResult = "n/a";
    this._sfDisputeForm$.value.tmxValidated = false;

    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setDisputeForm(info: DisputeForm) {
    this._sfDisputeForm$.next(info);
  }

  getDisputeForm(): DisputeForm {
    return this._sfDisputeForm$.getValue();
  }

  clearDisputeForm() {
    let newDf = new DisputeForm();
    //keep the customer's preferred language -- add logic for selected language
    newDf.accountLanguage = this._sfDisputeForm$.value.accountLanguage;
    this._sfDisputeForm$.next(newDf);
  }

  setLanguage(selectedLanguage: string) {
    this._sfDisputeForm$.value.accountLanguage = selectedLanguage;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
  }

  setLawEnforcementInfo(report: LawEnforcementDetails) {
    this._sfDisputeForm$.value.lawEnforcementDetails = report;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setHasSuspects(hasSuspects: boolean) {
    this._sfDisputeForm$.value.hasSuspects = hasSuspects;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setSuspects(suspects: Suspect[]) {
    const updateSus = Object.assign(this._sfDisputeForm$.value, { suspects });
    this._sfDisputeForm$.next(updateSus);
    return this._sfDisputeForm$.asObservable();
  }
  setPurchaseDevices(purchaseDevices: PurchaseDevice[]) {
    const updatePds = Object.assign(this._sfDisputeForm$.value, { purchaseDevices });
    this._sfDisputeForm$.next(updatePds);
    return this._sfDisputeForm$.asObservable();
  }
  setFraudType(type: FraudType) {
    this._sfDisputeForm$.value.fraudType = type;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setEmployeeStatus(employeeStatus: EmployeeStatus) {
    this._sfDisputeForm$.value.employeeStatus.additionalDetails = employeeStatus.additionalDetails || "";
    this._sfDisputeForm$.value.employeeStatus.isCurrentOrFormer = employeeStatus.isCurrentOrFormer;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setLastTransactionDate(date: Date) {
    this._sfDisputeForm$.value.lastTransactionDate = date;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setMoneyCodeSharedDetails(authShared: MoneyCodeAuthShared) {
    const updateAuthShare = Object.assign(this._sfDisputeForm$.value, { moneyCodeAuthShared: authShared });
    this._sfDisputeForm$.next(updateAuthShare);
    return this._sfDisputeForm$.asObservable();
  }
  setCardLocation(cardLocation: string) {
    this._sfDisputeForm$.value.locationCardWasKept = cardLocation;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setActivityKnowledge(act: string) {
    this._sfDisputeForm$.value.activityKnowledge = act;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setSignerInfo(accepted: boolean, signature: string) {
    this._sfDisputeForm$.value.signerName = signature;
    this._sfDisputeForm$.value.acceptLegalDisclaimer = accepted;
    this._sfDisputeForm$.value.acceptLegalDisclaimerTimestamp = new Date().toISOString();
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setContactEmail(email: string) {
    this._sfDisputeForm$.value.contactEmailAddress = email;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
  setAdditionalInfo(info: string) {
    this._sfDisputeForm$.value.additionalInformation = info;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setIncidentDetail(detail: string) {
    this._sfDisputeForm$.value.overview = detail;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setIllegitimacyDetail(detail: string) {
    this._sfDisputeForm$.value.illegitimacyDetails = detail;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setPhysicalCardDetails(physicalCardDetails: PhysicalCardDetails) {
    this._sfDisputeForm$.value.physicalCardDetails = physicalCardDetails;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setProfilingInfo(tmxSessionId: string, tmxValidated: boolean) {
    this._sfDisputeForm$.value.tmxSessionId = tmxSessionId;
    this._sfDisputeForm$.value.tmxValidated = tmxValidated;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }

  setIsPrePop(isPrePop: boolean) {
    this._sfDisputeForm$.value.isPrePop = isPrePop;
    this._sfDisputeForm$.next(this._sfDisputeForm$.value);
    return this._sfDisputeForm$.asObservable();
  }
}
