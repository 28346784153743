<h1>{{ "Products.Header" | translate }}</h1>
<p>{{ "Products.Detail" | translate }}</p>
<i>{{ "Products.Info" | translate }}</i>
<br />
<br />
<mat-button-toggle-group name="productType" aria-label="label" vertical [(ngModel)]="productType">
  <mat-button-toggle value="{{ fleetCard }}" class="button-toggle blue" (click)="canProceed()">
    <fa-icon [icon]="faCreditCard"></fa-icon> {{ "Products.ButtonFleetCard" | translate }}
  </mat-button-toggle>
  <mat-button-toggle value="{{ smartfundsCard }}" class="button-toggle blue" (click)="canProceed()">
    <fa-icon [icon]="faCreditCard"></fa-icon> {{ "Products.ButtonSmartfunds" | translate }}
  </mat-button-toggle>
  <mat-button-toggle value="{{ moneyCode }}" class="button-toggle blue" (click)="canProceed()">
    <fa-icon [icon]="faMoneyBillTransfer"></fa-icon> {{ "Products.ButtonMoneyCode" | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
