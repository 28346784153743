<h1>{{ "LastTransaction.Header" | translate }}</h1>
<p>{{ "LastTransaction.Detail" | translate }}</p>
<form>
  <mat-form-field appearance="outline" [formGroup]="lastTransactionForm">
    <mat-label>{{ "LastTransaction.DateLabel" | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="lastTransaction"
      formControlName="lastTransactionDate"
      [max]="today"
      (dateChange)="nextCheck()"
    />
    <mat-datepicker-toggle matSuffix [for]="lastTransaction"></mat-datepicker-toggle>
    <mat-datepicker #lastTransaction></mat-datepicker>
    <mat-error *ngIf="lastTransactionForm.get('lastTransactionDate')?.hasError('required')">
      {{ "Error.Required" | translate }}</mat-error
    >
    <mat-error
      *ngIf="
        lastTransactionForm.get('lastTransactionDate')?.hasError('matDatepickerMax') ||
        lastTransactionForm.get('lastTransactionDate')?.hasError('matDatepickerMin')
      "
    >
      {{ "Error.MustBeWithin6Months" | translate }}</mat-error
    >
  </mat-form-field>
</form>
