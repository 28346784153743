import { Component, OnInit } from "@angular/core";
import { TimeoutService } from "src/app/services/timeout.service";

@Component({
  selector: "app-notfound",
  templateUrl: "./notfound.component.html",
  styleUrls: ["./notfound.component.scss"],
})
/**
 * Represents the NotfoundComponent class.
 * This component is responsible for handling the "not found" page.
 */
export class NotfoundComponent {
  constructor(private _timeout: TimeoutService) {
    this._timeout.idleWatchStop();
  }
}
