<h1>{{ "EmployeeStatus.Header" | translate }}</h1>
<mat-button-toggle-group aria-label="label" vertical [(ngModel)]="yesFormerOrCurrentEmployee" (change)="checkForNext()">
  <mat-button-toggle [value]="true" id="employeeStatusToggle">
    <mat-icon>check</mat-icon>
    {{ "EmployeeStatus.ButtonYes" | translate }}
  </mat-button-toggle>
  <mat-button-toggle [value]="false" id="noEmployeeStatusToggle">
    <mat-icon>close</mat-icon>
    {{ "EmployeeStatus.ButtonNo" | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
<div *ngIf="yesFormerOrCurrentEmployee == false">
  <p>{{ "EmployeeStatus.AdditionalDetails" | translate }}</p>
  <form [formGroup]="empForm">
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-label>{{ "Common.DescribeWhatHappened" | translate }}</mat-label>
      <textarea
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        matInput
        type="text"
        formControlName="additionalDetails"
        maxlength="255"
      ></textarea>
      <mat-error *ngIf="empForm.get('additionalDetails')?.hasError('maxlength')" >
        {{ "Error.MustBe1-255Chars" | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>