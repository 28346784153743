import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CaptchaService } from "src/app/services/captcha.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { datadogRum } from "@datadog/browser-rum";
import Swal from "sweetalert2";
import { NavigationEnum } from "src/app/dispute-form-module/globalvar";

/**
 * Represents the CaptchaComponent class.
 * This component is responsible for handling the captcha functionality.
 */
@Component({
  selector: "app-captcha-component",
  templateUrl: "./captcha.component.html",
  styleUrls: ["./captcha.component.scss"],
})
export class CaptchaComponent implements OnInit {
  @ViewChild("captchaEl", { static: false }) captchaEl: any;

  token: string | undefined;
  siteKey: string = environment.recaptcha.siteKey;
  isProd: boolean = environment.production;

  datadogErrorHandler(error: Error | string, origin: string, context?: object) {
    datadogRum.addError(error, {
      origin,
      ...context,
    });
  }

  ngOnInit() {}

  /**
   * Constructs a new instance of the CaptchaComponent.
   *
   * @param captchaService - The captcha service used to interact with the captcha functionality.
   * @param router - The router service used for navigation.
   * @param r - The activated route service used to retrieve information about the current route.
   * @param _translate - The translation service used for language translation.
   */
  constructor(
    private captchaService: CaptchaService,
    private router: Router,
    private r: ActivatedRoute,
    private _translate: TranslateService
  ) {
    this.token = undefined;

    if(!this.isProd){ //QA Shortcut, skip captcha :)
      this.token = "1";
      this.captchaService.setValid(true);
    }

    //reset captcha on timeout
    this.captchaService.isValid$.subscribe({
      next: (val) => {
        if (!val && this.token) {
          this.expireCaptcha();
        }
      },
    });

    this._translate.onLangChange.subscribe((lang) => {
      this.setCaptchaLang(document.getElementById("recaptcha"), lang.lang);
    });
  }

  /**
   * Validates the captcha token and stores the state of the captcha validation.
   *
   * @param captchaToken - The captcha token to be validated.
   * @returns A Promise that resolves when the captcha validation is successful.
   * @throws An error if the captcha validation fails.
   */
  async captchaResponse(captchaToken: any) {
    if (captchaToken) {
      //subscribe to captcha service validateCaptcha method and catch errors

      //api is called in this service. this service is used to store the state of the captcha validation
      try {
        this.captchaService.validateCaptcha(captchaToken).subscribe({
          next: (r: any) => {
            if (r.success) {
              this.token = captchaToken;
            } else {
              const err = new Error("Cannot Compute");
              this.datadogErrorHandler(err, "CaptchaToken");
              throw err;
            }
          },
          error: (resp) => {
            this.expireCaptcha();
            Swal.fire(this._translate.instant("Error.Error"), resp.message, "error");
          },
        });
      } catch (err: any) {
        //not sure why subscribe is not catching this error
        this.expireCaptcha();
        Swal.fire(this._translate.instant("Error.Error"), err.message, "error");
      }
    } else {
      Swal.fire(this._translate.instant("Error.Error"), this._translate.instant("Error.InvalidCaptcha"), "error");
    }
  }

  /**
   * Navigates to the term agreement page to start the dispute process.
   */
  startDispute() {
    this.router.navigate([NavigationEnum.TERM_AGREEMENT]);
  }

  /**
   * Expires the captcha by resetting the token, resetting the captcha element, and setting the validity to false.
   */
  expireCaptcha() {
    this.token = undefined;
    this.captchaEl.reset();
    this.captchaService.setValid(false);
  }

  /**
   * Sets the language of the ReCaptcha component.
   *
   * @param recaptchaContainer - The container element of the ReCaptcha component.
   * @param lang - The language code to set. Defaults to "en" if not provided.
   */
  setCaptchaLang(recaptchaContainer: any, lang: any) {
    lang = lang || "en"; // 1. Search for the ReCaptcha iframe

    const iframeGoogleCaptcha = recaptchaContainer.querySelector("iframe");

    if (iframeGoogleCaptcha != null) {
      // 2. Retrieve the current language
      const currentLang = iframeGoogleCaptcha
        .getAttribute("src")
        .match(/hl=(.*?)&/)
        .pop(); // 3. Verify if the language that you want to set is different to the current one

      if (currentLang !== lang) {
        // 4. If it is, change it
        iframeGoogleCaptcha.setAttribute(
          "src",
          iframeGoogleCaptcha.getAttribute("src").replace(/hl=(.*?)&/, "hl=" + lang + "&")
        );
      }
    }
  }
}
