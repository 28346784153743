import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { SharedModule } from "../utils/shared.module";
import { WProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { AppMaterialModule } from "../app-material/app-material.module";
import { EmployeeStatusComponent } from "./pages/employee-status/employee-status.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AdditionalInfoComponent } from "./pages/additional-info/additional-info.component";
import { DisputeFormComponent } from "./pages/dispute-form/dispute-form.component";
import { ImpactedPaymentsComponent } from "./pages/impacted-payments/impacted-payments.component";
import { MoneyCodeAuthComponent } from "./pages/moneycode-auth/moneycode-auth.component";
import { PoliceReportComponent } from "./pages/police-report/police-report.component";
import { MoneyCodeCredentialsShareComponent } from "./pages/money-code-credentials-share/money-code-credentials-share.component";
import { PhysicalPossessionComponent } from "./pages/physical-possession/physical-possession.component";
import { ProductSelectionComponent } from "./pages/product-selection/product-selection.component";
import { PurchaseDeviceComponent } from "./pages/purchase-device/purchase-device.component";
import { SuspectsComponent } from "./pages/suspects/suspects.component";
import { TermConditionsComponent } from "./pages/termconditions/termconditions.component";
import { TransactionDetailComponent } from "./pages/transaction-detail/transaction-detail.component";
import { CardLocationComponent } from "./pages/card-location/card-location.component";
import { FraudTypeSelectionComponent } from "./pages/fraud-type-selection/fraud-type-selection.component";
import { LastTransactionComponent } from "./pages/last-transaction/last-transaction.component";
import { ReviewSubmitClaimComponent } from "./pages/review-submit-claim/review-submit-claim.component";
import { IconsModule } from "../icons/icons.module";
import { ActivityKnowledgeComponent } from "./pages/activity-knowledge/activity-knowledge.component";
import { ConfirmationComponent } from "./pages/confirmation/confirmation.component";
import { WexNavigationComponent } from "./components/wex-navigation/wex-navigation.component";
import { MoneyCodeIncidentOverviewComponent } from "./pages/money-code-incident-overview/money-code-incident-overview.component";
import { CurrentLocationComponent } from "./pages/current-location/current-location.component";
import { IllegitimacyComponent } from "./pages/illegitimacy/illegitimacy.component";

/**
 * Defines the routes for the dispute form module.
 * Each route corresponds to a specific component that will be displayed when the route is activated.
 * The routes are organized as a tree structure, with child routes representing sub-pages of the main dispute form component.
 */
const routes: Routes = [
  {
    path: "dispute",
    component: DisputeFormComponent,
    //create child route to termcondition component
    children: [
      {
        path: "additional-info",
        component: AdditionalInfoComponent,
      },

      {
        path: "physical-possession",
        component: PhysicalPossessionComponent,
      },
      {
        path: "police-report",
        component: PoliceReportComponent,
      },
      {
        path: "product-selection",
        component: ProductSelectionComponent,
      },
      {
        path: "impacted-payments",
        component: ImpactedPaymentsComponent,
      },
      {
        path: "suspects",
        component: SuspectsComponent,
      },
      {
        path: "term-conditions",
        component: TermConditionsComponent,
      },
      {
        path: "fraud-type",
        component: FraudTypeSelectionComponent,
      },
      {
        path: "moneycode",
        children: [
          {
            path: "credential-shared",
            component: MoneyCodeCredentialsShareComponent,
          },
          { path: "auth", component: MoneyCodeAuthComponent },
          { path: "incident-overview", component: MoneyCodeIncidentOverviewComponent },
        ],
      },
      {
        path: "employee-status",
        component: EmployeeStatusComponent,
      },
      {
        path: "card-location",
        component: CardLocationComponent,
      },
      {
        path: "last-transaction",
        component: LastTransactionComponent,
      },
      {
        path: "review-submit-claim",
        component: ReviewSubmitClaimComponent,
      },
      {
        path: "activity-knowledge",
        component: ActivityKnowledgeComponent,
      },
      { path: "current-location", component: CurrentLocationComponent },
      { path: "illegitimacy", component: IllegitimacyComponent },
      { path: "confirmation", component: ConfirmationComponent },
    ],
  },
];
@NgModule({
  declarations: [
    DisputeFormComponent,
    ErrorPageComponent,
    WProgressBarComponent,
    WexNavigationComponent,
    EmployeeStatusComponent,
    AdditionalInfoComponent,
    DisputeFormComponent,
    ImpactedPaymentsComponent,
    MoneyCodeAuthComponent,
    PhysicalPossessionComponent,
    PoliceReportComponent,
    MoneyCodeCredentialsShareComponent,
    ProductSelectionComponent,
    PurchaseDeviceComponent,
    SuspectsComponent,
    TermConditionsComponent,
    TransactionDetailComponent,
    CardLocationComponent,
    FraudTypeSelectionComponent,
    LastTransactionComponent,
    ReviewSubmitClaimComponent,
    ActivityKnowledgeComponent,
    ConfirmationComponent,
    WexNavigationComponent,
    MoneyCodeIncidentOverviewComponent,
    CurrentLocationComponent,
    IllegitimacyComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    SharedModule,
    FontAwesomeModule,
    IconsModule,
  ],
  providers: [FormGroupDirective],
})
export class DisputeFormModule {}
