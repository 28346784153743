import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, lastValueFrom, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SFDisputeDTO } from "../models/sf-dispute.dto";
import { Buffer } from "buffer";
import { datadogRum } from "@datadog/browser-rum";
import { DisputeForm } from "../models/dispute-form.model";
import { DisputeRequestDto } from "./models/dispute-form-dto/dispute.request.dto";
import { LineOfBusiness, eProductType } from "../dispute-form-module/globalvar";
import { TransactionDto } from "./models/dispute-form-dto/purchase-device.dto";
import { TranslateService } from "@ngx-translate/core";
import { Common } from "../utils/common";
import { SalesforceInfoService } from "./sfInfo.service";

interface AuthResponse {
  access_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private readonly baseUrl = environment.serviceAPI;

  private _jwtToken$ = new BehaviorSubject<string>("");
  jwtToken$ = this._jwtToken$.asObservable();

  private _jwtTokenExpire$ = new BehaviorSubject<string>("");
  jwtTokenExpire$ = this._jwtTokenExpire$.asObservable();

  private _formSubmitEvent$ = new BehaviorSubject<boolean | null>(null);
  formSubmitEvent$ = this._formSubmitEvent$.asObservable();

  siteNetworkError = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private _translate: TranslateService,
    private _sfInfoService: SalesforceInfoService
  ) {}

  setJWTToken(token: string) {
    this._jwtToken$.next(token);
  }
  getJWTToken(): string {
    return this._jwtToken$.getValue();
  }

  datadogErrorHandler(error: Error | string, message: string, context?: object) {
    datadogRum.addError(error, {
      message,
      ...context,
    });
  }

  dataDogLog(name: string, context?: object) {
    datadogRum.addAction(name, context);
  }

  getAuthToken(id: string, key: string) {
    const headers = new HttpHeaders()
      .set("Authorization", `Basic ${this.encodeBase64(id, key)}`)
      .set("Cache-Control", "no-cache");
    let authToken = "";
    return this.http.get<AuthResponse>(`${this.baseUrl}auth`, { headers }).pipe(
      map((resp) => {
        authToken = resp.access_token;
        this.setJWTTokenExpire(resp.expires_in);
        this._jwtToken$.next(authToken);
        return resp.access_token;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  setJWTTokenExpire(expiringIn: number) {
    let expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + expiringIn);
    this._jwtTokenExpire$.next(expiresAt.toString());
  }

  isJWTTokenExpired() {
    let expireDate = new Date(this._jwtTokenExpire$.getValue());
    let currentDate = new Date();
    if (expireDate.getTime() < currentDate.getTime()) {
      return true;
    }
    return false;
  }
  getRecordById(recordId: string): Observable<SFDisputeDTO> {
    if (this._jwtToken$.getValue()) {
      const headers = new HttpHeaders()
        .set("Authorization", `Bearer ${this._jwtToken$.getValue()}`)
        .set("Cache-Control", "no-cache");
      return this.http.get<SFDisputeDTO>(`${this.baseUrl}${recordId}`, { headers }).pipe(catchError(this.handleError));
    } else {
      const err = new Error(this._translate.instant("Error.InvalidAuthToken"));
      this.datadogErrorHandler(err, "getRecordById");
      throw err;
    }
  }

  postRecord(data: any) {
    if (this._jwtToken$.getValue()) {
      const headers = new HttpHeaders().set("Authorization", `Bearer ${this._jwtToken$.getValue()}`);
      return this.http.post(`${this.baseUrl}`, data, { headers }).pipe(catchError(this.handleError));
    } else {
      const err = new Error(this._translate.instant("Error.InvalidAuthToken"));
      this.datadogErrorHandler(err, "postRecord");
      throw err;
    }
  }

  postForm(formData: DisputeForm) {
    //, recordId: string) {
    this._formSubmitEvent$.next(null);
    try {
      let recordId = this._sfInfoService.getSFRecordId();
      if (this._jwtToken$.getValue() && recordId) {
        if (this.isJWTTokenExpired()) {
          // this.getAuthToken(recordId, this._sfInfoService.getSFKey()).pipe(map(data => data));
          // return this.callPost(formData, recordId);
          return this.getAuthToken(recordId, this._sfInfoService.getSFKey()).pipe(
            switchMap(() => this.callPost(formData, recordId))
          );
        } else {
          return this.callPost(formData, recordId);
        }
      } else {
        const err = new Error(this._translate.instant("Error.InvalidAuthToken"));
        this.datadogErrorHandler(err, "Post Form", {
          context: "Error from attempting to submit form",
        });
        throw err;
      }
    } catch (err) {
      this.datadogErrorHandler(err as string, "Post form", { context: "Error returned after submitting form" });
      throw new Error(err as string);
    }
  }

  private callPost(formData: DisputeForm, recordId: string) {
    const dto = this.ConvertDisputeToDTO(formData);
    const headers = new HttpHeaders().set("Authorization", `Bearer ${this._jwtToken$.getValue()}`);
    return this.http
      .post(`${this.baseUrl}${recordId}/submit`, dto, {
        headers,
        observe: "response",
      })
      .pipe(
        catchError((error) => {
          return this.handleError(error, { origin: "Form Submission" });
        })
      );
  }

  validateCaptcha(token: string) {
    if (this._jwtToken$.getValue()) {
      const headers = new HttpHeaders().set("Authorization", `Bearer ${this._jwtToken$.getValue()}`);
      return this.http
        .post(`${this.baseUrl}validate-captcha`, { token: token }, { headers })
        .pipe(catchError(this.handleError));
    } else {
      const err = new Error(this._translate.instant("Error.InvalidAuthToken"));
      this.datadogErrorHandler(err, "Validate Captcha");
      throw err;
    }
  }

  private encodeBase64(id: string, key: string) {
    let buf = Buffer.from(id + ":" + key);
    return buf.toString("base64");
  }

  private handleError(error: HttpErrorResponse, context?: object) {
    this._formSubmitEvent$.next(false);
    let errorMessage = "Unknown error occurred";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.status === 0) {
      console.error("Unable to access site or network", error);
      this.siteNetworkError.next(true);
    } else {
      // server-side error
      switch (error.status) {
        case 400:
          errorMessage = `Bad Request: ${error.error.message}`;
          break;
        case 401:
          errorMessage = `Unauthorized: ${error.error.message}`;
          break;
        case 410:
          errorMessage = this._translate.instant("Error.AlreadyUsedLink");
          break;
        case 423:
          errorMessage = this._translate.instant("Error.LockedLink");
          break;
        default:
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          break;
      }
    }
    this.datadogErrorHandler(error, errorMessage, context);
    return throwError(() => new Error(errorMessage));
  }

  private ConvertDisputeToDTO(data: DisputeForm): DisputeRequestDto {
    var convertedData: DisputeRequestDto = {
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      lineOfBusiness: data.lineOfBusiness as LineOfBusiness,

      contactFirstName: data.contactFirstName, // this passes through from salesforce -> angular -> RP -> salesforce
      contactLastName: data.contactLastName, // this passes through from salesforce -> angular -> RP -> salesforce
      contactEmailAddress: data.contactEmailAddress,

      productType: data.productType,

      acceptLegalDisclaimer: data.acceptLegalDisclaimer,
      acceptLegalDisclaimerTimestamp: data.acceptLegalDisclaimerTimestamp,
      signerName: data.signerName,

      overview:
        data.lineOfBusiness == LineOfBusiness.OTR
          ? data.productType != eProductType.MONEYCODE
            ? "Not required for this form"
            : data.overview
          : data.activityKnowledge,

      additionalInformation: data.additionalInformation,

      nafFraudType: data.fraudType,

      purchaseDevices: [],
      suspects: [],

      internalUse: {
        threatMetrix: {
          sessionId: data.tmxSessionId,
          result: data.tmxResult
        },
        disputeCaseNumber: data.referenceId,
        alertTransactions: []
      },
    };

    if (data.employeeStatus && data.employeeStatus.isCurrentOrFormer !== undefined) {
      convertedData.cardholderDetails = {
        currentOrFormerEmployee: data.employeeStatus.isCurrentOrFormer as boolean,
        nonEmployeeDetails: data.employeeStatus.additionalDetails ? data.employeeStatus.additionalDetails : undefined,
      };
    }

    if (data.cardInPossession !== undefined) {
      convertedData.physicalCardDetails = {
        cardLocationKnown:
          data.physicalCardDetails.cardLocationKnown !== undefined
            ? data.physicalCardDetails.cardLocationKnown
            : undefined,
        cardInPossession: data.cardInPossession as boolean,
        locationCardWasKept: data.locationCardWasKept ? data.locationCardWasKept : undefined,
      };
    }

    if (data.lastTransactionDate) {
      convertedData.lastLegitimateTransactionDate = Common.dateToString(data.lastTransactionDate as Date);
    }

    if (data.suspects.length > 0) {
      data.suspects.forEach((suspect) => {
        convertedData.suspects.push({
          employmentStatus: suspect.employmentStatus,
          firstName: suspect.firstName,
          lastName: suspect.lastName,
          address: suspect.address,
          employmentStartDate: suspect.employmentStartDate
            ? Common.dateToString(suspect.employmentStartDate as Date)
            : undefined,
          employmentEndDate: suspect.employmentEndDate
            ? Common.dateToString(suspect.employmentEndDate as Date)
            : undefined,
          additionalIdentifyingInformation: suspect.additionalIdentifyingInformation,
        });
      });
    }

    if (data.productType === eProductType.MONEYCODE) {
      convertedData.moneyCodeDetails = {
        issuedByAuthorizedUser: data.mcIsAuthorizedUser as boolean,
        credentialsShared: data.moneyCodeAuthShared.shared as boolean,
        credentialsSharedDetails: data.moneyCodeAuthShared.sharedDetail as string,
      };
    }

    if (data.lawEnforcementDetails.isReported) {
      convertedData.lawEnforcementDetails = {
        referenceId: data.lawEnforcementDetails.referenceId,
        officerFirstName: data.lawEnforcementDetails.officerFirstName,
        officerLastName: data.lawEnforcementDetails.officerLastName,
        dateReported: Common.dateToString(data.lawEnforcementDetails.dateReported as Date),
        phoneNumber: data.lawEnforcementDetails.phoneNumber.replace(/^\D+/g, ""),
        reportNumber: data.lawEnforcementDetails.reportNumber,
        emailAddress: data.lawEnforcementDetails.emailAddress,
      };
    }

    if (data.purchaseDevices.length > 0) {
      data.purchaseDevices.forEach((pd) => {
        let tdto: TransactionDto[] | null = [];
        let hasAlertedTransaction = false;
        pd.transactions.forEach((t) => {
          if(t.isPrePop){
            convertedData.internalUse.alertTransactions?.push(t.transactionId);
            hasAlertedTransaction = true;
          }
          else {
            tdto?.push({
              merchant: t.merchant,
              merchantContactDate: t.merchantContactDate ? Common.dateToString(t.merchantContactDate) : undefined,
              transactionDate: Common.dateToString(t.transactionDate),
              transactionId: t.transactionId,
              transactionAmount: t.transactionAmount,
            });
          }

        });

        if(!hasAlertedTransaction){
          convertedData.internalUse.alertTransactions = null;
        }

        
        if(tdto.length == 0){
          tdto = null;
        }


        convertedData.purchaseDevices.push({
          cardNumber: pd.cardNumber,
          assetId: pd.vehicleAssetUnitDriverId,
          newCardNumber: pd.newCardNumber ? pd.newCardNumber : null,
          cardholderName: pd.cardholderFirst + " " + data.purchaseDevices[0].cardholderLast,
          transactions: tdto,
        });
      });
    }
    
    return convertedData;
  }
}
