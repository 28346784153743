import { Component, OnInit } from "@angular/core";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { AppService } from "src/app/services/app.service";
import { ApiService } from "src/app/services/api.service";
import { ToastService } from "src/app/services/toast.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { TimeoutService } from "src/app/services/timeout.service";

@Component({
  selector: "app-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"],
})
/**
 * Represents the ConfirmationComponent class.
 * This component is responsible for displaying the confirmation page of the dispute form.
 */
export class ConfirmationComponent implements OnInit {
  refNumber: string;
  gDisputeForm: DisputeForm;

  constructor(
    private _df: DisputeFormService,
    private _as: AppService,
    private _apiService: ApiService,
    private _toastService: ToastService,
    private _navigationService: NavigationService,
    private _progressService: ProgressBarService,
    private _translate: TranslateService,
    private _timeout: TimeoutService
  ) {
    this.gDisputeForm = this._df.getDisputeForm();
    this.refNumber = this.gDisputeForm.referenceId;

    this._timeout.idleWatchStop();
  }

  /**
   * Initializes the component.
   * - Hides the progress bar.
   * - Hides the navigation.
   * - Deactivates the session.
   * - Subscribes to the formSubmitEvent$ observable and handles the event.
   *   - If the event is true, shows a success toast.
   *   - If the event is false, shows an error toast with a translated title.
   */
  ngOnInit(): void {
    this._progressService.hideProgressBar();
    this._navigationService.hideNavigation();
    this._as.deactivateSession();
    this._apiService.formSubmitEvent$.subscribe((event) => {
      if (event == true) {
        // this._toastService.showToast({
        //   icon: "success",
        //   title: "Claim Submitted",
        // });
      } else if (event == false) {
        this._toastService.showToast({
          icon: "error",
          title: this._translate.instant("Error.DisputeFormServerError"),
        });
      }
    });
  }
}
