<h1>{{ "Illegitimacy.Header" | translate }}</h1>
<p>{{ "Illegitimacy.DetailText" | translate }}</p>
<form [formGroup]="illegitimacyForm">
  <mat-form-field appearance="outline" class="text-area">
    <mat-label>{{ "Illegitimacy.Label" | translate }}</mat-label>
    <textarea
      formControlName="illegitimacyDetails"
      matInput
      #autosize="cdkTextareaAutosize"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
    ></textarea>
  </mat-form-field>
</form>
