import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EditPencilComponent } from "./edit-pencil/edit-pencil.component";
import { MissingUfoComponent } from "./missing-ufo/missing-ufo.component";

@NgModule({
  declarations: [EditPencilComponent, MissingUfoComponent],
  imports: [CommonModule],
  exports: [EditPencilComponent, MissingUfoComponent],
})
export class IconsModule {}
