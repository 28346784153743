import { NavigationPath, NavigationStep, Step } from "src/app/models/navigation/navigation-path";
import { FraudType, LineOfBusiness, NavigationEnum, eProductType } from "../dispute-form-module/globalvar";

const navigationPaths: NavigationPath[] = [
  {
    buPath: "OTR",
    paths: [
      {
        route: NavigationEnum.TERM_AGREEMENT,
        nextRoute: NavigationEnum.PRODUCT_SELECTION,
      },
      {
        route: NavigationEnum.PRODUCT_SELECTION,
        conditions: [
          {
            option: eProductType.FLEETCARD,
            nextRoute: NavigationEnum.PHYSICAL_POSSESSION,
            nextRouteText: "PageName.Possession",
          },
          {
            option: eProductType.SMARTFUNDS,
            nextRoute: NavigationEnum.PHYSICAL_POSSESSION,
            nextRouteText: "PageName.Possession",
          },
          {
            option: eProductType.MONEYCODE,
            nextRoute: NavigationEnum.MC_CREDENTIAL_AUTH,
            nextRouteText: "PageName.MCAuthorization",
          },
        ],
      },
      {
        route: NavigationEnum.PHYSICAL_POSSESSION,
        conditions: [
          { option: true, nextRoute: NavigationEnum.EMPLOYEE_STATUS, nextRouteText: "PageName.EmployeeStatus" },
          { option: false, nextRoute: NavigationEnum.POLICE, nextRouteText: "PageName.PoliceReport" },
        ],
      },
      { route: NavigationEnum.POLICE, nextRoute: NavigationEnum.SUSPECTS, nextRouteText: "PageName.Suspects" },
      {
        route: NavigationEnum.IMPACTED_PAYMENTS,
        conditions: [
          {
            option: "NO_POSSESSION",
            nextRoute: NavigationEnum.ADDITIONAL_INFO,
            nextRouteText: "PageName.AdditionalNotes",
          },
          { option: "IN_POSSESSION", nextRoute: NavigationEnum.POLICE, nextRouteText: "PageName.PoliceReport" },
          { option: "MONEYCODE", nextRoute: NavigationEnum.POLICE, nextRouteText: "PageName.PoliceReport" },
        ],
      },
      {
        route: NavigationEnum.SUSPECTS,
        conditions: [
          {
            option: "NO_POSSESSION",
            nextRoute: NavigationEnum.IMPACTED_PAYMENTS,
            nextRouteText: "PageName.ImpactedPayments",
          },
          {
            option: "IN_POSSESSION",
            nextRoute: NavigationEnum.ADDITIONAL_INFO,
            nextRouteText: "PageName.AdditionalNotes",
          },
          { option: "MONEYCODE", nextRoute: NavigationEnum.ADDITIONAL_INFO, nextRouteText: "PageName.AdditionalNotes" },
        ],
      },
      {
        route: NavigationEnum.EMPLOYEE_STATUS,
        nextRoute: NavigationEnum.IMPACTED_PAYMENTS,
        nextRouteText: "PageName.ImpactedPayments",
      },
      {
        route: NavigationEnum.ADDITIONAL_INFO,
        nextRoute: NavigationEnum.REVIEW_SUBMIT,
        nextRouteText: "PageName.ReviewAndSubmit",
      },
      {
        route: NavigationEnum.REVIEW_SUBMIT,
        nextRoute: NavigationEnum.CONFIRMATION,
      },
      {
        route: NavigationEnum.MC_CREDENTIAL_AUTH,
        nextRoute: NavigationEnum.MC_CREDENTIAL_SHARED,
        nextRouteText: "PageName.MCCredentialAccess",
      },
      {
        route: NavigationEnum.MC_CREDENTIAL_SHARED,
        nextRoute: NavigationEnum.MC_INCIDENT_OVERVIEW,
        nextRouteText: "PageName.MCIncidentOverview",
      },
      {
        route: NavigationEnum.MC_INCIDENT_OVERVIEW,
        nextRoute: NavigationEnum.IMPACTED_PAYMENTS,
        nextRouteText: "PageName.ImpactedPayments",
      },
    ],
  },
  {
    buPath: "NAF",
    paths: [
      {
        route: NavigationEnum.TERM_AGREEMENT,
        nextRoute: NavigationEnum.FRAUD_TYPE,
      },
      {
        route: NavigationEnum.FRAUD_TYPE,
        conditions: [
          {
            option: FraudType.DRIVER_MISUSE,
            nextRoute: NavigationEnum.PHYSICAL_POSSESSION,
            nextRouteText: "PageName.Possession",
          },
          {
            option: FraudType.COMPROMISED,
            nextRoute: NavigationEnum.PHYSICAL_POSSESSION,
            nextRouteText: "PageName.Possession",
          },
          {
            option: FraudType.NO_POSSESSION,
            nextRoute: NavigationEnum.ACTIVITY_KNOWLEDGE,
            nextRouteText: "PageName.ActivityKnowledge",
          },
        ],
      },
      {
        route: NavigationEnum.PHYSICAL_POSSESSION,
        nextRoute: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextRouteText: "PageName.ActivityKnowledge",
        conditions: [
          { option: true, nextRoute: NavigationEnum.ACTIVITY_KNOWLEDGE, nextRouteText: "PageName.ActivityKnowledge" },
          { option: false, nextRoute: NavigationEnum.CURRENT_LOCATION, nextRouteText: "PageName.CurrentLocation" },
        ],
      },
      {
        route: NavigationEnum.CURRENT_LOCATION,
        nextRoute: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextRouteText: "PageName.ActivityKnowledge",
      },
      {
        route: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextRoute: NavigationEnum.LAST_TRANSACTION,
        nextRouteText: "PageName.LastLegitTransaction",
      },
      {
        route: NavigationEnum.LAST_TRANSACTION,
        nextRoute: NavigationEnum.CARD_LOCATION,
        nextRouteText: "PageName.CardLocation",
      },
      {
        route: NavigationEnum.CARD_LOCATION,
        nextRoute: NavigationEnum.POLICE,
        nextRouteText: "PageName.PoliceReport",
      },
      {
        route: NavigationEnum.POLICE,
        conditions: [
          { option: FraudType.DRIVER_MISUSE, nextRoute: NavigationEnum.SUSPECTS, nextRouteText: "PageName.Suspects" },
          {
            option: FraudType.COMPROMISED,
            nextRoute: NavigationEnum.IMPACTED_PAYMENTS,
            nextRouteText: "PageName.ImpactedPayments",
          },
          {
            option: FraudType.NO_POSSESSION,
            nextRoute: NavigationEnum.IMPACTED_PAYMENTS,
            nextRouteText: "PageName.ImpactedPayments",
          },
        ],
      },
      {
        route: NavigationEnum.SUSPECTS,
        nextRoute: NavigationEnum.IMPACTED_PAYMENTS,
        nextRouteText: "PageName.ImpactedPayments",
      },
      {
        route: NavigationEnum.IMPACTED_PAYMENTS,
        nextRoute: NavigationEnum.ADDITIONAL_INFO,
        nextRouteText: "PageName.AdditionalNotes",
      },
      {
        route: NavigationEnum.ADDITIONAL_INFO,
        nextRoute: NavigationEnum.REVIEW_SUBMIT,
        nextRouteText: "PageName.ReviewAndSubmit",
      },
      {
        route: NavigationEnum.REVIEW_SUBMIT,
        nextRoute: NavigationEnum.CONFIRMATION,
      },
    ],
  },
];
const navigationSteps: NavigationStep[] = [
  {
    buSteps: "OTR",
    pathCondition: "INITIAL",
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        step: 1,
        maxSteps: 9,
      },
    ],
  },
  {
    buSteps: "OTR",
    pathCondition: "INITIAL_PHYSICAL",
    steps: [
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 2,
        maxSteps: 9,
      },
    ],
  },
  {
    buSteps: "OTR",
    pathCondition: eProductType.FLEETCARD,
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 7,
      },
    ],
  },
  {
    buSteps: "OTR",
    pathCondition: eProductType.SMARTFUNDS,
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 8,
      },
    ],
  },
  {
    buSteps: "OTR",
    pathCondition: eProductType.MONEYCODE,
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        nextPath: NavigationEnum.MC_CREDENTIAL_AUTH,
        step: 1,
        maxSteps: 9,
      },
    ],
  },

  {
    buSteps: "OTR",
    pathCondition: "NO_POSSESSION",
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 9,
        component: "ProductSelection",
      },
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        nextPath: NavigationEnum.POLICE,
        step: 2,
        maxSteps: 7,
        component: "PhysicalPossession",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.SUSPECTS,
        step: 3,
        maxSteps: 7,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.SUSPECTS,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 4,
        maxSteps: 7,
        component: "Suspects",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 5,
        maxSteps: 7,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 6,
        maxSteps: 7,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 7,
        maxSteps: 7,
      },
    ],
  },
  {
    buSteps: "OTR",
    pathCondition: "IN_POSSESSION",
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 8,
        component: "ProductSelection",
      },
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        nextPath: NavigationEnum.EMPLOYEE_STATUS,
        step: 2,
        maxSteps: 8,
        component: "PhysicalPossession",
      },
      {
        currentPath: NavigationEnum.EMPLOYEE_STATUS,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 3,
        maxSteps: 8,
        component: "EmployeeStatus",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.POLICE,
        step: 4,
        maxSteps: 8,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.SUSPECTS,
        step: 5,
        maxSteps: 8,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.SUSPECTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 6,
        maxSteps: 8,
        component: "Suspects",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 7,
        maxSteps: 8,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 8,
        maxSteps: 8,
      },
    ],
  },
  {
    buSteps: "OTR",
    pathCondition: "MONEYCODE",
    steps: [
      {
        currentPath: NavigationEnum.PRODUCT_SELECTION,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 9,
        component: "ProductSelection",
      },
      {
        currentPath: NavigationEnum.MC_CREDENTIAL_AUTH,
        nextPath: NavigationEnum.MC_CREDENTIAL_SHARED,
        step: 2,
        maxSteps: 9,
        component: "MoneyCodeAuth",
      },
      {
        currentPath: NavigationEnum.MC_CREDENTIAL_SHARED,
        nextPath: NavigationEnum.MC_INCIDENT_OVERVIEW,
        step: 3,
        maxSteps: 9,
        component: "MoneyCodeCredentialsShare",
      },
      {
        currentPath: NavigationEnum.MC_INCIDENT_OVERVIEW,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 4,
        maxSteps: 9,
        component: "MoneyCodeIncidentOverview",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.POLICE,
        step: 5,
        maxSteps: 9,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.SUSPECTS,
        step: 6,
        maxSteps: 9,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.SUSPECTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 7,
        maxSteps: 9,
        component: "Suspects",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 8,
        maxSteps: 9,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 9,
        maxSteps: 9,
      },
    ],
  },
  {
    buSteps: "NAF",
    pathCondition: FraudType.DRIVER_MISUSE,
    steps: [
      {
        currentPath: NavigationEnum.FRAUD_TYPE,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 10,
        component: "FraudType",
      },
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        nextPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        step: 2,
        maxSteps: 10,
        component: "PhysicalPossession",
      },
      {
        currentPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextPath: NavigationEnum.LAST_TRANSACTION,
        step: 3,
        maxSteps: 10,
        component: "ActivityKnowledge",
      },
      {
        currentPath: NavigationEnum.LAST_TRANSACTION,
        nextPath: NavigationEnum.CARD_LOCATION,
        step: 4,
        maxSteps: 10,
        component: "LastLegitTransaction",
      },
      {
        currentPath: NavigationEnum.CARD_LOCATION,
        nextPath: NavigationEnum.POLICE,
        step: 5,
        maxSteps: 10,
        component: "CardLocation",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.SUSPECTS,
        step: 6,
        maxSteps: 10,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.SUSPECTS,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 7,
        maxSteps: 10,
        component: "Suspects",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 8,
        maxSteps: 10,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 9,
        maxSteps: 10,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 10,
        maxSteps: 10,
      },
    ],
  },
  {
    buSteps: "NAF",
    pathCondition: FraudType.NO_POSSESSION,
    steps: [
      {
        currentPath: NavigationEnum.FRAUD_TYPE,
        nextPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        step: 1,
        maxSteps: 8,
        component: "FraudType",
      },
      {
        currentPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextPath: NavigationEnum.LAST_TRANSACTION,
        step: 2,
        maxSteps: 8,
        component: "ActivityKnowledge",
      },
      {
        currentPath: NavigationEnum.LAST_TRANSACTION,
        nextPath: NavigationEnum.CARD_LOCATION,
        step: 3,
        maxSteps: 8,
        component: "LastLegitTransaction",
      },
      {
        currentPath: NavigationEnum.CARD_LOCATION,
        nextPath: NavigationEnum.POLICE,
        step: 4,
        maxSteps: 8,
        component: "CardLocation",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 5,
        maxSteps: 8,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 6,
        maxSteps: 8,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 7,
        maxSteps: 8,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 8,
        maxSteps: 8,
      },
    ],
  },
  {
    buSteps: "NAF",
    pathCondition: FraudType.COMPROMISED,
    steps: [
      {
        currentPath: NavigationEnum.FRAUD_TYPE,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 11,
        component: "FraudType",
      },
    ],
  },
  {
    buSteps: "NAF",
    pathCondition: "INITIAL_PHYSICAL",
    steps: [
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 2,
        maxSteps: 11,
        component: "PhysicalPossession",
      },
    ],
  },
  {
    buSteps: "NAF",
    pathCondition: "NO_PHYSICAL_POSSESSION", //TODO: update logic to add more to the condition to minimize number of objects and lower confusion
    steps: [
      {
        currentPath: NavigationEnum.FRAUD_TYPE,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 10,
        component: "FraudType",
      },
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        nextPath: NavigationEnum.CURRENT_LOCATION,
        step: 2,
        maxSteps: 10,
        component: "PhysicalPossession",
      },
      {
        currentPath: NavigationEnum.CURRENT_LOCATION,
        nextPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        step: 3,
        maxSteps: 10,
        component: "CurrentLocation",
      },
      {
        currentPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextPath: NavigationEnum.LAST_TRANSACTION,
        step: 4,
        maxSteps: 10,
        component: "ActivityKnowledge",
      },
      {
        currentPath: NavigationEnum.LAST_TRANSACTION,
        nextPath: NavigationEnum.CARD_LOCATION,
        step: 5,
        maxSteps: 10,
        component: "LastLegitTransaction",
      },
      {
        currentPath: NavigationEnum.CARD_LOCATION,
        nextPath: NavigationEnum.POLICE,
        step: 6,
        maxSteps: 10,
        component: "CardLocation",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 7,
        maxSteps: 10,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 8,
        maxSteps: 10,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 9,
        maxSteps: 10,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 10,
        maxSteps: 10,
      },
    ],
  },
  {
    buSteps: "NAF",
    pathCondition: "IN_PHYSICAL_POSSESSION",
    steps: [
      {
        currentPath: NavigationEnum.FRAUD_TYPE,
        nextPath: NavigationEnum.PHYSICAL_POSSESSION,
        step: 1,
        maxSteps: 9,
        component: "FraudType",
      },
      {
        currentPath: NavigationEnum.PHYSICAL_POSSESSION,
        nextPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        step: 2,
        maxSteps: 9,
        component: "PhysicalPossession",
      },
      {
        currentPath: NavigationEnum.ACTIVITY_KNOWLEDGE,
        nextPath: NavigationEnum.LAST_TRANSACTION,
        step: 3,
        maxSteps: 9,
        component: "ActivityKnowledge",
      },
      {
        currentPath: NavigationEnum.LAST_TRANSACTION,
        nextPath: NavigationEnum.CARD_LOCATION,
        step: 4,
        maxSteps: 9,
        component: "LastLegitTransaction",
      },
      {
        currentPath: NavigationEnum.CARD_LOCATION,
        nextPath: NavigationEnum.POLICE,
        step: 5,
        maxSteps: 9,
        component: "CardLocation",
      },
      {
        currentPath: NavigationEnum.POLICE,
        nextPath: NavigationEnum.IMPACTED_PAYMENTS,
        step: 6,
        maxSteps: 9,
        component: "PoliceReport",
      },
      {
        currentPath: NavigationEnum.IMPACTED_PAYMENTS,
        nextPath: NavigationEnum.ADDITIONAL_INFO,
        step: 7,
        maxSteps: 9,
        component: "ImpactedPayments",
      },
      {
        currentPath: NavigationEnum.ADDITIONAL_INFO,
        nextPath: NavigationEnum.REVIEW_SUBMIT,
        step: 8,
        maxSteps: 9,
        component: "AdditionalNotes",
      },
      {
        currentPath: NavigationEnum.REVIEW_SUBMIT,
        nextPath: NavigationEnum.CONFIRMATION,
        step: 9,
        maxSteps: 9,
      },
    ],
  },
];

export class DFUtils {
  constructor() {}

  static getRoute(businessUnit: string, pathName: string, condition?: any): string | undefined {
    const path = navigationPaths.find((path) => path.buPath === businessUnit)?.paths.find((p) => p.route === pathName);
    let route = "";
    if (path) {
      if (condition != null) {
        const nextRoute = path.conditions?.find((c) => c.option === condition)?.nextRoute;
        if (nextRoute) {
          route = nextRoute;
        }
      } else {
        route = path.nextRoute as string;
      }
    }
    return route;
  }
  static getNextRouteText(businessUnit: string, pathName: string, condition?: any): string | undefined {
    const path = navigationPaths.find((path) => path.buPath === businessUnit)?.paths.find((p) => p.route === pathName);
    let text = "";
    if (path) {
      if (condition != null) {
        const nextRouteText = path.conditions?.find((c) => c.option === condition)?.nextRouteText;
        if (nextRouteText) {
          text = nextRouteText;
        }
      } else {
        text = path.nextRouteText as string;
      }
    }
    return text;
  }

  static getStepInfo(
    businessUnit: string,
    currentPath: NavigationEnum,
    condition: any,
    nextPath?: NavigationEnum | null
  ): Step | undefined {
    //get step from navigationsteps
    if (nextPath != null && nextPath.trim().length > 0) {
      const step = navigationSteps
        .find((path) => path.buSteps === businessUnit && path.pathCondition === condition)
        ?.steps.find((s) => s.currentPath === currentPath && s.nextPath === nextPath);
      return step;
    } else {
      const step = navigationSteps
        .find((path) => path.buSteps === businessUnit && path.pathCondition === condition)
        ?.steps.find((s) => s.currentPath === currentPath);
      return step;
    }
  }

  static getSteps(businessUnit: string, condition: any) {
    return navigationSteps.find((path) => path.buSteps === businessUnit && path.pathCondition === condition)?.steps;
  }
}
