import { Component, OnInit } from "@angular/core";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";

import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { NavigationEnum } from "../../globalvar";
import { TranslateService } from "@ngx-translate/core";
import { Subject, fromEvent, takeUntil } from "rxjs";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.MC_CREDENTIAL_AUTH;
/**
 * MoneyCodeAuthComponent class represents the component responsible for handling the MoneyCode authorization page.
 * It initializes the necessary properties and provides methods for updating the progress bar, checking if the user can proceed, and updating the form value.
 */
@Component({
  selector: "app-moneycode-auth",
  templateUrl: "./moneycode-auth.component.html",
  styleUrls: ["./moneycode-auth.component.scss"],
})
export class MoneyCodeAuthComponent implements OnInit {
  disputeForm: DisputeForm;
  mcIsAuthorizedUser: boolean | undefined;
  canContinue = false;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _translate: TranslateService
  ) {
    //get value from disputeForm service
    this.disputeForm = this._dfService.getDisputeForm();
    this.stepCondition = "MONEYCODE";
    this.updateProgressBar(this.stepCondition);
    //apply the value to the input and set 'canContinue' var
    if (this.disputeForm.mcIsAuthorizedUser != null) {
      this.mcIsAuthorizedUser = this.disputeForm.mcIsAuthorizedUser == true;
      this.canContinue = true;
    }
    this.canProceed();
  }

  /**
   * Initializes the component.
   *
   * This method is called when the component is being initialized.
   * It sets up the necessary event listeners and handles the popstate event.
   *
   * @returns void
   */
  ngOnInit(): void {
    history.pushState(null, "");
    fromEvent(window, "popstate")
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, "");
        //this.showError = true;
      });
  }
  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It is responsible for cleaning up any resources or subscriptions.
   */
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  /**
   * Updates the progress bar with the given condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.disputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.MCAuthorization",
        nextStepName: DFUtils.getNextRouteText(this.disputeForm.lineOfBusiness as string, currentStep) as string,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Checks if the user can proceed with the current action.
   *
   * @remarks
   * This method updates the `mcIsAuthorizedUser` property of the `disputeForm` object based on the value of `mcIsAuthorizedUser`.
   * If `mcIsAuthorizedUser` is not null, it sets `mcIsAuthorizedUser` to `true` if it is equal to `true`, otherwise it sets it to `false`.
   * It also updates the `canContinue` property based on the value of `mcIsAuthorizedUser`.
   * Finally, it updates the navigation information and invokes the `updateNavigationInfo` method of the `_navigationService`.
   */
  canProceed() {
    if (this.mcIsAuthorizedUser != null) {
      this.disputeForm.mcIsAuthorizedUser = this.mcIsAuthorizedUser == true;
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }
    this.navInfo.canContinue = this.canContinue;
    this.navInfo.showBack = false;
    this.navInfo.nextRoute = DFUtils.getRoute(this.disputeForm.lineOfBusiness as string, NavigationEnum.MC_CREDENTIAL_AUTH) as string;
    this._navigationService.updateNavigationInfo(this.navInfo);
    this.updateValue;
  }

  /**
   * Updates the value of the `mcIsAuthorizedUser` property in the `disputeForm` object
   * and sets the updated `disputeForm` using the `_dfService.setDisputeForm` method.
   */
  private updateValue() {
    this.disputeForm.mcIsAuthorizedUser = this.mcIsAuthorizedUser;
    this._dfService.setDisputeForm(this.disputeForm);
  }
}
