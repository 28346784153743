export class LawEnforcementDetails {
  id: string = crypto.randomUUID();
  officerFirstName: string = "";
  officerLastName: string = "";
  dateReported: Date | undefined;
  phoneNumber: string = "";
  //agencyPersonnelTakingReport: string = "";
  reportNumber: string = "";
  referenceId: string = "";
  emailAddress: string = "";
  isReported!: boolean;

  constructor() {}
}
