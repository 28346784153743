import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { Common } from "src/app/utils/common";
import { NavigationService } from "src/app/services/navigation.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { NavigationEnum } from "../../globalvar";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { EmployeeStatus } from "src/app/models/purchase-device/employee-status.model";

/**
 * Represents the current step in the employee status page.
 */
const currentStep = NavigationEnum.EMPLOYEE_STATUS;

/**
 * Represents the EmployeeStatusComponent class.
 * This component is responsible for managing the employee status form.
 */
@Component({
  selector: "app-employee-status",
  templateUrl: "./employee-status.component.html",
  styleUrls: ["./employee-status.component.scss"],
})
export class EmployeeStatusComponent implements OnInit {
  empForm!: FormGroup;
  yesFormerOrCurrentEmployee: boolean | undefined = undefined;
  gDisputeForm!: DisputeForm;
  canContinue: boolean = false;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition = "";

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _fb: FormBuilder,
    private _dfService: DisputeFormService,
    private _translate: TranslateService
  ) {}

  /**
   * Initializes the component and sets up the initial state.
   */
  ngOnInit(): void {
    this.navInfo.showBack = true;
    this._navigationService.updateNavigationInfo(this.navInfo);

    this.gDisputeForm = this._dfService.getDisputeForm();

    this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
    this.updateProgressBar(this.stepCondition);

    this.empForm = this._fb.group({
      additionalDetails: [this.gDisputeForm.employeeStatus.additionalDetails, [Validators.required]],
    });

    if (
      this.gDisputeForm.employeeStatus.additionalDetails &&
      this.gDisputeForm.employeeStatus.additionalDetails.trim().length > 0
    ) {
      this.yesFormerOrCurrentEmployee = false;
      this.canContinue = true;
    } else if (this.gDisputeForm.employeeStatus.isCurrentOrFormer) {
      this.yesFormerOrCurrentEmployee = true;
      this.canContinue = true;
    }

    this.empForm.statusChanges.subscribe(
      Common.debounce(() => {
        this.checkForNext();
        //this.saveAddtionalDetails();
      }, 500)
    );
    this.checkForNext();
  }

  /**
   * Updates the progress bar with the specified condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.EmployeeStatus",
        nextStepName: DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }

  /**
   * Saves the additional details of the employee status.
   */
  private saveAddtionalDetails() {
    const empStatus: EmployeeStatus = {
      isCurrentOrFormer: this.yesFormerOrCurrentEmployee,
      additionalDetails: this.empForm.value.additionalDetails,
    };
    this._dfService.setEmployeeStatus(empStatus);
  }

  /**
   * Checks if the next step can be performed based on the employee status.
   * If the employee is a former or current employee, it prompts for additional details and resets the form if confirmed.
   * If the employee is not a former or current employee and the form is valid, allows to continue.
   * Otherwise, does not allow to continue.
   * Updates the navigation information accordingly.
   */
  checkForNext() {
    if (this.yesFormerOrCurrentEmployee == true) {
      if (this.empForm.get("additionalDetails")?.value) {
        Swal.fire({
          title: this._translate.instant("EmployeeStatus.ChangeAnswer"),
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: this._translate.instant("Common.Yes"),
          denyButtonText: this._translate.instant("Common.No"),
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.empForm.reset();
            this.saveAddtionalDetails();
          } else {
            this.yesFormerOrCurrentEmployee = false;
          }
        });
      }
      this.canContinue = true;
    } else if (this.yesFormerOrCurrentEmployee == false && this.empForm.valid) {
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }
    this.saveAddtionalDetails();
    this.navInfo.canContinue = this.canContinue;
    this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, NavigationEnum.EMPLOYEE_STATUS) as string;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }
}
