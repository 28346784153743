import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { Common } from "src/app/utils/common";
import { FraudType, LineOfBusiness, NavigationEnum } from "../../globalvar";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { TranslateService } from "@ngx-translate/core";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.CARD_LOCATION;

/**
 * Represents the CardLocationComponent class.
 * This component is responsible for managing the card location form and updating the progress bar and navigation information.
 */
@Component({
  selector: "app-card-location",
  templateUrl: "./card-location.component.html",
  styleUrls: ["./card-location.component.scss"],
})
export class CardLocationComponent implements OnInit {
  cardLocationForm: FormGroup;
  gDisputeForm: DisputeForm;
  readyToNext: boolean = false;
  pageHistory: History;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _fb: FormBuilder,
    private _translate: TranslateService
  ) {
    this.pageHistory = history;

    this.gDisputeForm = this._dfService.getDisputeForm();
    this.cardLocationForm = this._fb.group({
      cardLocation: [this.gDisputeForm.locationCardWasKept, Validators.required],
    });

    if (this.cardLocationForm.valid) {
      this.readyToNext = true;
    }

    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
        this.updateProgressBar(this.stepCondition, NavigationEnum.POLICE);
      } else {
        this.updateProgressBar(this.gDisputeForm.fraudType, NavigationEnum.POLICE);
      }
    } else {
      this.updateProgressBar(null, NavigationEnum.POLICE);
    }
  }

  /**
   * Initializes the component.
   * Subscribes to the statusChanges event of the cardLocationForm and debounces the callback function.
   * Calls the nextCheck and updateCardLocation methods.
   */
  ngOnInit(): void {
    this.cardLocationForm.statusChanges.subscribe(
      Common.debounce(() => {
        this.nextCheck();
        this.updateCardLocation();
      }, 500)
    );
    this.nextCheck();
  }
  /**
   * Updates the progress bar based on the given condition and nextPath.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(
      this.gDisputeForm.lineOfBusiness as string,
      currentStep,
      condition, //this.gDisputeForm.fraudType,
      nextPath
    );
    const nextStepName = DFUtils.getNextRouteText(
      this.gDisputeForm.lineOfBusiness as string,
      currentStep,
      null
    ) as string;
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.CardLocation",
        nextStepName: nextStepName,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Updates the card location based on the value of the cardLocationForm.
   */
  updateCardLocation() {
    this._dfService.setCardLocation(this.cardLocationForm.value.cardLocation);
  }

  /**
   * Checks if the card location form is valid and updates the navigation information accordingly.
   */
  nextCheck() {
    if (this.cardLocationForm.valid) {
      this.readyToNext = true;
    } else {
      this.readyToNext = false;
    }
    this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep, null) as string;
    this.navInfo.canContinue = this.readyToNext;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }
}
