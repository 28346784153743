<mat-expansion-panel #pdPanel hideToggle>
  <mat-expansion-panel-header>
    <div class = "accordionHeaderMobile">
      <mat-panel-title>
        <span class="accordionHeaderIcon">
          <mat-icon *ngIf="purchaseDevice.purchaseDeviceType === 'MoneyCode'">receipt</mat-icon>
          <mat-icon *ngIf="purchaseDevice.purchaseDeviceType !== 'MoneyCode'">credit_card</mat-icon>
        </span>
        <span class="accordionHeaderDeviceInfo">
          {{
            this.purchaseDevice.cardNumber
              ? (this.purchaseDevice.cardNumber | cardNumber)
              : ("ImpactedPayments.PaymentMethod.PaymentMethodLabel" | translate)
          }}
        </span>
      </mat-panel-title>
      <mat-panel-description>
        <span class="accordionHeaderDeviceVL"></span>
        <span class="accordionHeaderTrxSum">
          {{ sum | currency }}
          {{ "ImpactedPayments.PaymentMethod.Total" | translate }}
        </span>
        <span class="accordionHeaderTrxCount">
          ({{ count | number }} {{ "ImpactedPayments.PaymentMethod.Transaction" | translate | lowercase }})</span
        >
      </mat-panel-description>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="stopProp($event)" *ngIf="!purchaseDevice.isPrePop">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="deletePurchaseDevice()">
        <span>{{ "Common.Delete" | translate }}</span>
      </button>
    </mat-menu>
    <button mat-icon-button class="expandButton">
      <mat-icon>{{ pdPanel.expanded ? "remove" : "add" }}</mat-icon>
    </button>
  </mat-expansion-panel-header>
  <form [formGroup]="pdGroup">
    <div class="pdInputs">
      <div class="inputBlock">
        <div *ngIf="purchaseDevice.dateAbuseBegan" class="inputRow">
          <div style="width: 50%">
            <mat-label style="font-weight: bold">{{ "ImpactedPayments.PaymentMethod.Began" | translate }}: </mat-label>
            <mat-label>{{ purchaseDevice.dateAbuseBegan | date }}</mat-label>
          </div>
          <div style="width: 50%">
            <mat-label style="font-weight: bold">{{ "ImpactedPayments.PaymentMethod.Ended" | translate }}: </mat-label>
            <mat-label>{{ purchaseDevice.dateAbuseEnded | date }}</mat-label>
          </div>
        </div>
        <div class="inputRow">
          <mat-form-field appearance="outline">
            <mat-label>{{ "ImpactedPayments.PaymentMethod.CardholderFirstName" | translate }}</mat-label>
            <input matInput type="text" formControlName="cardholderFirst" />
            <mat-error *ngIf="pdGroup.get('cardholderFirst')?.hasError('required')">
              {{ "Error.Required" | translate }}
            </mat-error>
            <mat-error *ngIf="pdGroup.get('cardholderFirst')?.hasError('pattern')">
              {{ "Error.CannotContainNumbersOrChars" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                pdGroup.get('cardholderFirst')?.hasError('maxlength') ||
                pdGroup.get('cardholderFirst')?.hasError('minlength')
              "
            >
              {{ "Error.MustBe2-30Chars" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ "ImpactedPayments.PaymentMethod.CardholderLastName" | translate }}</mat-label>
            <input matInput type="text" formControlName="cardholderLast" />
            <mat-error *ngIf="pdGroup.get('cardholderLast')?.hasError('required')">{{
              "Error.Required" | translate
            }}</mat-error>
            <mat-error *ngIf="pdGroup.get('cardholderLast')?.hasError('pattern')">
              {{ "Error.CannotContainNumbersOrChars" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                pdGroup.get('cardholderLast')?.hasError('maxlength') ||
                pdGroup.get('cardholderLast')?.hasError('minlength')
              "
            >
              {{ "Error.MustBe2-30Chars" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="applicableCheck">
          <mat-checkbox formControlName="cardHolderNameApplies" (change)="cardHolderNameAppliesChanged($event)">{{
            "ImpactedPayments.PaymentMethod.CardHolderNameApplicable" | translate
          }}</mat-checkbox>
        </div>
        <div class="inputRow">
          <mat-form-field appearance="outline" style="width: 210px; flex-grow: 0">
            <mat-label>{{ "ImpactedPayments.PaymentMethod.VehicleAssetUniteDriverId" | translate }}</mat-label>
            <input matInput type="text" formControlName="vehicleAssetUnitDriverId" maxlength="20" />
            <mat-error *ngIf="pdGroup.get('vehicleAssetUnitDriverId')?.hasError('maxlength')">
              {{ "Error.CannotBeMoreThan20Chars" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            style="width: 210px; flex-grow: 0"
            *ngIf="purchaseDevice.lineOfBusiness == 'OTR' && purchaseDevice.purchaseDeviceType === 'SmartFunds Card'"
          >
            <mat-label>{{ "ImpactedPayments.PaymentMethod.NewCardNumber" | translate }}</mat-label>
            <input
              matInput
              type="numeric"
              formControlName="newCardNumber"
              maxlength="20"
              minlength="10"
              mask="{{ newCardNumberMask }}"
            />
            <mat-error *ngIf="pdGroup.get('newCardNumber')?.hasError('maxlength')">
              {{ "Error.CannotBeMoreThan20Chars" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                pdGroup.get('newCardNumber')?.hasError('maxlength') ||
                pdGroup.get('newCardNumber')?.hasError('minlength')
              "
            >
              {{ "Error.MustBe10-20Chars" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="inputRow">
          <mat-form-field appearance="outline" *ngIf="!purchaseDevice.isPrePop">
            <mat-label>{{ cardNumLabel }}</mat-label>
            <input matInput type="text" formControlName="cardNumber" mask="{{ cardNumMask }}" />
            <mat-error *ngIf="pdGroup.get('cardNumber')?.hasError('required')">{{
              "Error.Required" | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <mat-accordion>
    <app-transaction-detail
      *ngFor="let trx of purchaseDevice.transactions"
      [transaction]="trx"
      (extractDates)="extractDates()"
      (recalculatePdTotal)="recalculatePdTotal()"
      (removeTrx)="deleteTrx($event)"
      (updateStateTrx)="updateState()"
    ></app-transaction-detail>
    <button class="add-btn transparent" (click)="newTransaction()">
      <mat-icon>add</mat-icon>{{ "ImpactedPayments.PaymentMethod.Transaction" | translate }}
    </button>
  </mat-accordion>
</mat-expansion-panel>
