import { LocationStrategy } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, fromEvent, takeUntil } from "rxjs";
import { TimeoutService } from "src/app/services/timeout.service";

interface MessageState {
  message: string;
}

/**
 * Represents the ErrorPageComponent class.
 * This component is responsible for displaying an error page.
 */
@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent implements OnInit {
  message: string = this._translate.instant("Common.GenericError");
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private _location: LocationStrategy,
    private _translate: TranslateService,
    private _timeout: TimeoutService
  ) {
    const state = this._location.getState() as MessageState;

    if (state && typeof state === "object" && "message" in state) {
      this.message = state.message;
    }
    this._timeout.idleWatchStop();
  }

  /**
   * Initializes the component.
   * Disables the back button and subscribes to the popstate event to handle navigation.
   */
  ngOnInit(): void {
    //this disables the back button
    history.pushState(null, "");
    fromEvent(window, "popstate")
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, "");
        //this.showError = true;
      });
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It is responsible for cleaning up any resources or subscriptions.
   */
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
