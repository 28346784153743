import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { LawEnforcementDetails } from "src/app/models/purchase-device/law-enforcement-details.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { Common } from "src/app/utils/common";
import Swal from "sweetalert2";
import { FraudType, LineOfBusiness, NavigationEnum, eProductType } from "../../globalvar";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { NavigationService } from "src/app/services/navigation.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { TranslateService } from "@ngx-translate/core";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.POLICE;
/**
 * Represents the PoliceReportComponent class.
 * This component is responsible for handling the police report form.
 */
@Component({
  selector: "app-police-report",
  templateUrl: "./police-report.component.html",
  styleUrls: ["./police-report.component.scss"],
})
export class PoliceReportComponent implements OnInit {
  yesReport: boolean | undefined;
  report: LawEnforcementDetails = new LawEnforcementDetails();
  reportForm: FormGroup;
  gDisputeForm: DisputeForm = {} as DisputeForm;
  canContinue: boolean = false;
  navInfo: NavigationInfo = new NavigationInfo();
  minDate: Date = new Date();
  maxDate: Date = new Date();

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _fb: FormBuilder,
    private _dfService: DisputeFormService,
    private _translate: TranslateService
  ) {
    //pull service dispute form
    this.gDisputeForm = this._dfService.getDisputeForm();

    //local var to store report object and send to service with new/updated values
    this.report = this.gDisputeForm?.lawEnforcementDetails;

    //form used to display to user
    this.reportForm = this._fb.group({
      id: new FormControl(this.report.id ?? crypto.randomUUID()),
      dateOfReport: new FormControl(this.report.dateReported, Validators.required),
      agencyOfficerFirstName: new FormControl(this.report.officerFirstName, Validators.required),
      agencyOfficerLastName: new FormControl(this.report.officerLastName, Validators.required),
      phoneNumber: new FormControl(this.report.phoneNumber, Validators.required),
      reportNumber: new FormControl(this.report.reportNumber, Validators.required),
      referenceId: new FormControl(this.report.referenceId, Validators.required),
      emailAddress: new FormControl(this.report.emailAddress, Validators.required),
    });
    this.yesReport = this.report.isReported;

    this.canContinue = this.reportForm.valid || this.yesReport === false;
  }

  /**
   * Initializes the component after Angular has initialized all data-bound properties.
   * - Sets the minimum date to today minus 120 days.
   * - Subscribes to the statusChanges event of the reportForm and debounces the callback function.
   * - If the status of the reportForm is "VALID", calls the saveReport method with the reportForm as an argument.
   * - Calls the checkForNext method.
   */
  ngOnInit(): void {
    //set mindate to today-120 days
    this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() - 120));

    this.reportForm.statusChanges.subscribe(
      Common.debounce((status: any) => {
        if (status == "VALID") {
          //call the service to update the form
          this.saveReport(this.reportForm);
        }

        this.checkForNext();
      }, 500)
    );
    this.checkForNext();
  }

  /**
   * Updates the progress bar with the specified condition and next path.
   *
   * @param condition - The condition for updating the progress bar.
   * @param nextPath - The next path to navigate to after updating the progress bar (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    let nextStepName = "";
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      nextStepName = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep, this.gDisputeForm.fraudType) as string;
    } else {
      nextStepName = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    }
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.PoliceReport",
        nextStepName: nextStepName,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }

  /**
   * Checks if the report form is valid and sets the value of `canContinue` accordingly.
   * If the form is valid, `canContinue` is set to `true`.
   * If the form is not valid and `yesReport` is `false`, `canContinue` is set to `true`.
   * If the form is not valid and `yesReport` is `true`, `canContinue` is set to `false`.
   * If `yesReport` is `undefined`, `canContinue` is set to `false`.
   * Sets the value of `isReported` in the `report` object based on the value of `yesReport`.
   * Calls the `validateChange` function.
   */
  checkForNext() {
    if (this.reportForm.valid) {
      this.canContinue = true;
    } else if (!this.reportForm.valid && this.yesReport === false) {
      this.canContinue = true;
    } else if (!this.reportForm.valid && this.yesReport === true) {
      this.canContinue = false;
    } else if (this.yesReport == undefined) {
      this.canContinue = false;
    }
    this.report.isReported = this.yesReport as boolean;

    this.validateChange();
  }
  /**
   * Updates the navigation information based on the current state of the dispute form.
   * Determines the next route and condition based on the line of business and fraud type.
   * Updates the navigation service with the new navigation information.
   * Updates the progress bar based on the condition and next route.
   */
  updateNavigation() {
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep, this.gDisputeForm.fraudType) as string;
    }
    this.navInfo.canContinue = this.canContinue;
    this._navigationService.updateNavigationInfo(this.navInfo);

    let condition = "";
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      condition =
        this.gDisputeForm.cardInPossession === true && this.gDisputeForm.productType !== eProductType.MONEYCODE
          ? "IN_POSSESSION"
          : this.gDisputeForm.cardInPossession === false && this.gDisputeForm.productType !== eProductType.MONEYCODE
          ? "NO_POSSESSION"
          : this.gDisputeForm.productType == eProductType.MONEYCODE
          ? "MONEYCODE"
          : "INITIAL_PHYSICAL";
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        condition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
      } else {
        condition = this.gDisputeForm.fraudType as string;
      }
    }
    this.updateProgressBar(condition, this.navInfo.nextRoute as NavigationEnum);
  }
  /**
   * Validates the change in the police report.
   * If the conditions are met, a confirmation dialog is shown to the user.
   * If the user confirms, the report form is reset and the navigation is updated.
   * If the user denies, a toast message is shown and the 'yesReport' flag is set to true.
   * If the conditions are not met, the navigation is updated directly.
   */
  validateChange() {
    if (this.canContinue && this.reportForm.valid && !this.yesReport) {
      Swal.fire({
        title: this._translate.instant("PoliceReport.ChangeAnswer"),
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: this._translate.instant("Common.Yes"),
        denyButtonText: this._translate.instant("Common.No"),
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.reportForm.reset();
          this.report = {} as LawEnforcementDetails;
          this.saveReport(this.reportForm);

          this.updateNavigation();
        } else if (result.isDenied) {
          Toast.fire({
            icon: "info",
            title: this._translate.instant("Common.NotRemoved"),
          });
          this.yesReport = true;
          return;
        }
      });
    } else {
      this.updateNavigation();
    }
  }

  /**
   * Saves the police report by extracting the necessary information from the provided FormGroup.
   *
   * @param report - The FormGroup containing the police report information.
   */
  private saveReport(report: FormGroup) {
    this.report.id = report.controls["id"]?.value;
    this.report.dateReported = report.controls["dateOfReport"]?.value;
    this.report.officerFirstName = report.controls["agencyOfficerFirstName"]?.value;
    this.report.officerLastName = report.controls["agencyOfficerLastName"]?.value;
    this.report.phoneNumber = report.controls["phoneNumber"]?.value;
    this.report.reportNumber = report.controls["reportNumber"]?.value;
    this.report.referenceId = report.controls["referenceId"]?.value;
    this.report.emailAddress = report.controls["emailAddress"]?.value;

    this._dfService.setLawEnforcementInfo(this.report);
  }
}
