export enum LineOfBusiness {
  OTR = "OTR",
  NAF = "NAF",
}
export enum eProductType {
  FLEETCARD = "Fleet Card",
  SMARTFUNDS = "SmartFunds Card",
  MONEYCODE = "MoneyCode",
}

export enum FraudType {
  DRIVER_MISUSE = "Driver Misuse",
  NO_POSSESSION = "Card Lost/Stolen",
  COMPROMISED = "Card Compromised",
}

export enum eEmploymentStatus {
  CURRENT_EMPLOYEE = "Current Employee",
  FORMER_EMPLOYEE = "Former Employee",
  OTHER = "Other",
}

export enum NavigationEnum {
  TERM_AGREEMENT = "/dispute/term-conditions",
  PRODUCT_SELECTION = "/dispute/product-selection",
  PHYSICAL_POSSESSION = "/dispute/physical-possession",
  EMPLOYEE_STATUS = "/dispute/employee-status",

  FRAUD_TYPE = "/dispute/fraud-type",
  ACTIVITY_KNOWLEDGE = "/dispute/activity-knowledge",
  LAST_TRANSACTION = "/dispute/last-transaction",
  CARD_LOCATION = "/dispute/card-location", //where was it kept
  CURRENT_LOCATION = "/dispute/current-location", ///need to build
  ILLEGITIMACY = "/dispute/illegitimacy", //need to build

  IMPACTED_PAYMENTS = "/dispute/impacted-payments",
  POLICE = "/dispute/police-report",
  SUSPECTS = "/dispute/suspects",

  MC_CREDENTIAL_AUTH = "/dispute/moneycode/auth",
  MC_CREDENTIAL_SHARED = "/dispute/moneycode/credential-shared",
  MC_INCIDENT_OVERVIEW = "/dispute/moneycode/incident-overview", //from here, path is the same as smartfund/Fleet, 'IN Possession' path

  ADDITIONAL_INFO = "/dispute/additional-info",
  REVIEW_SUBMIT = "/dispute/review-submit-claim",
  CONFIRMATION = "/dispute/confirmation",
}

//DO we need an enum for each BU??
// export enum NAFNavigationEnum {
//   PROD_SELECT_FLEET = "/dispute/physicalpossession",
//   PROD_SELECT_SMARTFUNDS = "/dispute/physicalpossession",
//   PROD_SELECT_MONEYCODE = "/dispute/moneycode/moneycodeauth",
//   POLICE = "/dispute/suspects",
// }

//used by mat-datepicker for date formatting
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD", // how to parse the input
  },
  display: {
    dateInput: "DD", // display in control
    monthYearLabel: "MMMM yyyy", // month/year on calendar
    dateA11yLabel: "DD",
    monthYearA11yLabel: "MMMM yyyy",
  },
};
