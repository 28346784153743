import { Component, OnInit } from "@angular/core";
import { FraudType, NavigationEnum } from "../../globalvar";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { TranslateService } from "@ngx-translate/core";
import { Subject, fromEvent, takeUntil } from "rxjs";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.FRAUD_TYPE;

/**
 * Represents the FraudTypeSelectionComponent class.
 * This component is responsible for handling the selection of fraud types.
 */
@Component({
  selector: "app-fraud-type-selection",
  templateUrl: "./fraud-type-selection.component.html",
  styleUrls: ["./fraud-type-selection.component.scss"],
})
export class FraudTypeSelectionComponent implements OnInit {
  selectedFraud: FraudType | undefined;
  readyToNext: boolean = false;
  misuse = FraudType.DRIVER_MISUSE;
  compromised = FraudType.COMPROMISED;
  noPossesion = FraudType.NO_POSSESSION;
  gDisputeForm: DisputeForm;
  navInfo: NavigationInfo = new NavigationInfo();
  private unsubscriber: Subject<void> = new Subject<void>();
  nextStepName: string = "";

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _translate: TranslateService
  ) {
    this.navInfo.showBack = false;
    this._navigationService.updateNavigationInfo(this.navInfo);

    this.gDisputeForm = this._dfService.getDisputeForm();
    this.selectedFraud = this.gDisputeForm.fraudType;

    history.pushState(null, "");
    fromEvent(window, "popstate")
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, "");
        //this.showError = true;
      });
  }

  /**
   * Initializes the component.
   *
   * This method is called when the component is being initialized.
   * It updates the progress bar based on the selected fraud type,
   * and performs the next check.
   */
  ngOnInit(): void {
    this.updateProgressBar(!this.selectedFraud ? "INITIAL" : this.selectedFraud);
    this.nextCheck();
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It is responsible for cleaning up any resources or subscriptions.
   */
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  /**
   * Updates the progress bar based on the given condition and nextPath.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    this.nextStepName =
      condition != "INITIAL"
        ? (DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep, this.selectedFraud) as string)
        : "PageName.SelectOption";
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.FraudType",
        nextStepName: this.nextStepName,
        totalSteps: condition == "INITIAL" ? 10 : stepInfo?.maxSteps,
        currentStep: condition == "INITIAL" ? 1 : stepInfo?.step,
      })
    );
  }
  /**
   * Checks if a fraud type is selected and updates the navigation information accordingly.
   */
  nextCheck() {
    if (this.selectedFraud !== undefined) {
      this.readyToNext = true;
    } else {
      this.readyToNext = false;
    }
    this.navInfo.showBack = false;
    this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, NavigationEnum.FRAUD_TYPE, this.selectedFraud) as string;
    this.navInfo.canContinue = this.readyToNext;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }

  /**
   * Handles the change event for the fraud selection.
   * Updates the dispute form, performs the next check, and updates the progress bar based on the selected fraud.
   */
  onFraudChange() {
    this.updateDisputeForm();
    this.nextCheck();
    this.updateProgressBar(this.selectedFraud);
  }

  /**
   * Updates the dispute form based on the selected fraud type.
   * If the selected fraud type is NO_POSSESSION, sets the cardInPossession property of gDisputeForm to false.
   * If the selected fraud type is not equal to the current fraud type, sets the cardInPossession property of gDisputeForm to undefined.
   * If the selected fraud type is not COMPROMISED, sets the illegitimacyDetails property of gDisputeForm to an empty string.
   * Sets the fraudType property of gDisputeForm to the selected fraud type.
   * Calls the setFraudType method of _dfService with the selected fraud type.
   */
  private updateDisputeForm() {
    if (this.selectedFraud === FraudType.NO_POSSESSION) {
      this.gDisputeForm.cardInPossession = false;
    } else {
      if(this.gDisputeForm.fraudType != this.selectedFraud){
        this.gDisputeForm.cardInPossession = undefined;
      }
    }
    if (this.selectedFraud != FraudType.COMPROMISED) {
      this.gDisputeForm.illegitimacyDetails = "";
    }

    this.gDisputeForm.fraudType = this.selectedFraud;
    this._dfService.setFraudType(this.selectedFraud!);
  }
}
