import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { firstValueFrom, map, Observable } from "rxjs";
import { CaptchaService } from "../services/captcha.service";
import { ApiService } from "../services/api.service";

@Injectable({
  providedIn: "root",
})
export class RouteGuard implements CanActivate {
  constructor(private apiService: ApiService, private router: Router, private _captchaService: CaptchaService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check your authentication logic here
    //console.log(this.captchaService.getToken());
    return this._captchaService.isValid$.pipe(
      map((r) => {
        if (r) {
          //this.captchaService.getValid()) {
          return true;
        } else {
          // Redirect to login page or any other desired route
          this.router.navigate(["/captcha"]);
          return false;
        }
      })
    );
  }
}
