import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { NavigationEnum } from "../../globalvar";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { TranslateService } from "@ngx-translate/core";
import { PhysicalCardDetails } from "src/app/models/physicalCardDetails.model";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.CURRENT_LOCATION;

/**
 * Represents the CurrentLocationComponent class.
 * This component is responsible for managing the current location page in the dispute form module.
 */
@Component({
  selector: "app-current-location",
  templateUrl: "./current-location.component.html",
  styleUrls: ["./current-location.component.scss"],
})
export class CurrentLocationComponent implements OnInit {
  isKnown: boolean | undefined;
  physicalCardDetails: PhysicalCardDetails = {} as PhysicalCardDetails;
  canContinue: boolean = false;
  df: DisputeForm = {} as DisputeForm;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";

  constructor(
    private _cdr: ChangeDetectorRef,
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _translate: TranslateService
  ) {
    //pull service dispute form
    this.df = this._dfService.getDisputeForm();
    this.stepCondition = this.df.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
    this.updateProgressBar(this.stepCondition);

    //local var to store report object and send to service with new/updated values
    this.physicalCardDetails = this.df.physicalCardDetails;
    this.isKnown = this.physicalCardDetails.cardLocationKnown;
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.checkForNext();
  }
  /**
   * Updates the progress bar with the specified condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.df.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.CurrentLocation",
        nextStepName: DFUtils.getNextRouteText(this.df.lineOfBusiness as string, currentStep) as string,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Checks if the current location is known and updates the necessary properties and services.
   */
  checkForNext() {
    this.canContinue = (this.isKnown !== undefined);

    this.physicalCardDetails.cardLocationKnown = this.isKnown as boolean;

    this.navInfo.canContinue = this.canContinue;
    this.navInfo.nextRoute = DFUtils.getRoute(this.df.lineOfBusiness as string, NavigationEnum.CURRENT_LOCATION) as string;
    this._navigationService.updateNavigationInfo(this.navInfo);

    this._dfService.setPhysicalCardDetails(this.physicalCardDetails);
  }
}
