import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProgressInfo } from "./models/progressInfo.model";

@Injectable({
  providedIn: "root",
})
export class ProgressBarService {
  // private _isVisible = new BehaviorSubject<boolean>(false);
  // isVisible$ = this._isVisible.asObservable();
  // private _stepName = new BehaviorSubject<string>("");
  // stepName$ = this._stepName.asObservable();
  // private _nextStepName = new BehaviorSubject<string>("");
  // nextStepName$ = this._stepName.asObservable();
  // private _totalSteps = new BehaviorSubject<number>(10);
  // totalSteps$ = this._totalSteps.asObservable();
  // private _currentStep = new BehaviorSubject<number>(1);
  // currentStep$ = this._currentStep.asObservable();

  private _updateProgressEvent = new BehaviorSubject<ProgressInfo>({} as ProgressInfo);
  updateProgressEvent = this._updateProgressEvent.asObservable();
  constructor() {}

  hideProgressBar() {
    this._updateProgressEvent.next(
      Object.assign(new ProgressInfo(), {
        isVisible: false,
        stepName: "",
        nextStepName: "",
        totalSteps: 0,
        currentStep: 0,
      })
    );
  }
  updateProgressInfo(progressInfo: ProgressInfo) {
    // this._isVisible.next(progressInfo.isVisible);
    // this._stepName.next(progressInfo.stepName);
    // this._nextStepName.next(progressInfo.nextStepName);
    // this._totalSteps.next(progressInfo.totalSteps);
    // this._currentStep.next(progressInfo.currentStep);

    this._updateProgressEvent.next(progressInfo);
  }
}
