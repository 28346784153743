import { Component, OnInit } from "@angular/core";
import { NavigationEnum } from "../../globalvar";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { NavigationService } from "src/app/services/navigation.service";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { Common } from "src/app/utils/common";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.ILLEGITIMACY;

/**
 * IllegitimacyComponent class represents the component responsible for handling the illegitimacy page in the dispute form module.
 * It initializes the necessary properties and provides methods for updating the progress bar and navigation information.
 */
@Component({
  selector: "app-illegitimacy",
  templateUrl: "./illegitimacy.component.html",
  styleUrls: ["./illegitimacy.component.scss"],
})
export class IllegitimacyComponent implements OnInit {
  gDisputeForm: DisputeForm;
  illegitimacyForm: FormGroup;
  canContinue: boolean = false;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";
  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _fb: FormBuilder,
    private _translate: TranslateService
  ) {
    this.gDisputeForm = this._dfService.getDisputeForm();
    this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
    this.updateProgressBar(this.stepCondition);
    this.illegitimacyForm = this._fb.group({
      illegitimacyDetails: [this.gDisputeForm.illegitimacyDetails, Validators.required],
    });
    this.canContinue =
      this.gDisputeForm.illegitimacyDetails && this.gDisputeForm.illegitimacyDetails.trim().length > 0 ? true : false;
  }

  /**
   * Initializes the component after Angular has initialized all data-bound properties.
   * Subscribes to the value changes of the "illegitimacyDetails" form control and updates the corresponding service.
   * Updates the navigation and checks if the form is valid.
   */
  ngOnInit(): void {
    this.illegitimacyForm.get("illegitimacyDetails")?.valueChanges.subscribe(
      Common.debounce(() => {
        this._dfService.setIllegitimacyDetail(this.illegitimacyForm.value.illegitimacyDetails);
        this.canContinue = this.illegitimacyForm.valid;
        this.updateNav();
      }, 500)
    );
    this.updateNav();
  }
  /**
   * Updates the progress bar with the specified condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.Illegitimacy",
        nextStepName: DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Updates the navigation information for the illegitimacy component.
   */
  updateNav() {
    this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, NavigationEnum.ILLEGITIMACY) as string;
    this.navInfo.canContinue = this.canContinue;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }
}
