<h1>{{ "FraudType.Header" | translate }}</h1>
<p>{{ "FraudType.Detail" | translate }}</p>
<mat-button-toggle-group
  class="button-toggle-group"
  (change)="onFraudChange()"
  name="fraudType"
  aria-label="label"
  [(ngModel)]="selectedFraud"
  vertical
>
  <mat-button-toggle class="button-toggle blue" value="{{ noPossesion }}"
    ><mat-icon>search</mat-icon>
    <span>
      {{ "FraudType.ButtonCardPossession" | translate }}
    </span>
  </mat-button-toggle>
  <mat-button-toggle class="button-toggle blue" value="{{ misuse }}"
    ><mat-icon>drive_eta</mat-icon>
    <span>
      {{ "FraudType.ButtonDriverMisuse" | translate }}
    </span>
  </mat-button-toggle>
  <mat-button-toggle class="button-toggle blue" value="{{ compromised }}"
    ><mat-icon>credit_card</mat-icon>
    <span>
      {{ "FraudType.ButtonCardCompromised" | translate }}
    </span>
  </mat-button-toggle>
</mat-button-toggle-group>
