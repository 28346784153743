import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class CaptchaService {
  private _isValid$ = new BehaviorSubject<boolean>(false);
  isValid$ = this._isValid$.asObservable();

  constructor(private apiService: ApiService) {}

  setValid(valid: boolean) {
    this._isValid$.next(valid);
  }

  getValid(): boolean {
    return this._isValid$.getValue();
  }

  validateCaptcha(token: string): Observable<object> {
    //const token = this._captchaToken$.getValue();
    try {
      if (token && token !== "") {
        this._isValid$.next(true);
        return this.apiService.validateCaptcha(token);
      } else {
        this._isValid$.next(false);
        throw Error("Invalid token");
      }
    } catch (err: any) {
      this._isValid$.next(false);
      throw Error(err.message);
    }
  }
}
