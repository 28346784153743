import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { regexPatterns } from "src/app/global-constants";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { WexErrorStateMatcher } from "src/app/utils/error-state";
import { LineOfBusiness, NavigationEnum } from "../../globalvar";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { SalesforceInfoService } from "src/app/services/sfInfo.service";

/**
 * Component for displaying and handling the term and conditions form.
 *
 * @remarks
 * This component is responsible for displaying the term and conditions form, validating user input, and saving the form data.
 *
 * @example
 * ```typescript
 * const termConditionsComponent = new TermConditionsComponent();
 * termConditionsComponent.ngOnInit();
 * ```
 */
@Component({
  selector: "app-termconditions",
  templateUrl: "./termconditions.component.html",
  styleUrls: ["./termconditions.component.scss"],
})
export class TermConditionsComponent implements OnInit {
  matcher = new WexErrorStateMatcher();
  //signature: string = "";
  termFormGroup = new FormGroup(
    {
      signatureControl: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(60),
        Validators.pattern(regexPatterns.intlName),
      ]),
      emailControl: new FormControl("", [Validators.required, Validators.minLength(4), Validators.maxLength(255)]),
      confirmEmailControl: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255),
      ]),
      certControl: new FormControl(false, [Validators.requiredTrue]),
    },
    { validators: [this.emailConfirmationValidator().bind(this)] }
  );
  today = new Date();
  navInfo: NavigationInfo = new NavigationInfo();
  canContinue: boolean = false;
  gDisputeForm!: DisputeForm;
  showEmail: boolean = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _translate: TranslateService,
    private _dfService: DisputeFormService,
    private _sfInfoService: SalesforceInfoService
  ) {
    this._progressBarService.hideProgressBar();

    this.gDisputeForm = this._dfService.getDisputeForm();

    let sfInfo = this._sfInfoService.getSFInfo();
    if (!sfInfo.contactEmail || sfInfo.contactEmail.trim() == "") {
      this.showEmail = true;
    } else {
      this.termFormGroup.controls["emailControl"].setValidators(null);
      this.termFormGroup.controls["confirmEmailControl"].setValidators(null);
      this.termFormGroup.controls["emailControl"].setErrors(null);
      this.termFormGroup.controls["confirmEmailControl"].setErrors(null);
    }
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.termFormGroup.get(controlName)?.hasError(errorName) as boolean;
  };

  /**
   * Initializes the component and subscribes to the statusChanges event of the termFormGroup.
   * Updates the navigation information based on the form status.
   * Saves the data if the form status is valid.
   * Sets the navigation information for the next route based on the line of business.
   */
  ngOnInit(): void {
    this.termFormGroup.statusChanges.subscribe((status) => {
      this.canContinue = status === "VALID" ? true : false;

      this.navInfo.canContinue = this.canContinue;
      this._navigationService.updateNavigationInfo(this.navInfo);
      if (this.canContinue) {
        this.saveData();
      }
    });

    this._translate.get("TermConditions.LetsGetStarted").subscribe((text) => {
      this.navInfo.showBack = false;
      this.navInfo.nextButtonText = "TermConditions.LetsGetStarted";
      this.navInfo.canContinue = this.canContinue;
      this.navInfo.nextRoute =
        this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR
          ? NavigationEnum.PRODUCT_SELECTION
          : NavigationEnum.FRAUD_TYPE;
      this._navigationService.updateNavigationInfo(this.navInfo);
    });
  }

  /**
   * Lifecycle hook that is called after every check of a component's view.
   * It is called immediately after the `ngAfterViewInit` and `ngAfterContentChecked` lifecycle hooks.
   * Use this hook to detect and respond to changes that occur after the view has been checked.
   */
  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }
  /**
   * Validates the email confirmation in a form control.
   *
   * @returns A validation function that checks if the email and confirm email values match.
   * If they don't match, it sets the emailMismatch error on both controls.
   * If they match and the emailMismatch error was previously set, it removes the error.
   *
   * @param control - The form control to validate.
   *
   * @returns A ValidationErrors object if the email and confirm email values don't match, null otherwise.
   */
  emailConfirmationValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value.emailControl;
      const confirm = control.value.confirmEmailControl;

      if (email !== confirm) {
        //set custom date error on employedTo control
        control.get("emailControl")?.setErrors({ ...control.get("emailControl")?.errors, emailMismatch: true });
        control
          .get("confirmEmailControl")
          ?.setErrors({ ...control.get("confirmEmailControl")?.errors, emailMismatch: true });
      } else {
        const emailControlErrs = control.get("emailControl")?.errors;
        if (emailControlErrs && emailControlErrs["emailMismatch"]) {
          delete emailControlErrs["emailMismatch"];
          control.get("emailControl")?.updateValueAndValidity();
        }
        const confEmailControlErrs = control.get("confirmEmailControl")?.errors;
        if (confEmailControlErrs && confEmailControlErrs["emailMismatch"]) {
          delete confEmailControlErrs["emailMismatch"];
          control.get("confirmEmailControl")?.updateValueAndValidity();
        }
      }
      return null;
    };
  }

  /**
   * Saves the data from the term and conditions form.
   *
   * @remarks
   * This method retrieves the values from the form controls and saves them using the `_dfService` service.
   * If the form is valid, the signer information is set using the `setSignerInfo` method of the `_dfService` service.
   * If the email control is not null or empty, the contact email is set using the `setContactEmail` method of the `_dfService` service.
   */
  saveData() {
    let isAccepted = this.termFormGroup.value.certControl ? this.termFormGroup.value.certControl : false;
    let signerName = this.termFormGroup.value.signatureControl ? this.termFormGroup.value.signatureControl : "";

    if (this.termFormGroup.valid) {
      this._dfService.setSignerInfo(isAccepted, signerName);
      if (this.termFormGroup.value.emailControl != null && this.termFormGroup.value.emailControl != "") {
        this._dfService.setContactEmail(this.termFormGroup.value.emailControl as string);
      }
    }
  }
}
