import { Component, OnInit } from "@angular/core";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { PurchaseDevice } from "src/app/models/purchase-device/purchase-device.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { CSVService } from "src/app/services/csvService.service";
import { Common } from "src/app/utils/common";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { LineOfBusiness, FraudType, NavigationEnum, eProductType } from "../../globalvar";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SalesforceInfoService } from "src/app/services/sfInfo.service";
import Swal from "sweetalert2";
import { SFDisputeDTO } from "src/app/models/sf-dispute.dto";
import { csvTransaction } from "src/app/models/csvTransaction.model";
import { csvMoneyCodeTransaction } from "src/app/models/csvMoneyCodeTransaction.model";
import { Transaction } from "src/app/models/purchase-device/transaction.model";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.IMPACTED_PAYMENTS;

/**
 * Represents the ImpactedPaymentsComponent class.
 * This component is responsible for managing the impacted payments page of the dispute form module.
 */
@Component({
  selector: "app-impacted-payments",
  templateUrl: "./impacted-payments.component.html",
  styleUrls: ["./impacted-payments.component.scss"],
})
export class ImpactedPaymentsComponent implements OnInit {
  gDisputeForm: DisputeForm = new DisputeForm();
  canContinue = false;
  errors: boolean[] = [];
  navInfo: NavigationInfo = new NavigationInfo();
  obs!: Subscription;
  stepCondition: string = "";
  nextStepName: string = ""; //need to do something better to account for this screen being used in multiple paths
  MAX_PURCHASE_DEVICES = 25;
  sfInfo!: SFDisputeDTO;
  requiredFieldMissing:boolean = false;
  purchaseDeviceDuplicate:boolean = false;
  bUploadModal = false;
  public arrayCSVNAFOTR: Array<csvTransaction> = [
    { transaction_date: 'MM-DD-YYYY', transaction_time: '', card_number: '', trans_ID: '', cardholder_first_name: '', cardholder_last_name: '', custom_vehicle_asset_id: '', amount: 0, merchant_contact_date: 'MM-DD-YYYY', merchant: ''},
  ];
  public arrayCSVMoneyCode: Array<csvMoneyCodeTransaction> = [
    { transaction_date: 'MM-DD-YYYY', transaction_time: '', money_code: '', cardholder_first_name: '', cardholder_last_name: '', custom_vehicle_asset_id: '', amount: 0, merchant_contact_date: 'MM-DD-YYYY', merchant: ''},
  ];
  csvErrors:Array<string> = [];
  public arrayCSV: Array<any> = [];
  public importedData:Array<any> = [];

  constructor(
    private _disputeFormService: DisputeFormService,
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _translate: TranslateService,
    private _sfInfoService: SalesforceInfoService,
    public _csvService: CSVService
  ) {
    this.obs = this._disputeFormService.sfDisputeForm$.subscribe((df) => {
      this.canProceed();
    });
  }

  /**
   * Initializes the component.
   *
   * This method is called when the component is being initialized.
   * It sets up the necessary data and performs any required operations.
   *
   * @returns void
   */
  ngOnInit(): void {
    this.navInfo.showBack = true;
    this._navigationService.updateNavigationInfo(this.navInfo);
    this.gDisputeForm = Common.cloneObject(this._disputeFormService.getDisputeForm());
    this.arrayCSV = this.gDisputeForm.productType === eProductType.MONEYCODE ? this.arrayCSVMoneyCode : this.arrayCSVNAFOTR;
    //this screen is used in different BUs and paths...d
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
      this.stepCondition = this.gDisputeForm.productType == eProductType.MONEYCODE ? "MONEYCODE" : this.stepCondition;
      this.nextStepName = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness, currentStep, this.stepCondition) as string;
      
    } else if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
      } else {
        this.stepCondition = this.gDisputeForm.fraudType as string;
      }
      this.nextStepName = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    }

    if(this.gDisputeForm.purchaseDevices.length == 0){
      this.newPrePopPurchaseDevice();
      }
    this.updateProgressBar(this.stepCondition);
    this.canProceed();
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * Unsubscribes from any active subscriptions.
   */
  ngOnDestroy(): void {
    this.obs.unsubscribe();
  }
  /**
   * Updates the progress bar with the provided condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.ImpactedPayments",
        nextStepName: this.nextStepName,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }

  /**
   * Deletes a purchase device from the list of impacted payments.
   *
   * @param guid - The unique identifier of the purchase device to be deleted.
   */
  deletePurchaseDevice(guid: any) {
    this.gDisputeForm.purchaseDevices = this.gDisputeForm.purchaseDevices.filter((p) => p.id != guid);
    this.updateState();
  }

  /**
   * Adds a new purchase device to the list of purchase devices.
   *
   * @remarks
   * This method limits the number of purchase devices to 25. If the limit is not reached, a new purchase device is created and added to the list. The purchase device is initialized with the line of business, fraud type, and purchase device type from the current dispute form. After adding the purchase device, the state is updated.
   *
   * If the limit is reached, an error toast is displayed.
   */
  newPurchaseDevice(): void {
    //limit to 25 pds
    if (this.gDisputeForm.purchaseDevices.length < this.MAX_PURCHASE_DEVICES) {
      let purchaseDevice = new PurchaseDevice();
      purchaseDevice.lineOfBusiness = this.gDisputeForm.lineOfBusiness as LineOfBusiness;
      purchaseDevice.fraudType = this.gDisputeForm.fraudType as FraudType;
      purchaseDevice.purchaseDeviceType = this.gDisputeForm.productType as eProductType;
      this.gDisputeForm.purchaseDevices.push(purchaseDevice);
      this.updateState();
    } else {
      Toast.fire({
        icon: "error",
        title: this._translate.instant("ImpactedPayments.MaxPurchaseDevices"),
      });
    }
  }

  /**
   * Adds a new pre-populated purchase device to the list of purchase devices.
   *
   * If the number of purchase devices is less than the maximum allowed, a new purchase device is created and added to the list.
   * The purchase device is pre-populated with information retrieved from the SFInfo service.
   *
   * @remarks
   * The purchase device is only added if there are transactions associated with the purchase device in the SFInfo service.
   * The purchase device is considered valid if both the cardholder's first name and last name are not empty or null.
   *
   * @throws {Error} If the number of purchase devices exceeds the maximum allowed.
   */
  newPrePopPurchaseDevice(): void {
    if (this.gDisputeForm.purchaseDevices.length < this.MAX_PURCHASE_DEVICES) {
      this.sfInfo = this._sfInfoService.getSFInfo();
      if(this.sfInfo.purchaseDevice?.transactions.length > 0){
        let purchaseDevice = new PurchaseDevice();

        purchaseDevice.cardNumber = this.sfInfo.purchaseDevice.cardNumber;
        purchaseDevice.cardholderFirst = this.sfInfo.purchaseDevice.cardholderFirstName;
        purchaseDevice.cardholderLast = this.sfInfo.purchaseDevice.cardholderLastName;
        purchaseDevice.vehicleAssetUnitDriverId = this.sfInfo.purchaseDevice.assetId;
        purchaseDevice.isPrePop = true;
        if((purchaseDevice.cardholderFirst != '' && purchaseDevice.cardholderFirst != null)
        && (purchaseDevice.cardholderLast != ''  && purchaseDevice.cardholderLast != null))
        {
          purchaseDevice.isValid = true;
        }

        purchaseDevice.lineOfBusiness = this.gDisputeForm.lineOfBusiness as LineOfBusiness;
        purchaseDevice.fraudType = this.gDisputeForm.fraudType as FraudType;
        purchaseDevice.purchaseDeviceType = this.gDisputeForm.productType as eProductType;
        this.gDisputeForm.isPrePop = true;
        this.gDisputeForm.purchaseDevices.push(purchaseDevice);
        this.updateState();
      }
    } else {
      Toast.fire({
        icon: "error",
        title: this._translate.instant("ImpactedPayments.MaxPurchaseDevices"),
      });
    }
  }

  /**
   * Updates the state of the component.
   * Sets the purchase devices and isPrePop properties in the dispute form service.
   * Calls the canProceed method.
   */
  updateState(): void {
    this._disputeFormService.setPurchaseDevices(this.gDisputeForm.purchaseDevices);
    this._disputeFormService.setIsPrePop(this.gDisputeForm.isPrePop);
    this.canProceed();
  }

  /**
   * Checks if the user can proceed with the current operation.
   *
   * @remarks
   * This method performs several checks to determine if the user can proceed. It checks if there is at least one purchase device, if each purchase device has at least one transaction, if there are any invalid purchase devices or transactions, and if there are any duplicate card numbers.
   *
   * @returns A boolean value indicating whether the user can proceed.
   */
  canProceed() {
    this.canContinue = false;
    this.purchaseDeviceDuplicate = false;
    var cardNumbersSet = new Set<string>;
    //ensure at least 1 purchase device
    if (this.gDisputeForm.purchaseDevices != null && this.gDisputeForm.purchaseDevices.length > 0) {
      //for each pd, ensure there is at least 1 transaction
      var invalidPDs = this.gDisputeForm.purchaseDevices.filter(
        (pd) => pd.isValid == false || pd.transactions.length < 1
      );

      var erroMsg = this.gDisputeForm.purchaseDevices.filter(
        (pd) => pd.requiredFieldMissing != undefined
      );
      erroMsg.forEach((pd) => {
        this.requiredFieldMissing = pd.requiredFieldMissing;
      })

      this.gDisputeForm.purchaseDevices.forEach(pd => {
        if(!cardNumbersSet.has(pd.cardNumber)){
            cardNumbersSet.add(pd.cardNumber);
        } else{
          invalidPDs.push(pd);
          this.purchaseDeviceDuplicate = true;
        }
      });

      if (invalidPDs.length < 1) {
        var invalidTransactions = this.gDisputeForm.purchaseDevices.filter(
          (pd) => !!pd.transactions.find((t) => t.isValid == false)
        );

        if (invalidTransactions.length < 1) {
          this.canContinue = true;
        }
      }
    }
    this.updateNavigation();
  }

  /**
   * Updates the navigation information based on the current state of the dispute form.
   * Determines the route option based on whether the card is in possession or not, and the product type.
   * Sets the next route based on the line of business and the route option.
   * Updates the navigation information with the required field missing, purchase device duplicate, can continue, show back, and next route values.
   * Calls the _navigationService to update the navigation information.
   */
  updateNavigation() {
    var routeOption = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
    routeOption = this.gDisputeForm.productType == eProductType.MONEYCODE ? "MONEYCODE" : routeOption;
    var nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, NavigationEnum.IMPACTED_PAYMENTS, routeOption) as string;
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, NavigationEnum.IMPACTED_PAYMENTS) as string;
    }
    this.navInfo.requiredFieldMissing = this.requiredFieldMissing;
    this.navInfo.purchaseDeviceDuplicate = this.purchaseDeviceDuplicate;
    this.navInfo.canContinue = this.canContinue;
    this.navInfo.showBack = true;
    this.navInfo.nextRoute = nextRoute;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }

  /**
   * function to export a model CSV
   * @param name file name to be dowloaded
   * @param data data to be exported
   */
  exportCSV(name: string, data: Array<any>) {
    const csvContent = this._csvService.saveDataInCSV(data);

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = name + '.csv';
    hiddenElement.click();
  }
  /**
   * function to import data from CSV and populate purchase devices
   * @param event 
   */
  public async importDataFromCSV(event: any) {
    const fileContent = await this.getTextFromFile(event);
    this.importedData = this._csvService.importDataFromCSV(fileContent);
    const purchaseDeviceMap = new Map<string, PurchaseDevice>();
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let isCSVValid = true;
    let row = 1;
    this.csvErrors = [];
    for (const a of this.importedData) {
      let rowSize = Object.keys(a).length;
      let emptyCollums = 0;
      for(const key in a){
        if(a[key] === null || a[key] === undefined || a[key].toString().trim() === ''){
          emptyCollums++;
        }
      }
      if(emptyCollums !== rowSize && rowSize !== 0){
        const transactionInstance = new Transaction();
        transactionInstance.merchant = a.merchant;
        if(!isNaN(Date.parse(a.merchant_contact_date))) {
          let month, day, year;
          if(a.merchant_contact_date.includes('/')) {
            [month, day, year] = a.merchant_contact_date.split('/')
          } else if (a.merchant_contact_date.includes('-')) {
            [month, day, year] = a.merchant_contact_date.split('-');
          }
          else {
            isCSVValid = false;
            this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.MerchantDate") + row);
          }
          transactionInstance.merchantContactDate = new Date(Number(year), Number(month) - 1, Number(day)); // date
        } else {
          isCSVValid = false;
          this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.MerchantDate") + row);
        }
        if(!isNaN(Date.parse(a.transaction_date))) {          
          let month, day, year;
          if(a.transaction_date.includes('/')) {
            [month, day, year] = a.transaction_date.split('/')
          } else if (a.transaction_date.includes('-')) {
            [month, day, year] = a.transaction_date.split('-');
          }
          else {
            isCSVValid = false;
            this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.TransactionDate") + row);
          }
          transactionInstance.transactionDate = new Date(Number(year), Number(month) - 1, Number(day)); // date
        } else {
          isCSVValid = false;
          this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.TransactionDate") + row);
        }
        if(!isNaN(Number(a.amount)) && Number(a.amount) > 0){
          transactionInstance.transactionAmount = Number(a.amount); //number
        } else {
          isCSVValid = false;
          this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.Amount") + row);
        }
        if(this.gDisputeForm.lineOfBusiness === LineOfBusiness.NAF){
          if(a.trans_ID.length <= 15 && a.trans_ID.length >= 11) {
            transactionInstance.transactionId = a.trans_ID; //number 11-15
          } else {
            isCSVValid = false;
            this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.TransactionID") + row);
          }
        }
        else{
          if(this.gDisputeForm.productType !== eProductType.MONEYCODE){
            transactionInstance.transactionId = a.trans_ID;
          }
        }
        let cardNumber;
        if(this.gDisputeForm.productType === eProductType.MONEYCODE){
          cardNumber = a.money_code;
        } else {
          cardNumber = a.card_number;
        }
        if (!purchaseDeviceMap.has(cardNumber)) {
          const pu = new PurchaseDevice();
          if(!isNaN(Number(cardNumber)) && 
            ((this.gDisputeForm.lineOfBusiness === LineOfBusiness.NAF && cardNumber.length === 5) ||
            (this.gDisputeForm.lineOfBusiness === LineOfBusiness.OTR && cardNumber.length === 7) ||
            (this.gDisputeForm.productType === eProductType.MONEYCODE && cardNumber.length >= 4 && cardNumber.length <= 15))) {
            pu.cardNumber = cardNumber;
          } else {
            if(this.gDisputeForm.lineOfBusiness === LineOfBusiness.NAF) {
              isCSVValid = false;
              this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.CardNumberNAF") + row);
            }
            if(this.gDisputeForm.lineOfBusiness === LineOfBusiness.OTR) {
              isCSVValid = false;
              if(this.gDisputeForm.productType === eProductType.MONEYCODE) {
                this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.CardNumberMoneyCode") + row);
              } else {
                this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.CardNumberOTR") + row);              
              }
            }
          }

          if(format.test(a.cardholder_first_name) || format.test(a.cardholder_last_name)
          && a.cardholder_first_name.length >= 2 && a.cardholder_first_name.length <= 30
          && a.cardholder_last_name.length >= 2 && a.cardholder_last_name.length <= 30)
          {
            isCSVValid = false;
            this.csvErrors.push(this._translate.instant("ImpactedPayments.CSV.CardholderName") + row);
          }
          else
          {
            pu.cardholderFirst = a.cardholder_first_name; //only letters between 2-30
            pu.cardholderLast = a.cardholder_last_name; //only letters between 2-30
          }

          pu.vehicleAssetUnitDriverId = a.custom_vehicle_asset_id;
          pu.transactions = [];
          purchaseDeviceMap.set(cardNumber, pu);
        }
    
        purchaseDeviceMap.get(cardNumber)?.transactions.push(transactionInstance);
      }
      row++;
    }
    if(isCSVValid){
      const arrayOfPurchaseDevices = Array.from(purchaseDeviceMap.values());
      for(const p of arrayOfPurchaseDevices){
        if (this.gDisputeForm.purchaseDevices.length < this.MAX_PURCHASE_DEVICES) {
          this.sfInfo = this._sfInfoService.getSFInfo();
          if(p.transactions.length > 0){
            if((p.cardholderFirst !== '' && p.cardholderFirst !== null)
            && (p.cardholderLast !== ''  && p.cardholderLast !== null))
            {
              p.isValid = true;
            }
            p.lineOfBusiness = this.gDisputeForm.lineOfBusiness as LineOfBusiness;
            p.fraudType = this.gDisputeForm.fraudType as FraudType;
            p.purchaseDeviceType = this.gDisputeForm.productType as eProductType;
            this.gDisputeForm.isPrePop = false;
            this.gDisputeForm.purchaseDevices.push(p);
            this.updateState();
            this.bUploadModal = false;
          }
        } else {
          Toast.fire({
            icon: "error",
            title: this._translate.instant("ImpactedPayments.MaxPurchaseDevices"),
          });
        }
      }
    }
  }

  /**
   * Function to get the text from the file
   * @param event 
   * @returns 
   */
  private async getTextFromFile(event:any){
    const file: Blob = event.target.files[0];
    const fileContent = await file.text();

    return fileContent;
  }
  /**
   * function to show the upload modal
   */
  public showUploadModal()
  {
    this.bUploadModal = true;
  }
  
  /**
   * function to close the upload modal
   */
  public closeUploadModal()
  {
    this.bUploadModal = false;
  }
}
