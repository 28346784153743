import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ProgressBarService } from "../../../services/progress-bar.service";

/**
 * Represents a progress bar component.
 */
@Component({
  selector: "wex-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class WProgressBarComponent implements OnInit {
  isVisible: boolean = false;
  stepName: string = "";
  nextStepName: string = "";
  totalSteps: number = 10;
  currentStep: number = 1;

  progress: number = 0;
  private stepNameText: string = "";
  private nextStepNameText: string = "";

  /**
   * Constructs a new instance of the ProgressBarComponent.
   * @param _progressBarService - The ProgressBarService used to update the progress bar.
   * @param _translate - The TranslateService used for translation.
   */
  constructor(private _progressBarService: ProgressBarService, private _translate: TranslateService) {
    this._progressBarService.updateProgressEvent.subscribe({
      //this._progressBarService.updateProgressEventListener().subscribe({
      next: (info) => {
        if (Object.keys(info).length) {
          this.stepNameText = info.stepName;
          this.nextStepNameText = info.nextStepName;

          this.isVisible = info.isVisible;
          this.stepName = this._translate.instant(info.stepName);
          this.nextStepName = this._translate.instant(info.nextStepName);
          this.currentStep = info.currentStep;
          this.progress = (this.currentStep / info.totalSteps) * 100;
        }
      },
    });

    this._translate.onLangChange.subscribe((_) => {
      this.stepName = this._translate.instant(this.stepNameText);
      this.nextStepName = this._translate.instant(this.nextStepNameText);
    });
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {}
}
