<h1>{{ "CardLocation.Header" | translate }}</h1>
<p>{{ "CardLocation.Detail" | translate }}</p>
<form [formGroup]="cardLocationForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ "CardLocation.CardLocation" | translate }}</mat-label>
    <textarea
      formControlName="cardLocation"
      matInput
      #autosize="cdkTextareaAutosize"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      maxlength="7500"
    ></textarea>
    <mat-error *ngIf="cardLocationForm.get('cardLocation')?.hasError('required')">
      {{ "Error.Required" | translate }}</mat-error
    >
  </mat-form-field>
</form>
