import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { CaptchaService } from "./captcha.service";
import { DisputeFormService } from "./dispute-form.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(
    private _apiService: ApiService,
    private _captchaService: CaptchaService,
    private _df: DisputeFormService
  ) {}

  deactivateSession() {
    this._captchaService.setValid(false);
    this._apiService.setJWTToken("");
    this._df.clearDisputeForm();
  }
}
