type ReviewMapObj = {
  formKey: string;
};

export class ReviewSubmitMap {
  dataMap = new Map<string, ReviewMapObj>([
    [
      "FraudType",
      {
        formKey: "fraudType",
      },
    ],
    [
      "Possession",
      {
        formKey: "cardInPossession",
      },
    ],
    [
      "PhysicalPossession",
      {
        formKey: "cardInPossession",
      },
    ],
    [
      "MoneyCodeAuth",
      {
        formKey: "mcIsAuthorizedUser",
      },
    ],
    [
      "ActivityKnowledge",
      {
        formKey: "activityKnowledge",
      },
    ],
    [
      "LastLegitTransaction",
      {
        formKey: "lastTransactionDate",
      },
    ],
    [
      "CardLocation",
      {
        formKey: "locationCardWasKept",
      },
    ],
    [
      "ImpactedPayments",
      {
        formKey: "purchaseDevices",
      },
    ],
    [
      "AdditionalNotes",
      {
        formKey: "additionalInformation",
      },
    ],
    [
      "Suspects",
      {
        formKey: "suspects",
      },
    ],
    [
      "PoliceReport",
      {
        formKey: "lawEnforcementDetails",
      },
    ],
    [
      "EmployeeStatus",
      {
        formKey: "employeeStatus",
      },
    ],
    [
      "ProductSelction",
      {
        formKey: "productSelection",
      },
    ],
    [
      "CurrentLocation",
      {
        formKey: "currentLocation",
      },
    ],
  ]);

  getData(key: string) {
    return this.dataMap.get(key)?.formKey || "";
  }
}
