import { NgModule } from "@angular/core";
import { Base64Pipe } from "../pipes/convert-base64-img.pipe";
import { CardNumberMask } from "../pipes/cardNumberMask";
import { BackButtonDirective } from "./back-button.directive";
import { NavigationService } from "../services/navigation.service";
import { TranslateModule } from "@ngx-translate/core";
import { ToastService } from "../services/toast.service";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
  declarations: [Base64Pipe, CardNumberMask, BackButtonDirective],
  imports: [TranslateModule, NgxMaskModule.forChild()],
  exports: [Base64Pipe, CardNumberMask, TranslateModule, BackButtonDirective, NgxMaskModule],
  providers: [NavigationService, ToastService],
})
export class SharedModule {}
