import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TimeoutDialogMessage } from "./model/timeout-message";
import { TranslateService } from "@ngx-translate/core";

/**
 * Represents the MatTimeoutAlertComponent class..
 */
@Component({
  selector: "app-mat-alert",
  templateUrl: "./mat-timeout-alert.component.html",
  styleUrls: ["./mat-timeout-alert.component.scss"],
})
export class MatTimeoutAlertComponent {
  message: string = "";
  title: string = this._translateService.instant("TimeoutDialog.Title");
  timeout: string = "";
  messageFirstPart = this._translateService.instant("TimeoutDialog.BodyFirstPart", {countdown: this.timeout});
  messageSecondPart = this._translateService.instant("TimeoutDialog.BodySecondPart");

  /**
   * Constructs a new instance of the MatTimeoutAlertComponent.
   *
   * @param data - The data object containing the timeout dialog message.
   * @param _translateService - The TranslateService instance for translating messages.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) data: TimeoutDialogMessage,
    private _translateService: TranslateService
  ) {
    this.message = data.message ? data.message : "";
    this.title = data.title ? data.title : this.title
    this.timeout = data.timeout ? data.timeout : this.timeout;
  }
}
