import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
//Service to save and import data in CSV format
export class CSVService {
    /**
     * function to save data in CSV format
     * @param data data to be saved in CSV format
     * @returns a csv file as string
     */
    public saveDataInCSV(data: Array<any>): string {
      if (data.length === 0) {
        return '';
      }
  
      const propertyNames = Object.keys(data[0]);
      const rowWithPropertyNames = propertyNames.join(',') + '\n';
  
      let csvContent = rowWithPropertyNames;
  
      const rows: string[] = [];
  
      data.forEach((item) => {
        const values: string[] = [];
  
        propertyNames.forEach((key) => {
          let val: any = item[key];
  
          if (val !== undefined && val !== null) {
            val = String(val);
          } else {
            val = '';
          }
          values.push(val);
        });
        rows.push(values.join(','));
      });
      csvContent += rows.join('\n');
  
      return csvContent;
    }
  
    /**
     * function to import data from CSV
     * @param csvText CSV text to be imported
     * @returns CSV as an object
     */
    public importDataFromCSV(csvText: string): Array<any> {
      const propertyNames = csvText.slice(0, csvText.indexOf('\n')).split(',');
      const dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');
  
      const dataArray: any[] = [];
      dataRows.forEach((row) => {
        const values = row.split(',');
  
        const obj: any = new Object();
  
        for (let index = 0; index < propertyNames.length; index++) {
          let propertyName: string = propertyNames[index];
  
          let val: any = values[index];
          if (val === '') {
            val = null;
          }
          
          propertyName = propertyName.trim();
          obj[propertyName] = val;
        }
  
        dataArray.push(obj);
      });
  
      return dataArray;
    }
  }