import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { LOCATION_INITIALIZED } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { environment } from "src/environments/environment";
import { DisputeFormModule } from "./dispute-form-module/dispute-form.module";
import { SharedModule } from "./utils/shared.module";
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";
import { AppMaterialModule } from "./app-material/app-material.module";
import { NotfoundComponent } from "./app-components/notfound/notfound.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconsModule } from "./icons/icons.module";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CaptchaModule } from "./captcha-module/captcha.module";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
  declarations: [AppComponent, NotfoundComponent],
  imports: [
    BrowserModule,
    DisputeFormModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppMaterialModule,
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
    FontAwesomeModule,
    IconsModule,
    CaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation (ahead of time)
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = "en-US";
        translate.setDefaultLang("en-US");
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}
