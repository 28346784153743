<h1 class="title">{{ "TermConditions.Header" | translate }}</h1>
<h3>
  {{ "TermConditions.Detail" | translate }}
</h3>
<div>
  <p class="">
    {{ "TermConditions.MoreDetail" | translate }}
  </p>
</div>
<div>
  <div [formGroup]="termFormGroup">
    <div class="checkbox-row">
      <mat-checkbox id="certControl" [formControlName]="'certControl'" #certify>{{
        "TermConditions.CertifyStatement" | translate
      }}</mat-checkbox>
    </div>
    <div class="signature-row">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>{{ "TermConditions.Signature" | translate }}</mat-label>
        <input matInput formControlName="signatureControl" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="checkError('signatureControl', 'required')">
          {{ "Error.Required" | translate }}
        </mat-error>
        <mat-error *ngIf="checkError('signatureControl', 'maxlength') || checkError('signatureControl', 'minlength')">
          {{ "Error.MustBe4-60Chars" | translate }}
        </mat-error>
        <mat-error *ngIf="checkError('signatureControl', 'pattern')">
          {{ "Error.CannotContainNumbersOrChars" | translate }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="showEmail">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{ "TermConditions.EmailAddress" | translate }}</mat-label>
          <input
            matInput
            formControlName="emailControl"
            pattern="[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
          />
          <mat-error *ngIf="checkError('emailControl', 'required')">
            {{ "Error.Required" | translate }}
          </mat-error>
          <mat-error *ngIf="checkError('emailControl', 'pattern')">
            {{ "Error.MustBeValidEmail" | translate }}
          </mat-error>
          <mat-error *ngIf="checkError('emailControl', 'emailMismatch')">
            {{ "Error.EmailMismatch" | translate }}
          </mat-error>
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{ "TermConditions.ConfirmEmailAddress" | translate }}</mat-label>
          <input
            matInput
            formControlName="confirmEmailControl"
            pattern="[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
          />
          <mat-error *ngIf="checkError('confirmEmailControl', 'required')">
            {{ "Error.Required" | translate }}
          </mat-error>
          <mat-error *ngIf="checkError('confirmEmailControl', 'pattern')">
            {{ "Error.MustBeValidEmail" | translate }}
          </mat-error>
          <mat-error *ngIf="checkError('confirmEmailControl', 'emailMismatch')">
            {{ "Error.EmailMismatch" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div id="date-today">
        <div class="sm-label">{{ "TermConditions.TodaysDate" | translate }}</div>
        <div class="date-label">{{ today | date }}</div>
      </div>
    </div>
  </div>
</div>
