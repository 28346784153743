<section class="card-wrapper">
  <header>
    <h1 class="confirmation-header">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="64" rx="32" fill="#E8F5F4" />
        <path
          d="M12 32C12 20.9844 20.9062 12 32 12C43.0156 12 52 20.9844 52 32C52 43.0938 43.0156 52 32 52C20.9062 52 12 43.0938 12 32ZM40.9844 28.5625C41.8438 27.7031 41.8438 26.375 40.9844 25.5156C40.125 24.6562 38.7969 24.6562 37.9375 25.5156L29.5 33.9531L25.9844 30.5156C25.125 29.6562 23.7969 29.6562 22.9375 30.5156C22.0781 31.375 22.0781 32.7031 22.9375 33.5625L27.9375 38.5625C28.7969 39.4219 30.125 39.4219 30.9844 38.5625L40.9844 28.5625Z"
          fill="#00A593"
        />
      </svg>
      <span>{{ "Confirmation.Header" | translate }}</span>
    </h1>
  </header>
  <p>
    {{ "Confirmation.ThankYou" | translate }} <br />
    {{ "Confirmation.ReferenceNumber" | translate }}
    <strong>{{ refNumber }}</strong>
  </p>
  <section class="card-body-wrapper">
    <h3>{{ "Confirmation.WhatHappensNext" | translate }}</h3>
    <div>{{ "Confirmation.WhileProcessing" | translate }}</div>
    <ul>
      <li>
        {{ "Confirmation.CompanyObligations" | translate }}
      </li>
      <li>
        {{ "Confirmation.ReccommendPoliceReport" | translate }}
      </li>
      <li>
        {{ "Confirmation.AuthControls" | translate }}
      </li>

      <li>
        {{ "Confirmation.Timeframe" | translate }}
      </li>
      <li>{{ "Confirmation.AdditionalInfo" | translate }}</li>
      <li>{{ "Confirmation.PDFDetails" | translate }}</li>
    </ul>
  </section>
</section>
