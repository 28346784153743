import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { NavigationInfo } from "./models/navigationInfo.model";

@Injectable()
export class NavigationService {
  private history: string[] = [];
  // private _isVisible$ = new BehaviorSubject<string>("");
  // isVisible$ = this._isVisible$.asObservable();
  // private _canContinue$ = new BehaviorSubject<boolean>(false);
  // canContinue$ = this._canContinue$.asObservable();

  // private _showBack$ = new BehaviorSubject<boolean>(false);
  // showBack$ = this._canContinue$.asObservable();
  private _updateNavigation$ = new BehaviorSubject<NavigationInfo>({} as NavigationInfo);
  navigationInfo = this._updateNavigation$.asObservable();

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  updateNavigationInfo(navInfo: NavigationInfo) {
    this._updateNavigation$.next(navInfo);
  }

  hideNavigation() {
    this._updateNavigation$.next({
      isVisible: false,
    } as NavigationInfo);
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/");
    }
  }

  next(route: string) {
    this.router.navigate([route]);
  }
}
