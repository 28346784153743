import { Component, Inject, OnInit, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { GenericErrorInput } from "./model";
@Component({
  selector: "app-generic-error",
  templateUrl: "./generic-error.component.html",
  styleUrls: ["./generic-error.component.scss"],
})
/**
 * Represents a generic error component.
 */
export class GenericErrorComponent {
  @Input() message?: string = "";
  @Input() title?: string = this._translateService.instant("Error.Error");

  /**
   * Constructs a new instance of the GenericErrorComponent.
   *
   * @param dialogRef - The MatDialogRef instance for the dialog.
   * @param _translateService - The TranslateService instance for translating strings.
   * @param data - The input data for the GenericErrorComponent.
   */
  constructor(
    private dialogRef: MatDialogRef<GenericErrorComponent>,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: GenericErrorInput
  ) {
    this.title = data.title ? this._translateService.instant(data.title) : this.title;
    this.message = data.message ? this._translateService.instant(data.message) : this.message;
  }

  /**
   * Closes the alert dialog.
   */
  closeAlert = () => {
    this.dialogRef.close();
  };
}
