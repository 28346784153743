import { Component } from "@angular/core";
import { SFDisputeDTO } from "./models/sf-dispute.dto";
import { ApiService } from "./services/api.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { datadogRum } from "@datadog/browser-rum";
import { environment } from "src/environments/environment";
import { SalesforceInfoService } from "./services/sfInfo.service";
import { DisputeFormService } from "./services/dispute-form.service";
import { Base64Pipe } from "./pipes/convert-base64-img.pipe";
import Swal from "sweetalert2";
import { TimeoutService } from "./services/timeout.service";
import { Subscription } from "rxjs";
import { AppService } from "./services/app.service";
import { LineOfBusiness } from "./dispute-form-module/globalvar";
import { Common } from "./utils/common";
import { Title } from "@angular/platform-browser";
import packageInfo from "../../package.json";

declare var wexmet: any;
declare var wexy: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  selectedLanguage: string | null;
  authKey: string | null;
  jwtToken = "";
  recordId: string | null;
  record = {} as SFDisputeDTO;
  showTimeoutModal: boolean = false;
  showLoad: boolean = false;
  retries: number = 0;
  timeoutSub!: Subscription;

  image = ""; // "../assets/img/WEX_red22.png"; //we aren't using a default image for now

  constructor(
    private _apiService: ApiService,
    private location: Location,
    private route: Router,
    private _translate: TranslateService,
    private _sfService: SalesforceInfoService,
    private _dfService: DisputeFormService,
    private _timeout: TimeoutService,
    private _route: Router,
    private _appService: AppService,
    private _titleService: Title
  ) {
    const searchParams = new URLSearchParams(this.location.path(true).split("?")[1]);

    this.recordId = searchParams.get("recordId") as string;
    this.authKey = searchParams.get("key") as string;
    this.selectedLanguage = "en-US";
    _translate.setDefaultLang("en-US");
    _translate.use(this.selectedLanguage);
    this._translate.onLangChange.subscribe((_) => {
      this._titleService.setTitle(this._translate.instant("Header.Title.Label"));
    });

    datadogRum.init({
      applicationId: "825741f6-0dea-43d9-8490-008c112afccf",
      clientToken: "pub248422c434ac30db542c8405c8c161d3",
      site: "datadoghq.com",
      service: "rum-dispute-form",
      env: environment.datadogEnvironmentName,
      version: packageInfo.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();

    /*basic validation for empty recordId and authKey.  need to also check validity of authKey and recordId*/
    if (!this.recordId || !this.authKey) {
      this.route.navigate(["/error"]);
    } else {
      this.getProfile();

      this._sfService.setSFRecordId(this.recordId);
      this._sfService.setSFKey(this.authKey);
      this._timeout.startIdleWatch();

      this.retries = parseInt(localStorage.getItem("retries") || "0");

      this.subscribeToNetworkError();

      this.shouldClearRetriesLocalStorage();

      this.initMaineNav();
    }
  }

  handleSwalOnClose(isConfirmed: boolean) {
    if (isConfirmed) {
      if (!localStorage.getItem("retriesTimeCreated")) {
        localStorage.setItem("retriesTimeCreated", new Date().toString());
      }

      const castRetries = `${this.retries + 1}`;

      if (parseInt(castRetries) > 3) {
        Swal.fire({
          title: this._translate.instant("Common.Error"),
          html: this._translate.instant("Error.RetriesExceeded"),
          showCloseButton: true,
        });
      } else {
        localStorage.setItem("retries", castRetries);
        this.route.navigate(["/"], {
          queryParams: {
            recordId: this.recordId,
            authKey: this.authKey,
          },
        });
      }
    }
  }

  subscribeToNetworkError() {
    this._apiService.siteNetworkError.subscribe((val) => {
      if (val) {
        this._apiService.dataDogLog("Unable to access site/network. User has been presented with option to retry", {
          renderedAt: new Date().toUTCString(),
        });
        Swal.fire({
          title: this._translate.instant("Common.Error"),
          html: this._translate.instant("Error.SiteNetworkError"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: this._translate.instant("Common.Retry"),
          denyButtonText: this._translate.instant("Common.No"),
        }).then((e) => this.handleSwalOnClose(e.isConfirmed));
      }
    });
  }

  shouldClearRetriesLocalStorage() {
    if (localStorage.getItem("retriesTimeCreated")) {
      const createdDate = new Date(localStorage.getItem("retriesTimeCreated")!).getTime();
      const currentDate = new Date().getTime();

      const diffMinutes = (currentDate - createdDate) / 1000 / 60;

      if (Math.floor(diffMinutes) >= 15) {
        localStorage.removeItem("retriesTimeCreated");
        localStorage.removeItem("retries");
      }
    }
  }

  ngAfterViewInit() {
    this._timeout.idleWatchInit();
    this.timeoutSub = this._timeout.hasTimedout$.subscribe((val) => {
      if (val) {
        this._appService.deactivateSession();
        this._route.navigate(["/error"], {
          state: {
            message: this._translate.instant("Timeout.TimedOut"),
          },
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.timeoutSub.unsubscribe();
  }

  async getProfile() {
    var profileSession = await Common.getUniqueHex(this.recordId as string);

    wexmet.profile(environment.tmx.domain, environment.tmx.orgId, profileSession);

    const retry = 2;

    let isStarted = false;
    for (let i = 0; i <= retry; i++) {
      const started = await this.checkProfile();
      if (started) {
        isStarted = true;
        break;
      }
    }
    if (!isStarted) {
      datadogRum.addError("Unable to start profiling");
      await this._dfService.setProfilingInfo(profileSession, false);
    } else {
      datadogRum.addAction("Profiling Session Started: " + profileSession);
      await this._dfService.setProfilingInfo(profileSession, true);
    }
  }

  checkProfile() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        wexy.check_profile_tags_loaded() ? resolve(true) : resolve(false);
      }, 1000);
    });
  }

  initMaineNav() {
    this.showLoad = true;
    if (this.recordId && this.authKey) {
      this._apiService.getAuthToken(this.recordId, this.authKey).subscribe({
        next: async (token: string) => {
          if (token) {
            this._apiService.getRecordById(this.recordId as string).subscribe({
              next: (info) => {
                this.determineLanguage(info);

                this._sfService.setSFInfo(info);

                if (!this._sfService.getValid()) {
                  datadogRum.addError("SFInfo received invalid data or is missing required fields", info);
                  this.route.navigate(["/error"], {
                    state: {
                      message: this._translate.instant("Error.RetrievingDataFromSF"),
                    },
                  });
                }

                this._dfService.setSFInfo(info);

                this.selectedLanguage = info.accountLanguage;
                this._translate.use(this.selectedLanguage);

                if (info.logo) {
                  this.image = Base64Pipe.prototype.transform(info.logo, "image/png");
                }
              },
              error: (err) => {
                //datadog log error
                this.route.navigate(["/error"], {
                  state: {
                    message: err.errorMessage,
                  },
                });
              },
            });
            this.route.navigate(["/captcha"]);
          } else {
            this.route.navigate(["/error"]);
          }
        },
        error: (error) => {
          this.route.navigate(["/error"], {
            state: {
              message: error.message,
            },
          });
        },
        complete: () => {
          this.showLoad = false;
        },
      });
    } else if (this._apiService.getJWTToken().length > 0) {
      this.showLoad = false;
    } else {
      this.showLoad = false;
      this.route.navigate(["/error"], {
        state: {
          message: this._translate.instant("Error.RetrievingDataFromSF"),
        },
      });
    }
  }

  //sf & rp aren't translating the account languages for now, SO we're going to do that ourselves
  private determineLanguage(info: SFDisputeDTO) {
    if (info.accountLanguage === "ENG" || info.accountLanguage === "en-US") {
      info.accountLanguage = "en-US";
    } else if (info.accountLanguage === "ENU" || info.accountLanguage === "en-CA") {
      info.accountLanguage = "en-CA";
    } else if (info.accountLanguage === "FRA" || info.accountLanguage === "fr-CA") {
      info.accountLanguage = "fr-CA";
    } else if (info.accountLanguage === "ESP" || info.accountLanguage === "es-MX") {
      info.accountLanguage = "es-MX";
    } else {
      info.accountLanguage = "en-US";
    }
  }

  updateLanguage(event: any) {
    if (this.selectedLanguage) {
      this._dfService.setLanguage(this.selectedLanguage);
      this._translate.use(this.selectedLanguage);
    }
  }

  title = this._translate.get("Header.Title.Label");
}
