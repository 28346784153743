import { Component, OnInit } from "@angular/core";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { LineOfBusiness, FraudType, NavigationEnum, eProductType } from "../../globalvar";
import { DFUtils } from "../../../utils/dispute-form.utils";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Common } from "src/app/utils/common";
import { TranslateService } from "@ngx-translate/core";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.ADDITIONAL_INFO;

/**
 * Represents the AdditionalInfoComponent class.
 * This component is responsible for displaying and managing additional information related to a dispute form.
 */
@Component({
  selector: "app-additional-info",
  templateUrl: "./additional-info.component.html",
  styleUrls: ["./additional-info.component.scss"],
})
export class AdditionalInfoComponent implements OnInit {
  gDisputeForm: DisputeForm;
  addInfoForm: FormGroup;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition = "";

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _fb: FormBuilder,
    private _translate: TranslateService
  ) {
    this.gDisputeForm = this._dfService.getDisputeForm();
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
      this.stepCondition = this.gDisputeForm.productType == eProductType.MONEYCODE ? "MONEYCODE" : this.stepCondition;
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
      } else {
        this.stepCondition = this.gDisputeForm.fraudType as string;
      }
    }
    this.updateProgressBar(this.stepCondition, NavigationEnum.REVIEW_SUBMIT);
    this.addInfoForm = this._fb.group({
      additionalInfo: [
        this.gDisputeForm.additionalInformation,
        [Validators.required, Validators.minLength(5), Validators.maxLength(7500)],
      ],
    });
  }
  /**
   * Initializes the component and sets up form value change subscriptions.
   */
  ngOnInit(): void {
    this.addInfoForm.get("additionalInfo")?.valueChanges.subscribe(
      Common.debounce(() => {
        this._dfService.setAdditionalInfo(this.addInfoForm.value.additionalInfo);
      }, 500)
    );
    this.navInfo.nextRoute = DFUtils.getRoute(
      this.gDisputeForm.lineOfBusiness as string,
      NavigationEnum.ADDITIONAL_INFO
    ) as string;
    this.navInfo.canContinue = true;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }
  /**
   * Updates the progress bar with the current step information.
   *
   * @param condition - The condition to determine the current step.
   * @param nextPath - The next navigation path.
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.AdditionalNotes",
        nextStepName: DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Navigates to the next route based on the business unit.
  */
  navigateNext() {
    //dynamically grab the business unit to send: OTR/NAF
    //this._router.navigate([nextRoute]);
  }
}
