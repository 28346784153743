import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatAccordion } from "@angular/material/expansion";
import { Router } from "@angular/router";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { Suspect } from "src/app/models/purchase-device/suspect.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { Common } from "src/app/utils/common";
import { DateMatcher } from "src/app/utils/error-state";
import Swal from "sweetalert2";
import { LineOfBusiness, NavigationEnum, eEmploymentStatus, eProductType } from "../../globalvar";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { NavigationService } from "src/app/services/navigation.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { TranslateService } from "@ngx-translate/core";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.SUSPECTS;

/**
 * Component for managing suspects in the dispute form.
 * This component handles the creation, deletion, and validation of suspect information.
 */
@Component({
  selector: "app-suspects",
  templateUrl: "./suspects.component.html",
  styleUrls: ["./suspects.component.scss"],
})
export class SuspectsComponent implements OnInit {
  @ViewChild(MatAccordion) susAccordion: MatAccordion | undefined;

  DateMatcher = new DateMatcher();
  susForm!: FormGroup;
  yesSuspects: boolean | undefined;
  canContinue: boolean = false;
  selectedTab: number = -1;
  panelOpenState = false;
  suspects: Suspect[] = [];
  gDisputeForm!: DisputeForm;
  navInfo: NavigationInfo = new NavigationInfo();
  nextStep: string = "";
  stepCondition?: string | null;
  maxDate: Date = new Date();

  status_current = eEmploymentStatus.CURRENT_EMPLOYEE;
  status_former = eEmploymentStatus.FORMER_EMPLOYEE;
  status_other = eEmploymentStatus.OTHER;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _fb: FormBuilder,
    private _dfService: DisputeFormService,
    private _router: Router,
    private _translate: TranslateService
  ) {}

  /**
   * Initializes the component and sets up the necessary form controls and data.
   */
  ngOnInit(): void {
    this.susForm = this._fb.group({ suspects: this._fb.array([]) });
    this.gDisputeForm = this._dfService.getDisputeForm();
    this.stepCondition = null;

    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
      this.stepCondition = this.gDisputeForm.productType == eProductType.MONEYCODE ? "MONEYCODE" : this.stepCondition;
      this.nextStep = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep, this.stepCondition) as string;
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      this.stepCondition = this.gDisputeForm.fraudType;
      this.nextStep = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    }

    this.updateProgressBar(this.stepCondition, this.nextStep as NavigationEnum);

    this.yesSuspects = this.gDisputeForm.hasSuspects;
    this.suspects = this.gDisputeForm.suspects;
    this.suspects.forEach((sus) => {
      const newSusForm = this._fb.group(
        {
          id: new FormControl(sus.id),
          firstName: new FormControl(sus.firstName, Validators.required),
          lastName: new FormControl(sus.lastName, Validators.required),
          employmentStatus: new FormControl(sus.employmentStatus, Validators.required),
          employedFrom: new FormControl(sus?.employmentStartDate),
          employedTo: new FormControl(sus?.employmentEndDate),
          address: new FormControl(sus.address, Validators.required),
          additionalIdentifyingInformation: new FormControl(sus.additionalIdentifyingInformation),
        },
        { validators: this.validateDate() }
      );

      const validator = [Validators.required];
      if (newSusForm.get("employmentStatus")?.value === eEmploymentStatus.CURRENT_EMPLOYEE && sus.employmentStartDate) {
        newSusForm.controls.employedFrom?.addValidators(validator);
        // this.formSuspects.controls[controlIndex].get("employedTo")?.removeValidators(validators);
      } else if (newSusForm.get("employmentStatus")?.value === eEmploymentStatus.FORMER_EMPLOYEE && sus.employmentEndDate) {
        newSusForm.controls.employedFrom?.addValidators(validator);
        newSusForm.controls.employedTo?.addValidators(validator);
      }

      this.formSuspects.push(newSusForm);
    });

    for (let index = 0; index < this.formSuspects.length; index++) {
      this.onRelationShipChange(index);
    }

    if (this.suspects.length > 0) {
      this.yesSuspects = true;
      setTimeout(() => {
        this.susAccordion?.closeAll();
      }, 100);
      this.canContinue = this.susForm.valid;
    }
    this.susForm.statusChanges.subscribe({
      next: Common.debounce(() => {
        if (this.susForm.valid && this.yesSuspects && this.formSuspects.length > 0) {
          this.canContinue = true;
        } else if (!this.yesSuspects && this.formSuspects.length == 0) {
          this.canContinue = true;
        } else {
          this.canContinue = false;
        }
        this.updateNavigation();
      }, 500),
    });

    this.checkForNext();
  }
  /**
   * Lifecycle hook that is called after every check of a component's view.
   * It is called immediately after the `ngAfterViewInit` and `ngAfterContentChecked` lifecycle hooks.
   * Use this hook to detect and respond to changes that occur after the view has been checked.
   */
  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  /**
   * Returns the `FormArray` control for the suspects in the `susForm`.
   *
   * @returns The `FormArray` control for the suspects.
   */
  get formSuspects() {
    return this.susForm.get("suspects") as FormArray;
  }

  /**
   * Updates the progress bar with the specified condition and next path.
   *
   * @param condition - The condition for updating the progress bar.
   * @param nextPath - The next path to navigate to after updating the progress bar (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    let nextStep = "";
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      nextStep = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep, condition) as string;
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      nextStep = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    }
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.Suspects",
        nextStepName: nextStep,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Updates the navigation information for the suspects component.
   *
   * This method dynamically grabs the business unit to send (OTR/NAF) and updates the navigation information
   * by setting the next route and the ability to continue.
   */
  updateNavigation() {
    //dynamically grab the business unit to send: OTR/NAF
    this.navInfo.nextRoute = this.nextStep;
    this.navInfo.canContinue = this.canContinue;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }

  /**
   * Checks if the form has any suspects and updates the 'canContinue' flag accordingly.
   * If there are no suspects and 'yesSuspects' is false, sets 'canContinue' to true.
   * If there are no suspects and 'yesSuspects' is true, sets 'canContinue' to false.
   * Finally, calls the 'validateSuspectChanges' method.
   */
  checkForNext() {
    if (this.formSuspects.length == 0 && this.yesSuspects != undefined && !this.yesSuspects) {
      this.canContinue = true;
    } else if (this.formSuspects.length == 0 && this.yesSuspects) {
      this.canContinue = false;
    }
    this.validateSuspectChanges();
  }

  /**
   * Adds a new suspect to the dispute form.
   *
   * @remarks
   * This method checks if the number of suspects in the form is less than 10. If it is, a new suspect form group is created using the FormBuilder. The new suspect form group is then added to the formSuspects array. The selectedTab is updated to the index of the newly added suspect. Finally, the onRelationShipChange method is called with the selectedTab as the parameter.
   * If the number of suspects in the form is already 10, an error toast message is displayed.
   */
  addSuspect() {
    if (this.gDisputeForm.suspects.length < 10) {
      const susForm = this._fb.group(
        {
          id: new FormControl(crypto.randomUUID()),
          firstName: new FormControl("", Validators.required),
          lastName: new FormControl("", Validators.required),
          employmentStatus: new FormControl("", Validators.required),
          employedFrom: new FormControl(""),
          employedTo: new FormControl("", [Validators.required]),
          address: new FormControl("", Validators.required),
          additionalIdentifyingInformation: new FormControl(""),
        },
        { validators: this.validateDate() }
      );
      //this.susForm.get("suspects");
      this.formSuspects.push(susForm);
      this.selectedTab = this.formSuspects.length - 1;

      this.onRelationShipChange(this.selectedTab);
    } else {
      Toast.fire({
        icon: "error",
        title: this._translate.instant("Suspects.CannotAddMoreThan"),
      });
    }
  }

  /**
   * Deletes a suspect from the suspects list.
   *
   * @param sus - The suspect to be deleted.
   * @param index - The index of the suspect in the list.
   */
  deleteSuspect(sus: any, index: number) {
    Swal.fire({
      title: this._translate.instant("Suspects.RemoveAreYouSure"),
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: this._translate.instant("Common.Yes"),
      denyButtonText: this._translate.instant("Common.No"),
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        var s = sus as FormGroup;
        //remove from local array
        this.suspects.splice(
          this.suspects.findIndex((x) => {
            x.id == s.get("id")?.value;
          })
        );
        //remove from local
        this.formSuspects.removeAt(index);
        //remove from service
        this._dfService.setSuspects(this.suspects);
      } else if (result.isDenied) {
        Toast.fire({
          icon: "info",
          title: this._translate.instant("Common.NotRemoved"),
        });
        return;
      }
    });
  }

  /**
   * Validates and handles changes made to the suspects in the form.
   * If there are suspects in the form and the "yesSuspects" flag is not set,
   * a confirmation dialog is shown to the user. If the user confirms,
   * the suspects are cleared and the navigation is updated. If the user denies,
   * a toast message is shown and the "yesSuspects" flag is set to true.
   * If there are no suspects in the form or the "yesSuspects" flag is already set,
   * the navigation is updated. Finally, the "yesSuspects" flag is stored in the service.
   */
  validateSuspectChanges() {
    if (this.formSuspects.length > 0 && !this.yesSuspects) {
      Swal.fire({
        title: this._translate.instant("Suspects.ChangeAnswer"),
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: this._translate.instant("Common.Yes"),
        denyButtonText: this._translate.instant("Common.No"),
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.formSuspects.clear();
          this.suspects = [];
          this._dfService.setSuspects(this.suspects);

          this.updateNavigation();
        } else if (result.isDenied) {
          Toast.fire({
            icon: "info",
            title: this._translate.instant("Suspects.NotRemoved"),
          });
          this.yesSuspects = true;
          return;
        }
      });
    } else {
      this.updateNavigation();
    }

    if (this.yesSuspects != undefined) {
      this._dfService.setHasSuspects(this.yesSuspects);
    }
  }

  //custom validator for date fields
  /**
   * Validates the date in a form control.
   *
   * @returns A validation function that checks if the date is valid.
   */
  validateDate() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value.employedTo &&
        (isNaN(control.value.employedTo) ||
          Common.formatDate(control.value.employedTo) < Common.formatDate(control.value.employedFrom))
      ) {
        //set custom date error on employedTo control
        control.get("employedTo")?.setErrors({ ...control.get("employedTo")?.errors, invalidDate: true });
      } else {
        const employedToErrors = control.get("employedTo")?.errors;
        if (employedToErrors && employedToErrors["invalidDate"]) {
          delete employedToErrors["invalidDate"];
          control.get("employedTo")?.updateValueAndValidity();
        }
      }
      return null;
    };
  }

  /**
   * Stops the propagation of the given event.
   *
   * @param e - The event to stop propagation for.
   */
  stopProp(e: Event) {
    e.stopPropagation();
  }

  /**
   * Saves a suspect.
   *
   * @param suspect - The suspect object to be saved.
   */
  private saveSuspect(suspect: any) {
    const newSus: Suspect = {
      id: suspect.id?.value,
      //suspectType: suspect.get('id')?.value, //picklist in UI - used to determine SF record type
      firstName: suspect.firstName?.value,
      lastName: suspect.lastName?.value,
      address: suspect.address?.value,
      employmentStartDate: suspect.employedFrom?.value,
      employmentEndDate: suspect.employedTo?.value,
      additionalIdentifyingInformation: suspect.additionalIdentifyingInformation?.value,
      employmentStatus: suspect.employmentStatus.value,
    };

    if (this.suspects.findIndex((s) => s.id == newSus.id) > -1) {
      this.suspects[this.suspects.findIndex((s) => s.id == newSus.id)] = newSus;
      this.suspects = Object.assign([], this.suspects);
    } else {
      this.suspects.push(newSus);
    }

    this._dfService.setSuspects(this.suspects);
  }

  /**
   * Handles the change event of the relationship control.
   *
   * @param controlIndex - The index of the control within the formSuspects array.
   */
  private onRelationShipChange(controlIndex: number): void {
    if (this.formSuspects.controls.length == 0) return;
    //this will grab each suspects form within the main formgroup and subscribe to the status change
    this.formSuspects.controls[controlIndex].statusChanges.subscribe(
      Common.debounce((status: any) => {
        if (status == "VALID") {
          console.log("save");
          var ctrls = (this.formSuspects.controls[controlIndex] as FormArray).controls;
          //call the service to update the form
          this.saveSuspect(ctrls);
        }
      }, 500)
    );

    this.formSuspects.controls[controlIndex].get("employmentStatus")?.valueChanges.subscribe((employmentStatus) => {
      const validators = [Validators.required];
      if (employmentStatus === eEmploymentStatus.CURRENT_EMPLOYEE) {
        this.formSuspects.controls[controlIndex].get("employedFrom")?.addValidators(validators);
        this.formSuspects.controls[controlIndex].get("employedTo")?.removeValidators(validators);
      } else if (employmentStatus == eEmploymentStatus.FORMER_EMPLOYEE) {
        this.formSuspects.controls[controlIndex].get("employedFrom")?.addValidators(validators);
        this.formSuspects.controls[controlIndex].get("employedTo")?.addValidators(validators);
      } else {
        this.formSuspects.controls[controlIndex].get("employedFrom")?.removeValidators(validators);
        this.formSuspects.controls[controlIndex].get("employedTo")?.removeValidators(validators);
      }

      this.formSuspects.controls[controlIndex].get("employedFrom")?.updateValueAndValidity();
      this.formSuspects.controls[controlIndex].get("employedTo")?.updateValueAndValidity();
    });
  }
}
