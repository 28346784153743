import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { Common } from "src/app/utils/common";
import { NavigationService } from "src/app/services/navigation.service";

/**
 * Represents the DisputeFormComponent class.
 * This component is responsible for displaying and managing the dispute form.
 */
@Component({
  selector: "app-dispute-form",
  templateUrl: "./dispute-form.component.html",
  styleUrls: ["./dispute-form.component.scss"],
})
export class DisputeFormComponent implements OnInit {
  disputeForm!: DisputeForm;
  progressVisible: boolean = false;
  navigationVisible: boolean = true;

  constructor(
    private _progressBarService: ProgressBarService,
    private _disputeFormService: DisputeFormService,
    private _navigationService: NavigationService,
    private _cdr: ChangeDetectorRef
  ) {
    this._progressBarService.updateProgressEvent.subscribe({
      next: (info) => {
        if (Object.keys(info).length) {
          this.progressVisible = info.isVisible;
        }
      },
    });

    this._navigationService.navigationInfo.subscribe({
      next: (info) => {
        if (Object.keys(info).length) {
          this.navigationVisible = info.isVisible;
        }
      },
    });
  }

  /**
   * Initializes the component.
   * Retrieves the dispute form data from the dispute form service and assigns it to the `disputeForm` property.
   */
  ngOnInit(): void {
    this.disputeForm = Common.cloneObject(this._disputeFormService.getDisputeForm());
  }
  /**
   * Lifecycle hook that is called after every check of a component's view.
   * It is called immediately after the `ngAfterViewInit` and `ngAfterContentChecked` lifecycle hooks.
   * Use this hook to detect and respond to changes that occur after the view has been checked.
   */
  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }
}
