import { FraudType, LineOfBusiness, eProductType } from "../../dispute-form-module/globalvar";

export class Transaction {
  id: string = crypto.randomUUID();
  merchant!: string;
  merchantContactDate!: Date;
  transactionDate!: Date;
  transactionId!: string;
  transactionAmount!: number;
  isValid: boolean = false;
  expand: boolean = false;

  //used by impacted payments component (and children) for conditional field labels
  lineOfBusiness!: LineOfBusiness;
  fraudType?: FraudType;
  productType!: eProductType;

  isPrePop: boolean = false;

  constructor() {}
}
