import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DocumentInterruptSource, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { BehaviorSubject} from "rxjs";
import { MatTimeoutAlertComponent } from "../app-material/dialog-components/mat-alert/timeout-alert/mat-timeout-alert.component";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class TimeoutService {
  showTimeoutModal: boolean = false;
  timeoutCountdown: string = "";
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = undefined;
  private dialogRef: MatDialogRef<MatTimeoutAlertComponent> | undefined;

  private _hasTimedout$ = new BehaviorSubject<boolean>(false);
  hasTimedout$ = this._hasTimedout$.asObservable();

  constructor(
    private _idle: Idle,
    private keepalive: Keepalive,
    private _dialog: MatDialog,
    private _translate: TranslateService
  ) {}

  idleWatchInit() {
    this._idle.watch();
  }

  idleWatchStop() {
    this._idle.stop();
  }

  startIdleWatch() {
    // sets an idle timeout of 15 minutes, 900 seconds
    this._idle.setIdle(900);
    // sets a timeout period of 5 minutes (300 seconds).
    this._idle.setTimeout(300);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this._idle.setInterrupts(this.createCustomInterruptSources());

    this._idle.onIdleEnd.subscribe(() => {
      this.showTimeoutModal = false;
      this.idleState = "No longer idle.";
      this.reset(this.idleState);
    });

    this._idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.showTimeoutModal = false;
      this._hasTimedout$.next(true);
      this.dialogRef?.close();
    });

    this._idle.onIdleStart.subscribe(() => {
      this.alertTimeoutDialog();
    });

    this._idle.onTimeoutWarning.subscribe((countdown) => {
      if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.componentInstance.timeout = this.secondsToMs(countdown);
      }
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
    });
  }

  reset(idleState?: string) {
    this._idle.watch();
    this.idleState = idleState ? idleState : "Started.";
    this.timedOut = false;
    this.showTimeoutModal = false;
    this._hasTimedout$.next(false);
  }
  secondsToMs(seconds: number) {
    const duration = DateTime.fromSeconds(seconds);
    const minutesSeconds = duration.toFormat("mm:ss");

    return minutesSeconds;
  }
  alertTimeoutDialog() {
    this.dialogRef?.close();
    this.dialogRef = this._dialog.open(MatTimeoutAlertComponent, {
      data: {
        message: this._translate.instant("Timeout.Body"),
        timeout: this.timeoutCountdown,
      },
    });
  }

  createCustomInterruptSources() {
    return [new DocumentInterruptSource("keydown mousedown touchstart touchmove scroll")];
  }
}
