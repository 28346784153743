<div *ngIf="!fromReview" [className]="navInfo.showBack === true ? 'button-row' : 'button-row noback'">
  <button class="back-btn" *ngIf="navInfo.showBack" backButton>
    <mat-icon>arrow_back</mat-icon> {{ navInfo.backButtonText }}
  </button>
  <div class="errorMessage" *ngIf="navInfo.requiredFieldMissing">
    <h3>{{ "Error.RequiredFieldMissing" | translate }}</h3>
  </div>
  <div class="errorMessage" *ngIf="navInfo.purchaseDeviceDuplicate">
    <h3>{{ "Error.purchaseDeviceDuplicate" | translate }}</h3>
  </div>
  <button class="next-btn" [disabled]="!navInfo.canContinue" (click)="navigateNext()" *ngIf="navInfo.showNext">
    {{ navInfo.nextButtonText }} <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
<div *ngIf="fromReview">
  <div class="button-row rev-container">
    <button (click)="navigateReview()" [disabled]="!navInfo.canContinue" class="next-btn">
      {{ "Common.BackToReview" | translate }} <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>
