<div class="card">
  <div class="content">
    <h1 class="title">{{ "Welcome.Header" | translate }}</h1>
    <h3>{{ "Welcome.Detail" | translate }}</h3>
    <div>
      <p class="note">
        <i class="pi pi-info-circle"></i><strong>{{ "Common.Note" | translate }}</strong>
        {{ "Welcome.Note" | translate }}
      </p>
    </div>
    <div class="button-row">
      <!-- <p-captcha #captchaEl siteKey="{{siteKey}}" (onResponse)="captchaResponse($event)" (onExpire)="expireCaptcha()"></p-captcha> -->
      <re-captcha
        id="recaptcha"
        name="recaptcha"
        #captchaEl="ngModel"
        [(ngModel)]="token"
        required
        [class.is-invalid]="captchaEl.invalid && (captchaEl.dirty || captchaEl.touched)"
        (resolved)="captchaResponse($event)"
      ></re-captcha>
      <!-- <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
          <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
        </div> -->
      <button class="dispute-transaction-btn" [disabled]="token == undefined ? true : null" (click)="startDispute()">
        {{ "Welcome.ButtonLabel" | translate }}<mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</div>
