import { Component, OnInit } from "@angular/core";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { LineOfBusiness, FraudType, NavigationEnum } from "../../globalvar";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { NavigationService } from "src/app/services/navigation.service";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { Subject, fromEvent, takeUntil } from "rxjs";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.PHYSICAL_POSSESSION;

/**
 * Represents the PhysicalPossessionComponent class.
 * This component is responsible for handling the physical possession step in the dispute form module.
 */
@Component({
  selector: "app-physical-possession",
  templateUrl: "./physical-possession.component.html",
  styleUrls: ["./physical-possession.component.scss"],
})
export class PhysicalPossessionComponent implements OnInit {
  gDisputeForm: DisputeForm = {} as DisputeForm;
  hasPhysicalPossession: boolean | undefined;
  canContinue = false;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService
  ) {}

  /**
   * Initializes the component.
   * - Retrieves the value from the disputeForm service.
   * - Checks if the line of business is OTR and performs necessary actions.
   * - Sets the value and calculates 'canContinue' based on the cardInPossession property.
   * - Calls the getRouteInfo and canProceed methods.
   */
  ngOnInit(): void {
    //get the value from the disputeForm service
    this.gDisputeForm = this._dfService.getDisputeForm();
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      history.pushState(null, "");
      fromEvent(window, "popstate")
        .pipe(takeUntil(this.unsubscriber))
        .subscribe((_) => {
          history.pushState(null, "");
          //this.showError = true;
        });
    }
    //set value and calculate 'canContinue'
    if (this.gDisputeForm.physicalCardDetails.cardInPossession !== undefined) {
      this.hasPhysicalPossession = this.gDisputeForm.physicalCardDetails.cardInPossession === true;
      this.canContinue = true;
    }

    this.getRouteInfo();
    this.canProceed();
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It is used to perform any necessary cleanup logic, such as unsubscribing from observables.
   */
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  /**
   * Retrieves the route information based on the current state of the dispute form.
   * @returns {void}
   */
  getRouteInfo() {
    var nextRoute = null;
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR && this.hasPhysicalPossession)
      nextRoute = DFUtils.getRoute(
        this.gDisputeForm.lineOfBusiness as string,
        currentStep,
        this.hasPhysicalPossession
      ) as NavigationEnum;
    else if (
      this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF &&
      this.gDisputeForm.fraudType == FraudType.COMPROMISED &&
      this.hasPhysicalPossession != undefined
    )
      nextRoute = DFUtils.getRoute(
        this.gDisputeForm.lineOfBusiness as string,
        currentStep,
        this.hasPhysicalPossession
      ) as NavigationEnum;
    else {
      nextRoute = null; // DFUtils.getRoute(currentStep) as NavigationEnum;
    }

    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.stepCondition =
        this.hasPhysicalPossession === true
          ? "IN_POSSESSION"
          : this.hasPhysicalPossession === false
          ? "NO_POSSESSION"
          : "INITIAL_PHYSICAL";
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        this.stepCondition =
          this.hasPhysicalPossession === true
            ? "IN_PHYSICAL_POSSESSION"
            : this.hasPhysicalPossession === false
            ? "NO_PHYSICAL_POSSESSION"
            : "INITIAL_PHYSICAL";
      } else {
        this.stepCondition = this.gDisputeForm.fraudType as string;
      }
    }

    this.updateProgressBar(this.stepCondition, nextRoute);
  }
  /**
   * Updates the progress bar based on the given condition and next path.
   *
   * @param condition - The condition to determine the next step.
   * @param nextPath - The next path to navigate to (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    var nextStepName = "";
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      nextStepName =
        condition != "INITIAL_PHYSICAL"
          ? (DFUtils.getNextRouteText(
              this.gDisputeForm.lineOfBusiness as string,
              currentStep,
              this.hasPhysicalPossession as boolean
            ) as string)
          : "PageName.SelectOption";
    } else if (
      this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF &&
      this.gDisputeForm.fraudType == FraudType.DRIVER_MISUSE
    ) {
      nextStepName = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    } else if (
      this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF &&
      this.gDisputeForm.fraudType == FraudType.COMPROMISED
    ) {
      nextStepName =
        condition != "INITIAL_PHYSICAL"
          ? (DFUtils.getNextRouteText(
              this.gDisputeForm.lineOfBusiness as string,
              currentStep,
              this.gDisputeForm.cardInPossession
            ) as string)
          : "PageName.SelectOption";
    }
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.Possession",
        nextStepName: nextStepName,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Determines if the form can proceed to the next step.
   *
   * @remarks
   * This method checks if `hasPhysicalPossession` is not null. If it is not null, it updates the `cardInPossession` property of `gDisputeForm` based on the value of `hasPhysicalPossession`. It also sets the `canContinue` flag to true. If `hasPhysicalPossession` is null, it sets the `canContinue` flag to false.
   *
   * The method then determines the next route based on the values of `lineOfBusiness`, `fraudType`, and `hasPhysicalPossession`. It updates the `showBack`, `nextRoute`, and `canContinue` properties of `navInfo` accordingly. It also updates the navigation information using the `_navigationService` and performs additional operations by calling `getRouteInfo()` and `updateValue()`.
   */
  canProceed() {
    if (this.hasPhysicalPossession != null) {
      this.gDisputeForm.cardInPossession = this.hasPhysicalPossession == true;
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }

    var nextRoute = "";

    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR)
      nextRoute = DFUtils.getRoute(
        this.gDisputeForm.lineOfBusiness as string,
        NavigationEnum.PHYSICAL_POSSESSION,
        this.hasPhysicalPossession as boolean
      ) as string;
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        nextRoute = DFUtils.getRoute(
          this.gDisputeForm.lineOfBusiness as string,
          NavigationEnum.PHYSICAL_POSSESSION,
          this.gDisputeForm.cardInPossession
        ) as string;
      } else if (this.gDisputeForm.fraudType == FraudType.DRIVER_MISUSE) {
        nextRoute = DFUtils.getRoute(
          this.gDisputeForm.lineOfBusiness as string,
          NavigationEnum.PHYSICAL_POSSESSION
        ) as string;
      }
    }

    this.navInfo.showBack = this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR ? false : true;
    this.navInfo.nextRoute = nextRoute;
    this.navInfo.canContinue = this.canContinue;
    this._navigationService.updateNavigationInfo(this.navInfo);
    this.getRouteInfo();
    this.updateValue();
  }

  /**
   * Updates the value of the physicalCardDetails.cardInPossession property in the gDisputeForm object
   * based on the value of the hasPhysicalPossession property.
   *
   * @remarks
   * This method is called to update the value of the physicalCardDetails.cardInPossession property
   * in the gDisputeForm object. If the hasPhysicalPossession property is defined, the value of
   * physicalCardDetails.cardInPossession is set to the value of hasPhysicalPossession. After updating
   * the value, the updated gDisputeForm object is passed to the _dfService.setDisputeForm method.
   */
  private updateValue() {
    if (this.hasPhysicalPossession !== undefined) {
      this.gDisputeForm.physicalCardDetails.cardInPossession = this.hasPhysicalPossession
      this._dfService.setDisputeForm(this.gDisputeForm);
    }
  }
}
