<app-base-alert-dialog>
  <ng-container id="header">
    <mat-icon class="warning-icon">warning</mat-icon>
    {{ title }}
  </ng-container>
  <ng-container id="content" *ngIf="message !== ''">
    <div class="content">
      {{ messageFirstPart }} <span class="countdown">{{ timeout }}</span> {{ messageSecondPart }}
    </div>
  </ng-container>
  <ng-container *ngIf="message">{{ message }}</ng-container>
</app-base-alert-dialog>
