<svg width="239" height="228" viewBox="0 0 239 228" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_1050_45100)">
    <path
      d="M171.072 59.4652C171.072 78.7237 155.81 94.649 136.825 95.76C136.081 95.76 135.336 95.76 134.592 95.76C128.264 95.76 121.936 93.9083 116.724 90.9454C105.557 84.6494 98.112 72.798 98.112 59.0949C98.112 49.836 101.462 41.3178 107.418 35.0218C114.119 27.6147 123.797 22.8 134.592 22.8C148.737 22.8 161.021 30.9479 166.977 42.7992C169.583 47.9842 171.072 53.5395 171.072 59.4652Z"
      fill="#A0DCF8"
    />
  </g>
  <path
    opacity="0.1"
    d="M170.16 59.0648C170.16 78.5357 155.089 94.6367 136.342 95.76C129.357 94.6367 122.741 92.7645 116.492 91.2667C105.464 84.9012 98.112 72.9191 98.112 59.0648C98.112 49.7038 101.42 41.0917 107.302 34.7262C119.432 34.3518 133.401 35.4751 147.737 38.0962C154.354 39.2195 160.603 40.7173 166.484 42.215C168.69 47.4572 170.16 53.0738 170.16 59.0648Z"
    fill="#A0DCF8"
  />
  <g filter="url(#filter1_dd_1050_45100)">
    <path
      d="M216.193 84.2731C219.015 68.5434 184.224 49.246 138.487 41.1713C92.7488 33.0966 53.3836 39.3022 50.562 55.0319C47.7403 70.7617 82.5306 90.059 128.268 98.1338C174.006 106.209 213.371 100.003 216.193 84.2731Z"
      fill="#57B5E6"
    />
  </g>
  <path
    d="M126.828 50.1527C127.036 47.4978 122.866 45.0109 117.513 44.5981C112.161 44.1852 107.653 46.0028 107.445 48.6577C107.237 51.3126 111.408 53.7995 116.76 54.2124C122.112 54.6252 126.62 52.8077 126.828 50.1527Z"
    fill="white"
  />
  <path
    d="M79.7857 56.9715C84.9319 55.4557 88.4844 52.1577 87.7205 49.605C86.9565 47.0523 82.1654 46.2117 77.0192 47.7275C71.873 49.2432 68.3205 52.5413 69.0844 55.0939C69.8483 57.6466 74.6394 58.4872 79.7857 56.9715Z"
    fill="white"
  />
  <path
    d="M164.636 59.9109C165.107 57.2893 161.203 54.4076 155.918 53.4745C150.633 52.5415 145.967 53.9103 145.497 56.5319C145.027 59.1536 148.93 62.0352 154.215 62.9683C159.5 63.9014 164.166 62.5325 164.636 59.9109Z"
    fill="white"
  />
  <path
    d="M193.018 79.304C194.249 76.9372 191.378 73.0399 186.606 70.5991C181.835 68.1583 176.969 68.0983 175.739 70.465C174.509 72.8318 177.38 76.7292 182.151 79.17C186.923 81.6108 191.788 81.6708 193.018 79.304Z"
    fill="white"
  />
  <path
    d="M128.927 72.421L94.0182 72.0481L44.304 165.712L183.84 180.576L168.292 85.1013L128.927 72.421Z"
    fill="url(#paint0_linear_1050_45100)"
  />
  <path
    d="M238.75 213.417H0.0625C6.51351 207.372 15.9419 201.327 26.859 196.792C32.8138 194.525 39.761 191.503 46.7083 189.991C67.55 183.946 92.8579 180.167 119.654 180.167C151.909 180.167 181.683 185.457 204.51 193.77C212.45 196.792 219.397 199.815 225.352 203.594C230.81 206.616 235.276 209.639 238.75 213.417Z"
    fill="#A0DDF8"
  />
  <path
    d="M60.2857 190.394C60.3045 192.619 56.2109 194.88 51.7246 194.918C47.2384 194.957 43.4842 193.134 43.0916 190.912C47.5684 189.761 52.7898 188.233 58.0175 187.446C59.5192 188.175 60.2763 189.281 60.2857 190.394Z"
    fill="#BEE7FA"
  />
  <path
    d="M94.1177 185.866C99.8741 185.222 104.427 183.685 104.287 182.433C104.147 181.182 99.3668 180.69 93.6104 181.334C87.854 181.979 83.3011 183.515 83.4411 184.767C83.5812 186.018 88.3613 186.51 94.1177 185.866Z"
    fill="#BEE7FA"
  />
  <path
    d="M150.139 184.921C150.262 183.081 145.518 181.272 139.543 180.879C133.568 180.486 128.625 181.659 128.502 183.498C128.379 185.338 133.123 187.147 139.098 187.54C145.073 187.933 150.016 186.76 150.139 184.921Z"
    fill="#BEE7FA"
  />
  <path
    d="M197.284 191.327C195.792 191.425 194.047 191.802 191.784 191.579C185.791 191.601 180.819 190.069 180.697 188.218C180.673 187.848 180.673 187.848 181.022 187.454C187.088 188.542 192.625 189.655 197.284 191.327Z"
    fill="#BEE7FA"
  />
  <path
    d="M168.122 86.5778C169.388 79.5195 153.606 70.8303 132.872 67.1697C112.137 63.5092 94.3021 66.2635 93.0359 73.3218C91.7698 80.38 107.552 89.0693 128.286 92.7298C149.021 96.3904 166.856 93.636 168.122 86.5778Z"
    fill="#A3E4D7"
  />
  <path
    d="M33.9445 121.296C33.9445 121.296 21.0764 124.621 24.9759 127.28C28.8753 129.807 38.1039 134.196 46.8126 131.27C55.5213 128.344 46.8126 124.222 46.9426 124.222C47.0726 124.222 33.9445 121.296 33.9445 121.296Z"
    fill="#00C7B1"
  />
  <path
    d="M17.856 120.158C22.5463 121.625 37.008 119.224 46.2583 113.088C53.5543 122.292 56.2903 124.693 58.896 124.96C54.8572 129.095 41.4377 135.764 33.8812 127.094C25.0217 132.83 17.856 120.158 17.856 120.158Z"
    fill="#99E9E0"
  />
  <path
    d="M172.896 132.882C177.863 133.152 191.325 126.937 198.644 118.56C208.054 125.721 211.191 127.342 213.936 126.937C210.93 132.071 201.781 143.285 188.841 144.096C179.431 138.421 172.896 132.882 172.896 132.882Z"
    fill="#A0DCF8"
  />
  <path
    d="M130.24 131.685C129.259 126.816 120.069 115.299 110.374 110.247C114.767 99.4122 115.511 95.986 114.448 93.4293C120.022 95.0946 132.839 101.23 136.818 113.549C133.823 124.024 130.24 131.685 130.24 131.685Z"
    fill="#00C7B1"
  />
  <path
    d="M77.4141 176.231V186.793H71.0261V196.827H58.7825V186.793H32.1661V174.647L56.6532 140.848H71.0261V176.231H77.4141ZM59.3149 154.579L43.8773 176.231H59.3149V154.579Z"
    fill="#515F6B"
  />
  <path
    d="M115.743 197.883C102.967 197.883 91.7877 186.793 91.7877 168.838C91.7877 150.883 102.967 139.792 115.743 139.792C128.518 139.792 139.697 150.883 139.697 168.838C140.23 186.265 129.051 197.883 115.743 197.883ZM115.743 186.793C121.598 186.793 127.986 180.984 127.986 168.31C127.986 156.164 122.13 149.826 115.743 149.826C109.887 149.826 103.499 155.635 103.499 168.31C104.031 180.984 109.887 186.793 115.743 186.793Z"
    fill="#515F6B"
  />
  <path
    d="M200.382 176.231V186.793H193.994V196.827H181.751V186.793H155.134V174.647L179.622 140.848H193.462V176.231H200.382ZM181.751 154.579L166.313 176.231H181.751V154.579Z"
    fill="#515F6B"
  />
  <path
    d="M65.2028 141.348V176.231V176.731H65.7028H72.123V186.293H66.2351H65.7351V186.793V196.327H54.4915V186.793V186.293H53.9915H27.8751V174.809L52.1174 141.348H65.2028ZM55.0239 154.579V153.016L54.1167 154.289L38.6792 175.941L38.1157 176.731H39.0863H54.5239H55.0239V176.231V154.579Z"
    fill="#D1D6D8"
    stroke="#A5AEB4"
  />
  <path
    d="M134.405 168.838V168.846L134.406 168.853C134.931 186.055 123.923 197.383 110.951 197.383C98.5113 197.383 87.4958 186.582 87.4958 168.838C87.4958 151.094 98.5113 140.292 110.951 140.292C123.39 140.292 134.405 151.094 134.405 168.838ZM98.2071 168.31V168.32L98.2075 168.331C98.7441 181.107 104.688 187.293 110.951 187.293C117.198 187.293 123.694 181.129 123.694 168.31C123.694 156.045 117.749 149.326 110.951 149.326C104.704 149.326 98.2071 155.491 98.2071 168.31Z"
    fill="#D1D6D8"
    stroke="#A5AEB4"
  />
  <path
    d="M188.17 141.348V176.231V176.731H188.67H195.091V186.293H189.203H188.703V186.793V196.327H177.459V186.793V186.293H176.959H150.843V174.809L175.085 141.348H188.17ZM177.459 154.579V153.016L176.552 154.289L161.114 175.941L160.551 176.731H161.522H176.959H177.459V176.231V154.579Z"
    fill="#D1D6D8"
    stroke="#A5AEB4"
  />
  <defs>
    <filter
      id="filter0_dd_1050_45100"
      x="92.112"
      y="18.8"
      width="84.96"
      height="84.96"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="0.5" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.0509804 0 0 0 0 0.141176 0 0 0 0.3 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1050_45100" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="3" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.0509804 0 0 0 0 0.141176 0 0 0 0.2 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_1050_45100" result="effect2_dropShadow_1050_45100" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1050_45100" result="shape" />
    </filter>
    <filter
      id="filter1_dd_1050_45100"
      x="44.4017"
      y="33.6311"
      width="177.951"
      height="76.043"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="0.5" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.0509804 0 0 0 0 0.141176 0 0 0 0.3 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1050_45100" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="3" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.0509804 0 0 0 0 0.141176 0 0 0 0.2 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_1050_45100" result="effect2_dropShadow_1050_45100" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1050_45100" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_1050_45100"
      x1="142.711"
      y1="25.8094"
      x2="113.49"
      y2="165.468"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#A3E4D7" />
      <stop offset="1" stop-color="#A3E4D7" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>
