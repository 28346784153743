export const environment = {
  production: false,
  environmentName: "Development",
  datadogEnvironmentName: "Dev",
  serviceAPI: "https://d4d05gszcf.execute-api.us-east-1.amazonaws.com/api/fincrimes/dispute-form/",
  recaptcha: {
    siteKey: "6LeS81kpAAAAAD7h7cVnIjuHNjKabosIAwmYLKpZ",
  },
  tmx: {
    domain: "h.online-metrix.net",
    orgId: "bddye18t",
  },
};
