import { formatDate } from "@angular/common";
import { DateTime } from "luxon";

export class Common {
  static cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  static debounce = (fn: any, timeDelay = 1000) => {
    let fnTimer: any;
    return (...args: any) => {
      clearTimeout(fnTimer);
      fnTimer = setTimeout(() => fn(...args), timeDelay);
    };
  };

  //used by date control validators for easy compares
  static formatDate(dateControl: any) {
    return formatDate(
      DateTime.fromISO(dateControl).toISODate() == null
        ? dateControl.toDateString()
        : DateTime.fromISO(dateControl).toISODate(),
      "yyyy-MM-dd",
      "en" //hard coded for now - keep an eye out for problems
    );
  }

  //convert to MM/DD/YYYY format
  static dateToString(theDate: Date) {
    var date_String = "";
    if (theDate) {
      var dt = new Date(theDate);
      // get the year, month, date and append to the string.
      date_String = dt.getMonth() + 1 + "/" + +dt.getDate() + "/" + dt.getFullYear();
    }
    return date_String;
  }

  static async getUniqueHex(salt: string) {
    var hex = "";
    const buff = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(salt));
    const hA = Array.from(new Uint8Array(buff));
    hex = hA.map((b) => b.toString(16).padStart(2, "0")).join("");
    return hex;
  }
}
