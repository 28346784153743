import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { FraudType, NavigationEnum } from "../../globalvar";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { TranslateService } from "@ngx-translate/core";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.LAST_TRANSACTION;

/**
 * Represents the LastTransactionComponent class.
 * This component is responsible for handling the last transaction form page.
 */
@Component({
  selector: "app-last-transaction",
  templateUrl: "./last-transaction.component.html",
  styleUrls: ["./last-transaction.component.scss"],
})
export class LastTransactionComponent implements OnInit {
  lastTransactionForm: FormGroup;
  readyToNext: boolean = false;
  gDisputeForm: DisputeForm;
  today: Date = new Date();
  minDate: Date = new Date();
  fromReview: Boolean;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";

  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _fb: FormBuilder,
    private _dfService: DisputeFormService,
    public router: Router,
    private _translate: TranslateService
  ) {
    this.router.onSameUrlNavigation = "ignore";
    this.fromReview = history.state?.fromReview;
    this.gDisputeForm = this._dfService.getDisputeForm();
    this.lastTransactionForm = this._fb.group({
      lastTransactionDate: new FormControl(this.gDisputeForm.lastTransactionDate, Validators.required),
    });
    if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
      this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
    } else {
      this.stepCondition = this.gDisputeForm.fraudType as string;
    }
    this.updateProgressBar(this.stepCondition);
  }

  /**
   * Initializes the component.
   * - Sets the minimum date to 6 months ago.
   * - Subscribes to the statusChanges event of the lastTransactionForm and calls nextCheck and saveDate methods.
   * - Calls the nextCheck method.
   */
  ngOnInit(): void {
    this.minDate = new Date(this.minDate.setMonth(this.minDate.getMonth() - 6));

    this.lastTransactionForm.statusChanges.subscribe(() => {
      this.nextCheck();
      this.saveDate();
    });
    this.nextCheck();
  }

  /**
   * Updates the progress bar based on the given condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    const nextStepName = DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.LastLegitTransaction",
        nextStepName: nextStepName,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }

  /**
   * Checks if the last transaction form is valid and updates the navigation information.
   */
  nextCheck() {
    if (this.lastTransactionForm.valid) {
      this.navInfo.canContinue = true;
    } else {
      this.navInfo.canContinue = false;
    }

    this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }

  /**
   * Saves the date of the last transaction.
   *
   * @remarks
   * This method checks if the `lastTransactionForm` is valid and then sets the last transaction date using the `_dfService` service.
   */
  saveDate() {
    if (this.lastTransactionForm.valid) {
      this._dfService.setLastTransactionDate(this.lastTransactionForm.value.lastTransactionDate);
    }
  }
}
