import { Observable } from "rxjs";

export class NavigationInfo {
  isVisible: boolean;
  canContinue: boolean;
  showBack: boolean;
  showNext: boolean;
  nextRoute: string;
  nextButtonText: string;
  backButtonText: string;
  requiredFieldMissing: boolean;
  purchaseDeviceDuplicate: boolean;
  nextButtonAction!: () => Observable<any>;
  /**
   *
   */
  constructor() {
    this.isVisible = true;
    this.showBack = true;
    this.showNext = true;
    this.canContinue = false;
    this.nextRoute = "";
    this.nextButtonText = "Common.Next";
    this.backButtonText = "Common.Back";
    this.requiredFieldMissing = false;
    this.purchaseDeviceDuplicate = false;
    //this.nextButtonAction = null;
    //this.nextButtonAction = () => {};
  }
}
