import { Injectable } from "@angular/core";
import Swal, { SweetAlertOptions } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private defaultOptions: SweetAlertOptions = {
    toast: true,
    position: "bottom-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  };

  constructor() {}

  showToast(options?: SweetAlertOptions) {
    const mergedOptions: SweetAlertOptions = { ...this.defaultOptions, ...options };
    Swal.fire(mergedOptions);
  }
}
