import { Component, Input} from "@angular/core";
import { MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-base-alert-dialog",
  templateUrl: "./base-alert-dialog.component.html",
  styleUrls: ["./base-alert-dialog.component.scss"],
})

/**
 * Represents the base alert dialog component.
 */
export class BaseAlertDialogComponent {
  @Input() title: string = "";
  @Input() onClose: () => void = () => {this.dialogRef.close()};
  constructor(
    public dialogRef: MatDialogRef<BaseAlertDialogComponent>) {
  }
}
