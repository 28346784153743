<h1>{{ "MCIncidentOverview.Header" | translate }}</h1>
<p>{{ "MCIncidentOverview.Detail" | translate }}</p>
<form [formGroup]="incidentDetailForm">
  <mat-form-field appearance="outline" class="text-area">
    <mat-label>{{ "Common.DescribeWhatHappened" | translate }}</mat-label>
    <textarea
      formControlName="incidentDetail"
      matInput
      #autosize="cdkTextareaAutosize"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      maxlength="7500"
    ></textarea>
    <mat-error *ngIf="incidentDetailForm.get('incidentDetail')?.hasError('required')">
      {{ "Error.Required" | translate }}
    </mat-error>
    <mat-error
      *ngIf="
        incidentDetailForm.get('incidentDetail')?.hasError('minlength') ||
        incidentDetailForm.get('incidentDetail')?.hasError('maxlength')
      "
    >
      {{ "Error.MustBe1-7500Chars" | translate }}
    </mat-error>
  </mat-form-field>
</form>
