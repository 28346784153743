import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CaptchaComponent } from "./captcha-component/captcha.component";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { AppMaterialModule } from "../app-material/app-material.module";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { SharedModule } from "../utils/shared.module";

const routes: Routes = [{ path: "captcha", pathMatch: "full", component: CaptchaComponent }];

@NgModule({
  declarations: [CaptchaComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AppMaterialModule,
    SharedModule,
  ],
})
export class CaptchaModule {}
