<h1>{{ "MCCredentials.Header" | translate }}</h1>
<p>{{ "MCCredentials.Detail" | translate }}</p>
<mat-button-toggle-group aria-label="label" vertical [(ngModel)]="yesShared">
  <mat-button-toggle [value]="true" id="shareToggle" (click)="checkForNext()">
    <mat-icon>check</mat-icon>
    {{ "Common.Yes" | translate }}
  </mat-button-toggle>
  <mat-button-toggle [value]="false" id="noShareToggle" (click)="checkForNext()">
    <mat-icon>close</mat-icon>
    {{ "Common.No" | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
<div class="shared-detail" *ngIf="yesShared !== undefined">
  <p>{{ (yesShared === true ? "MCCredentials.AdditionalInfoLabel.Shared" : "MCCredentials.AdditionalInfoLabel.NotShared") | translate }}</p>
  <form [formGroup]="mcSharedForm" class="sharedForm">
    <mat-form-field appearance="outline" class="shared-detail">
      <textarea
        matInput
        name="questionText"
        cols="535"
        rows="8"
        formControlName="mcSharedDetail"
        maxlength="255"
        required
      ></textarea>
      <mat-error *ngIf="mcSharedForm.get('mcSharedDetail')?.hasError('required')">
        {{ "Error.Required" | translate }}</mat-error
      >
      <mat-error *ngIf="mcSharedForm.get('mcSharedDetail')?.hasError('maxlength')">
        {{ "Error.MustBe1-255Chars" | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
