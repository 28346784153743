import { PurchaseDevice } from "./purchase-device/purchase-device.model";
import { Suspect } from "./purchase-device/suspect.model";
import { Transaction } from "./purchase-device/transaction.model";
import { LawEnforcementDetails } from "./purchase-device/law-enforcement-details.model";
import { LineOfBusiness, FraudType, eProductType } from "../dispute-form-module/globalvar";
import { MoneyCodeAuthShared } from "./purchase-device/money-code-auth-shared.model";
import { PhysicalCardDetails } from "./physicalCardDetails.model";
import { EmployeeStatus } from "./purchase-device/employee-status.model";

export class DisputeForm {
  referenceId: string = "";

  accountName: string = "";
  accountNumber: string = "";
  contactFirstName: string = ""; // this passes through from salesforce -> angular -> RP -> salesforce
  contactLastName: string = ""; // this passes through from salesforce -> angular -> RP -> salesforce
  contactEmailAddress: string = "";
  lineOfBusiness?: LineOfBusiness;
  accountLanguage: string = "";
  accountCurrency: string = "";

  //some type of logo thingie for branding purposes
  //branded contact info

  signerName: string = "";
  acceptLegalDisclaimer: boolean = false;
  acceptLegalDisclaimerTimestamp: string = ""; //timestamp?

  captchaValidated: boolean = false;
  tmxValidated: boolean = false;
  tmxSessionId: string = "";
  tmxResult: string = "";

  fraudType: FraudType | undefined = undefined;

  productType: eProductType | undefined;

  additionalInformation: string = "";
  overview: string = "";
  mcIsAuthorizedUser: boolean | undefined;
  cardInPossession: boolean | undefined;

  purchaseDevices: PurchaseDevice[] = [];
  hasSuspects: boolean | undefined;
  suspects: Suspect[] = [];
  employeeStatus: EmployeeStatus = {} as EmployeeStatus;
  //employeeStatusAdditionalDetails: string = ""; //do we need a boolean for yes? assuming when this is populated the answer was no.
  lawEnforcementDetails: LawEnforcementDetails = {} as LawEnforcementDetails;
  moneyCodeAuthShared: MoneyCodeAuthShared = {} as MoneyCodeAuthShared;
  locationCardWasKept: string = "";

  lastTransactionDate!: Date;
  activityKnowledge: string = "";
  illegitimacyDetails: string = "";
  physicalCardDetails: PhysicalCardDetails = {} as PhysicalCardDetails;
  isPrePop: boolean = false;
  cardHolderNameAppliesChanged: boolean = false;
  requiredFieldMissing: boolean = false;
  constructor() {}

  generateTestData() {
    //todo: remove the automated values below
    // dummy test data for dummies :)
    // this.accountName = "Test Account";
    // this.accountNumber = "12345";
    // this.lineOfBusiness = LineOfBusiness.OTR;
    // this.accountLanguage = "en-US";
    // this.contactFirstName = "greatest"; // this passes through from salesforce -> angular -> RP -> salesforce
    // this.contactLastName = "ever"; // this passes through from salesforce -> angular -> RP -> salesforce
    // this.contactEmailAddress = "sara.schmidt@wexinc.com";

    // this.referenceId = "18850997";

    // let pd = new PurchaseDevice();
    // pd.lineOfBusiness = this.lineOfBusiness as LineOfBusiness;
    //pd.fraudType = this.fraudType;
    // pd.dateAbuseBegan = new Date("2023-08-01T15:36:16.810Z");
    // pd.dateAbuseEnded = new Date("2023-08-09T15:36:16.810Z");
    // pd.purchaseDeviceType = eProductType.FLEETCARD;
    // pd.cardholderFirst = "Billy";
    // pd.cardholderLast = "James";
    // pd.cardNumber = "01234";
    // pd.isValid = true;

    // let trx = new Transaction();
    // pd.lineOfBusiness = this.lineOfBusiness as LineOfBusiness;
    //pd.fraudType = this.fraudType;
    // trx.merchant = "merchant 1";
    // trx.transactionId = "trxid 1";
    // trx.transactionAmount = 100.0;
    // trx.transactionDate = new Date("2023-08-01T15:36:16.810Z");
    // trx.merchantContactDate = new Date("2023-08-04T15:36:16.810Z");
    // trx.isValid = true;

    // let trx2 = new Transaction();
    // pd.lineOfBusiness = this.lineOfBusiness as LineOfBusiness;
    // pd.fraudType = this.fraudType;
    // trx2.merchant = "merchant 2";
    // trx2.transactionId = "trxid 2";
    // trx2.transactionAmount = 200.0;
    // trx2.transactionDate = new Date("2023-08-01T15:36:16.810Z");
    // trx2.merchantContactDate = new Date("2023-08-04T15:36:16.810Z");
    // trx2.isValid = true;

    // pd.transactions.push(trx);
    // pd.transactions.push(trx2);

    // let pd2 = new PurchaseDevice();
    // no pd type variable rn
    // pd.lineOfBusiness = this.lineOfBusiness as LineOfBusiness;
    // pd.fraudType = this.fraudType;
    // pd2.dateAbuseBegan = new Date("2023-08-01T15:36:16.810Z");
    // pd2.dateAbuseEnded = new Date("2023-08-09T15:36:16.810Z");
    // pd2.purchaseDeviceType = eProductType.FLEETCARD;
    // pd2.cardholderFirst = "Billy";
    // pd2.cardholderLast = "James";
    // pd2.cardNumber = "11111";
    // pd2.isValid = true;

    // let trx3 = new Transaction();
    // pd.lineOfBusiness = this.lineOfBusiness as LineOfBusiness;
    //pd.fraudType = this.fraudType;
    // trx3.merchant = "Shell";
    // trx3.transactionId = "1234567";
    // trx3.transactionAmount = 10.99;
    // trx3.transactionDate = new Date("2023-08-02T15:36:16.810Z");
    // trx3.merchantContactDate = new Date("2023-08-04T15:36:16.810Z");
    // trx3.isValid = true;

    // let trx4 = new Transaction();
    // pd.lineOfBusiness = this.lineOfBusiness as LineOfBusiness;
    //pd.fraudType = this.fraudType;
    // trx4.merchant = "7/11";
    // trx4.transactionId = "2345678";
    // trx4.transactionAmount = 4.05;
    // trx4.transactionDate = new Date("2023-08-02T15:36:16.810Z");
    // trx4.merchantContactDate = new Date("2023-08-04T15:36:16.810Z");
    // trx4.isValid = true;

    // pd2.transactions.push(trx3);
    // pd2.transactions.push(trx4);

    // this.purchaseDevices.push(pd);
    // this.purchaseDevices.push(pd2);

    // let sus1 = new Suspect();
    // sus1.id = crypto.randomUUID();
    // sus1.firstName = "Test";
    // sus1.lastName = "Sus";
    // sus1.address = "1500 Pennsylvania Ave";
    // sus1.employmentStartDate = new Date();
    // sus1.additionalIdentifyingInformation = "other info";
    // sus1.employmentStatus = "Other";

    // let sus2 = new Suspect();
    // sus2.id = crypto.randomUUID();
    // sus2.firstName = "Test";
    // sus2.lastName = "Suspect";
    // sus2.address = "1500 Pennsylvania Ave";
    // sus2.employmentStartDate = new Date();
    // sus2.employmentEndDate = new Date();
    // sus2.additionalIdentifyingInformation = "other info same gue new name";
    // sus2.employmentStatus = "Other";

    // this.suspects.push(sus1);
    // this.suspects.push(sus2);

    // let lawReport = new LawEnforcementDetails();
    // lawReport.id = crypto.randomUUID();
    // lawReport.dateReported = new Date();
    // lawReport.officerFirstName = "Officer";
    // lawReport.officerLastName = "Law";
    // lawReport.phoneNumber = "1234567890";
    // lawReport.reportNumber = "TPS-1234";
    // lawReport.referenceId = "123134";
    // lawReport.emailAddress = "sara.schmidt@wexinc.com";
    // lawReport.isReported = true;

    // this.lawEnforcementDetails = lawReport;

    // let mcAuthShared = new MoneyCodeAuthShared();
    // mcAuthShared.shared = true;
    // mcAuthShared.sharedDetail = "shared detail";
    // this.moneyCodeAuthShared = mcAuthShared;

    // this.mcIsAuthorizedUser = true;

    // this.cardInPossession = false;

    // this.physicalCardDetails = new PhysicalCardDetails();
    // this.physicalCardDetails.cardInPossession = true;

    // this.tmxSessionId = "sessionId";
    // this.tmxResult = "result";
  }
}
