<h1>{{ "ReviewSubmit.Header" | translate }}</h1>
<p>{{ "ReviewSubmit.Detail" | translate }}</p>
<section class="wrapper">
  <ng-container *ngFor="let step of transformedSteps">
    <div [ngSwitch]="step.component">
      <section *ngSwitchCase="'FraudType'">
        <div class="section-title">
          <h4>{{ "FraudType.Header" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <div class="section-row truncate">
          <div *ngIf="gDisputeForm.fraudType === fraudType.COMPROMISED">
            {{ "FraudType.ButtonCardCompromised" | translate }}
          </div>
          <div *ngIf="gDisputeForm.fraudType === fraudType.DRIVER_MISUSE">
            {{ "FraudType.ButtonDriverMisuse" | translate }}
          </div>
          <div *ngIf="gDisputeForm.fraudType === fraudType.NO_POSSESSION">
            {{ "FraudType.ButtonCardPossession" | translate }}
          </div>
        </div>
      </section>
      <section *ngSwitchCase="'ProductSelection'">
        <div class="section-title">
          <h4>{{ "ReviewProductSelection.Header" | translate }}</h4>
          <!-- <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil> -->
        </div>
        <div class="section-row product-selection">
          <span>{{ "ReviewProductSelection.Product" | translate }}</span>
          <span>{{ "ReviewMoneyCode.FraudType." + gDisputeForm.productType?.replace(" ", "") | translate }}</span>
        </div>
      </section>

      <section *ngSwitchCase="'MoneyCodeAuth'">
        <div class="section-title">
          <h4>{{ "ReviewMoneyCode.Authorization.Header" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <div class="section-row product-selection" *ngIf="gDisputeForm.mcIsAuthorizedUser !== undefined">
          <span>{{ "ReviewMoneyCode.Authorization.Header" | translate }}</span>
          <span>{{ (gDisputeForm.mcIsAuthorizedUser ? "Common.Yes" : "Common.No") | translate }}</span>
        </div>
      </section>
      <section *ngSwitchCase="'MoneyCodeCredentialsShare'">
        <div class="section-title">
          <h4>{{ "ReviewMoneyCode.CredentialAccess.Header" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <div class="section-row product-selection" *ngIf="gDisputeForm.moneyCodeAuthShared.shared !== undefined">
          {{ "ReviewMoneyCode.CredentialAccess.Header" | translate }}
          <div class="details">
            {{
              (gDisputeForm.moneyCodeAuthShared.shared
                ? "ReviewMoneyCode.CredentialAccess.Shared"
                : "ReviewMoneyCode.CredentialAccess.NotShared"
              ) | translate
            }}
            <div>{{ gDisputeForm.moneyCodeAuthShared.sharedDetail }}</div>
          </div>
        </div>
      </section>
      <section *ngSwitchCase="'MoneyCodeIncidentOverview'">
        <div class="section-title">
          <h4>{{ "ReviewMoneyCode.IncidentOverview.Header" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <div class="section-row product-selection" *ngIf="gDisputeForm.overview">
          <span>{{ "ReviewMoneyCode.IncidentOverview.Header" | translate }}</span>
          <span>{{ gDisputeForm.overview }}</span>
        </div>
      </section>

      <section *ngSwitchCase="'PhysicalPossession'">
        <div *ngIf="!cardInPossessionUndefined" class="section-title">
          <h4>{{ "ReviewPossession.Header" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <div *ngIf="cardInPossessionUndefined" class="section-title-missing">
          <h4>{{ "ReviewPossession.Header" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <ng-container *ngIf="showPossession()">
          <div *ngIf="!cardInPossessionUndefined" class="section-row product-selection">
            <span>{{ "ReviewOTRPossession.CardLocation.Header" | translate }}</span>
            <span>{{
              (gDisputeForm.physicalCardDetails.cardInPossession ? "ReviewOTRPossession.CardLocation.InPossession" : "ReviewOTRPossession.CardLocation.Lost") | translate
            }}</span>
          </div>
        </ng-container>
      </section>
      <section *ngSwitchCase="'PoliceReport'">
        <div class="section-title">
          <h4>{{ "PageName.PoliceReport" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step?.currentPath!)"></app-edit-pencil>
        </div>
        <div *ngIf="gDisputeForm.lawEnforcementDetails.id">
          <div class="section-row">
            Reported to
            <span>
              {{ gDisputeForm.lawEnforcementDetails.officerFirstName }}
              {{ gDisputeForm.lawEnforcementDetails.officerLastName }} on
              {{ gDisputeForm.lawEnforcementDetails.dateReported | date : "mediumDate" }}.
            </span>
          </div>
          <div class="section-row">
            Report #
            <span class="hug-right">{{ gDisputeForm.lawEnforcementDetails.reportNumber }}</span>
          </div>
          <div class="section-row">
            Reference ID
            <span class="hug-right">{{ gDisputeForm.lawEnforcementDetails.referenceId }}</span>
          </div>
        </div>
      </section>

      <section *ngSwitchCase="'CurrentLocation'">
        <div class="section-title">
          <h4>{{ "PageName.CurrentLocation" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step?.currentPath!)"></app-edit-pencil>
        </div>
        <div class="section-row">
          {{
            (gDisputeForm.physicalCardDetails.cardLocationKnown
              ? "ReviewCurrentLocation.Known"
              : "ReviewCurrentLocation.Unknown"
            ) | translate
          }}
        </div>
      </section>

      <section *ngSwitchCase="'Possession'">
        <div class="section-title">
          <h4>{{ "PageName.ReviewPossession" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
        </div>
        <div class="section-row truncate" *ngIf="gDisputeForm.physicalCardDetails.cardInPossession !== undefined">
          {{
            gDisputeForm.physicalCardDetails.cardInPossession
              ? "Yes, the cardholder has the physical card."
              : "No, the cardholder does not have the physical card."
          }}
        </div>
      </section>

      <section *ngSwitchCase="'ImpactedPayments'">
        <div class="section-title">
          <h4>{{ "PageName.ImpactedPayments" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step?.currentPath!)"></app-edit-pencil>
        </div>
        <div class="section-row" *ngFor="let pd of gDisputeForm.purchaseDevices">
          <span>{{ pd.cardNumber | cardNumber }}</span>
          <span *ngIf="pd.vehicleAssetUnitDriverId">
            {{ "ImpactedPayments.PaymentMethod.VehicleAssetUniteDriverId" | translate }}:
            {{ pd?.vehicleAssetUnitDriverId }}
          </span>
          <span class="hug-right">
            {{ sumTransactions(pd.transactions) | currency }}, {{ pd.transactions.length }} transaction{{
              pd.transactions.length !== 1 ? "s" : ""
            }}
          </span>
        </div>
      </section>

      <section *ngSwitchCase="'Suspects'">
        <div *ngIf="!hasSuspectsUndefined" class="section-title">
          <h4>{{ "PageName.Suspects" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step?.currentPath!)"></app-edit-pencil>
        </div>
        <div *ngIf="hasSuspectsUndefined" class="section-title-missing">
          <h4>{{ "PageName.Suspects" | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step?.currentPath!)"></app-edit-pencil>
        </div>
        <div *ngFor="let suspect of gDisputeForm.suspects" class="section-row">
          <div class="suspect">
            <div class="suspect-col-1 truncate">{{ suspect.firstName }} {{ suspect.lastName }}</div>
            <div class="suspect-col-2 truncate">
              <div>
                {{ suspect.employmentStatus | titlecase }}, {{ suspect.employmentStartDate | date : "mediumDate" }} -
                {{ suspect.employmentEndDate | date : "mediumDate" }}
              </div>
              <div>{{ suspect.address }}</div>
              <div>{{ suspect.additionalIdentifyingInformation }}</div>
            </div>
          </div>
        </div>
      </section>

      <section *ngSwitchCase="'EmployeeStatus'">
        <div class="section-title">
          <h4>{{ "PageName." + step?.component | translate }}</h4>
          <app-edit-pencil (click)="onEditClick(step?.currentPath!)"></app-edit-pencil>
        </div>
        <div class="section-row" *ngIf="gDisputeForm.employeeStatus.isCurrentOrFormer !== undefined">
          {{
            gDisputeForm.employeeStatus.isCurrentOrFormer
              ? "Yes, the cardholder is a current or former employee."
              : "No, the cardholder has not worked with us."
          }}
        </div>
        <div class="section-row truncate" *ngIf="gDisputeForm.employeeStatus.additionalDetails">
          {{ gDisputeForm.employeeStatus.additionalDetails }}
        </div>
      </section>

      <div *ngSwitchDefault>
        <section *ngIf="step.component">
          <div class="section-title">
            <h4>{{ "PageName." + step.component | translate }}</h4>
            <app-edit-pencil (click)="onEditClick(step.currentPath)"></app-edit-pencil>
          </div>
          <div class="section-row truncate" *ngIf="getFormData(step.component)">
            {{ getFormData(step.component)!.toLocaleString() }}
          </div>
        </section>
      </div>
    </div>
  </ng-container>
</section>
