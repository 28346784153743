import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorPageComponent } from "./dispute-form-module/pages/error-page/error-page.component";
import { RouteGuard } from "./utils/route.guard";
import { NotfoundComponent } from "./app-components/notfound/notfound.component";
import { CaptchaComponent } from "./captcha-module/captcha-component/captcha.component";

const routes: Routes = [
  {
    path: "dispute",
    loadChildren: () => import("./dispute-form-module/dispute-form.module").then((m) => m.DisputeFormModule),
    canActivate: [RouteGuard],
  },
  {
    path: "captcha",
    component: CaptchaComponent,
  },
  {
    path: "error",
    component: ErrorPageComponent,
  },
  {
    path: "not-found",
    component: NotfoundComponent,
  },
  { path: "", redirectTo: "/", pathMatch: "full" },
  { path: "**", pathMatch: "full", redirectTo: "/not-found" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
