<h1>{{ "Suspects.Header" | translate }}</h1>
<p>
  {{ "Suspects.Detail" | translate }}
</p>
<mat-button-toggle-group aria-label="label" vertical [(ngModel)]="yesSuspects">
  <mat-button-toggle [value]="true" class="green" (click)="checkForNext()">
    <mat-icon>check</mat-icon>
    {{ "Suspects.ButtonYes" | translate }}
  </mat-button-toggle>
  <mat-button-toggle [value]="false" class="red" id="noSuspectsToggle" (click)="checkForNext()">
    <mat-icon>close</mat-icon>
    {{ "Suspects.ButtonNo" | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
<div class="suspects" *ngIf="yesSuspects">
  <p>{{ "Suspects.AdditionalInfoLabel" | translate }}</p>
  <form [formGroup]="susForm">
    <mat-accordion formArrayName="suspects">
      <mat-expansion-panel
        *ngFor="let sus of formSuspects.controls; let i = index"
        [expanded]="selectedTab == i"
        hideToggle="true"
        #suspanel
      >
        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
          <div id="accordionHeader">
            <div class="header-title">
              {{
                sus.get("firstName")?.value.length == 0
                  ? "Suspect"
                  : sus.get("firstName")?.value + " " + sus.get("lastName")?.value
              }}
              <mat-icon *ngIf="sus.valid">check</mat-icon>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="stopProp($event)" class="delete-menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteSuspect(sus, i)">
                <span>{{ "Common.Delete" | translate }}</span>
              </button>
            </mat-menu>
            <mat-icon class="toggle-icon">{{ suspanel.expanded ? "remove" : "add" }}</mat-icon>
          </div>
        </mat-expansion-panel-header>
        <div [formGroupName]="i" #susRows class="container">
          <input type="hidden" formControlName="id" />
          <mat-form-field class="firstName" appearance="outline">
            <mat-label>{{ "Suspects.FirstName" | translate }}</mat-label>
            <input matInput type="text" formControlName="firstName" minlength="2" maxlength="60" />
            <mat-error *ngIf="sus.get('firstName')?.hasError('required')">
              {{ "Error.Required" | translate }}
            </mat-error>
            <mat-error
              *ngIf="sus.get('firstName')?.hasError('minlength') || sus.get('firstName')?.hasError('maxlength')"
            >
              {{ "Error.MustBe2-60Chars" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="lastName" appearance="outline">
            <mat-label>{{ "Suspects.LastName" | translate }}</mat-label>
            <input matInput type="text" formControlName="lastName" minlength="2" maxlength="60" />
            <mat-error *ngIf="sus.get('lastName')?.hasError('required')">
              {{ "Error.Required" | translate }}
            </mat-error>
            <mat-error *ngIf="sus.get('lastName')?.hasError('minlength') || sus.get('lastName')?.hasError('maxlength')">
              {{ "Error.MustBe2-60Chars" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="relationship" appearance="outline">
            <mat-label>{{ "Suspects.Relationship" | translate }}</mat-label>
            <mat-select formControlName="employmentStatus">
              <mat-option value="{{ status_current }}">{{ "Suspects.RelationshipCurrent" | translate }}</mat-option>
              <mat-option value="{{ status_former }}">{{ "Suspects.RelationshipFormer" | translate }}</mat-option>
              <mat-option value="{{ status_other }}">{{ "Suspects.RelationshipOther" | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            *ngIf="sus.get('employmentStatus')?.value && sus.get('employmentStatus')?.value != 'Other'"
            class="fromDate"
          >
            <mat-label>{{ "Suspects.EmployedFrom" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="efrom"
              formControlName="employedFrom"
              [errorStateMatcher]="DateMatcher"
              [max]="maxDate"
            />
            <mat-datepicker-toggle matSuffix [for]="efrom"></mat-datepicker-toggle>
            <mat-datepicker #efrom></mat-datepicker>
            <mat-error
              *ngIf="
                sus.get('employedFrom')?.hasError('matDatepickerMax') || sus.get('employedFrom')?.hasError('required')
              "
            >
              {{ "Error.ValidDateRequired" | translate }}
            </mat-error>
        </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="sus.get('employmentStatus')?.value && sus.get('employmentStatus')?.value == 'Former Employee'"
            class="toDate"
          >
            <mat-label>{{ "Suspects.EmployedTo" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="eto"
              formControlName="employedTo"
              [errorStateMatcher]="DateMatcher"
              [max]="maxDate"
            />
            <mat-datepicker-toggle matSuffix [for]="eto"></mat-datepicker-toggle>
            <mat-datepicker #eto></mat-datepicker>
            <mat-error
              *ngIf="
                sus.get('employedTo')?.hasError('matDatepickerMax') ||
                sus.get('employedTo')?.hasError('required')
              "
              >{{ "Error.ValidDateRequired" | translate }}</mat-error
            >
            <mat-error
              *ngIf="sus.get('employedTo')?.hasError('invalidDate')"
            >{{ "Error.Date.EmployedFromMustBeLessThanEmployedTo" | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="address" appearance="outline">
            <mat-label>{{ "Suspects.Address" | translate }}</mat-label>
            <input matInput type="text" formControlName="address" maxlength="255" />
            <mat-error *ngIf="sus.get('address')?.hasError('required')">
              {{ "Error.Required" | translate }}
            </mat-error>
            <mat-error *ngIf="sus.get('address')?.hasError('minlength') || sus.get('address')?.hasError('maxlength')">
              {{ "Error.MustBe1-255Chars" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="info" appearance="outline">
            <mat-label>{{ "Suspects.AdditionalInfo" | translate }}</mat-label>
            <input matInput type="text" formControlName="additionalIdentifyingInformation" maxlength="255" />
            <mat-error
              *ngIf="
                sus.get('additionalIdentifyingInformation')?.hasError('minlength') ||
                sus.get('additionalIdentifyingInformation')?.hasError('maxlength')
              "
            >
              {{ "Error.MustBe1-255Chars" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>

  <button class="add-suspect-btn" (click)="addSuspect()">
    <mat-icon>add</mat-icon>{{ "Suspects.Suspect" | translate }}
  </button>
</div>
