import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { FraudType, LineOfBusiness, MY_DATE_FORMATS, eProductType } from "../../globalvar";
import { debounceTime } from "rxjs";
import { regexPatterns } from "../../../global-constants";
import { PurchaseDevice } from "../../../models/purchase-device/purchase-device.model";
import { Transaction } from "../../../models/purchase-device/transaction.model";
import { Common } from "../../../utils/common";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { SalesforceInfoService } from "src/app/services/sfInfo.service";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

/**
 * Component for handling the purchase device functionality.
 * This component is responsible for displaying and managing the purchase device form.
 * It handles the input and output events related to the purchase device.
 */
@Component({
  selector: "app-purchase-device",
  templateUrl: "./purchase-device.component.html",
  styleUrls: ["./purchase-device.component.scss"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
/**
 * Represents a component for managing purchase devices.
 */
/**
 * Represents the PurchaseDeviceComponent class.
 * This component is responsible for managing the purchase device information and transactions.
 * It handles the creation, deletion, and modification of transactions, as well as updating the state of the component.
 * The component also calculates the total transaction amount and extracts the minimum and maximum transaction dates.
 */
export class PurchaseDeviceComponent implements OnInit {
  @Input() purchaseDevice!: PurchaseDevice;

  @Output() updateStatePD: EventEmitter<any> = new EventEmitter();
  @Output() removePD: EventEmitter<string> = new EventEmitter();
  MAX_TRANSACTION_COUNT = 200;

  sum = 0;
  count = 0;
  pdGroup!: FormGroup;
  requiredFieldMissing:boolean =false;
  //cardNum is unique to LOB - using NAF as default
  cardNumMask = "00000";
  cardNumLabel = this._translate.instant("ImpactedPayments.PaymentMethod.CardNumberNAF");
  newCardNumberMask = "00000000009999999999";
  constructor(private _cdr: ChangeDetectorRef, private _fb: FormBuilder, private _translate: TranslateService, private _sfInfoService: SalesforceInfoService) {}

  /**
   * Initializes the component and sets up the initial state.
   */
  ngOnInit(): void {
    //otr card numbers should be 7 digits
    if (this.purchaseDevice.lineOfBusiness === LineOfBusiness.OTR) {
      if (this.purchaseDevice.purchaseDeviceType !== eProductType.MONEYCODE) {
        this.cardNumMask = "0000000";
        this.cardNumLabel = this._translate.instant("ImpactedPayments.PaymentMethod.CardNumberOTR");
      } else {
        this.cardNumMask = "000099999999999"; //4-15 digits
        this.cardNumLabel = this._translate.instant("ImpactedPayments.PaymentMethod.CardNumberMoneyCode");
      }
    }

    this.pdGroup = this._fb.group({
      cardholderFirst: new FormControl(this.purchaseDevice?.cardholderFirst, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(regexPatterns.intlName),
      ]),
      cardholderLast: new FormControl(this.purchaseDevice?.cardholderLast, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(regexPatterns.intlName),
      ]),
      vehicleAssetUnitDriverId: new FormControl(this.purchaseDevice?.vehicleAssetUnitDriverId, [
        Validators.maxLength(20),
      ]),
      cardHolderNameApplies: new FormControl(this.purchaseDevice?.cardHolderNameAppliesChanged),
      cardNumber: new FormControl(this.purchaseDevice?.cardNumber, [Validators.required]),
    });
    if (
      this.purchaseDevice.lineOfBusiness == LineOfBusiness.OTR &&
      this.purchaseDevice.purchaseDeviceType == eProductType.SMARTFUNDS
    ) {
      this.pdGroup.addControl(
        "newCardNumber",
        new FormControl(this.purchaseDevice?.cardNumber, [Validators.minLength(10), Validators.maxLength(20)])
      );
    }

    this.count = this.purchaseDevice?.transactions.length ? this.purchaseDevice?.transactions.length : 0;

    this.checkName();
    this.subscribeToControls();
    if(this.purchaseDevice.isPrePop ){
      this.newPrePopTransactions();
    }
    this.recalculatePdTotal();
    this.extractDates();
  }

  /**
   * Lifecycle hook that is called after every check of a component's view.
   * It is called immediately after the `ngAfterViewInit` and `ngAfterContentChecked` lifecycle hooks.
   * Use this hook to detect and respond to changes that occur after the view has been checked.
   */
  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  /**
   * Creates a new transaction for the purchase device.
   * If the maximum transaction count has not been reached, a new transaction is created and added to the list of transactions.
   * Otherwise, an error toast is displayed.
   */
  newTransaction(): void {
    if (this.purchaseDevice.transactions.length < this.MAX_TRANSACTION_COUNT) {
      let transaction = new Transaction();
      transaction.lineOfBusiness = this.purchaseDevice.lineOfBusiness;
      transaction.productType = this.purchaseDevice.purchaseDeviceType;
      transaction.fraudType = this.purchaseDevice.fraudType;
      transaction.expand = true;
      this.purchaseDevice.transactions.push(transaction);

      this.count = this.purchaseDevice?.transactions.length ? this.purchaseDevice?.transactions.length : 0;
      this.updateState();
    } else {
      Toast.fire({
        icon: "error",
        title: this._translate.instant("ImpactedPayments.PaymentMethod.CannotAddMoreThan"),
      });
    }
  }

  /**
   * Adds new pre-populated transactions to the purchase device.
   * If the number of transactions is less than the maximum transaction count,
   * it retrieves the SFInfo and checks if there are more transactions to add.
   * For each transaction in the SFInfo, a new Transaction object is created
   * with relevant information and added to the purchase device's transactions array.
   * The count of transactions is updated accordingly.
   * Finally, the state is updated.
   * If the maximum transaction count is reached, an error toast is displayed.
   */
  newPrePopTransactions(): void {
    if (this.purchaseDevice.transactions.length < this.MAX_TRANSACTION_COUNT) {
      let sfInfo = this._sfInfoService.getSFInfo();
      if(sfInfo.purchaseDevice.transactions.length > this.purchaseDevice.transactions.length)
      {
        sfInfo.purchaseDevice.transactions.forEach(ts =>
          {
            let transaction = new Transaction();
            transaction.lineOfBusiness = this.purchaseDevice.lineOfBusiness;
            transaction.productType = this.purchaseDevice.purchaseDeviceType;
            transaction.fraudType = this.purchaseDevice.fraudType;
            transaction.merchant = ts.merchant;
            transaction.transactionAmount = ts.transactionAmount;
            transaction.transactionId = ts.recordId;
            transaction.transactionDate = ts.transactionDate;
            transaction.merchantContactDate = ts.merchantContactDate;
            transaction.expand = true;
            transaction.isPrePop = true;
            transaction.isValid = true;
            this.purchaseDevice.transactions.push(transaction);
            this.count = this.purchaseDevice?.transactions.length ? this.purchaseDevice?.transactions.length : 0;
          });
      }
      this.updateState();
    } else {
      Toast.fire({
        icon: "error",
        title: this._translate.instant("ImpactedPayments.PaymentMethod.CannotAddMoreThan"),
      });
    }
  }

  /**
   * Deletes the purchase device.
   */
  deletePurchaseDevice(): void {
    this.removePD.emit(this.purchaseDevice.id);
  }

  /**
   * Deletes a transaction from the purchase device.
   *
   * @param guid - The ID of the transaction to be deleted.
   */
  deleteTrx(guid: any) {
    this.purchaseDevice.transactions = this.purchaseDevice.transactions.filter((t) => t.id != guid);
    this.count = this.purchaseDevice?.transactions.length ? this.purchaseDevice?.transactions.length : 0;
    this.recalculatePdTotal();
    this.extractDates();
    this.updateState();
  }

  /**
   * Checks the name for the purchase device.
   * If the cardHolderNameAppliesChanged is true, it disables the cardholderFirst and cardholderLast fields,
   * and sets requiredFieldMissing to false.
   * If the cardholderFirst or cardholderLast values are 'N/A', it sets them to empty strings,
   * sets requiredFieldMissing to true, and sets the global requiredFieldMissing to true.
   */
  checkName(){
    if(this.purchaseDevice?.cardHolderNameAppliesChanged){
      this.pdGroup.get("cardholderFirst")?.disable();
      this.pdGroup.get("cardholderLast")?.disable();
      this.purchaseDevice.requiredFieldMissing = false;
      this.requiredFieldMissing = false;
    }else if(this.pdGroup.get("cardholderFirst")?.value === 'N/A' || this.pdGroup.get("cardholderLast")?.value === 'N/A'){
      this.pdGroup.get("cardholderFirst")?.setValue("");
      this.pdGroup.get("cardholderLast")?.setValue("");
      this.purchaseDevice.requiredFieldMissing = true;
      this.requiredFieldMissing = true;
    }
  }

  /**
   * Handles the change event for the cardHolderNameApplies input.
   *
   * @param event - The event object containing information about the change event.
   */
  cardHolderNameAppliesChanged(event: any) {
    this.purchaseDevice.cardHolderNameAppliesChanged = this.pdGroup.get("cardHolderNameApplies")?.value
    if (this.pdGroup.get("cardHolderNameApplies")?.value) {
      this.pdGroup.get("cardholderFirst")?.setValue("N/A");
      this.pdGroup.get("cardholderLast")?.setValue("N/A");
      this.pdGroup.get("cardholderFirst")?.disable();
      this.pdGroup.get("cardholderLast")?.disable();
      this.purchaseDevice.requiredFieldMissing = false;
      this.requiredFieldMissing = false;
    } else {
      this.pdGroup.get("cardholderFirst")?.setValue("");
      this.pdGroup.get("cardholderLast")?.setValue("");
      this.pdGroup.get("cardholderFirst")?.enable();
      this.pdGroup.get("cardholderLast")?.enable();
    }
  }

  /**
   * Recalculates the total sum of transaction amounts for the purchase device.
   * If the count is greater than 0, it calculates the sum by mapping the transaction amounts
   * and reducing them using addition. Otherwise, it sets the sum to 0.
   */
  recalculatePdTotal() {
    if (this.count > 0) {
      this.sum = this.purchaseDevice?.transactions
        .map((a) => a.transactionAmount)
        .reduce(function (a, b) {
          return a + b;
        });
    } else {
      this.sum = 0;
    }
  }

  /**
   * Extracts the minimum and maximum dates from the transactions of the purchase device.
   * If there are transactions, it sets the `dateAbuseBegan` property to the minimum date
   * and the `dateAbuseEnded` property to the maximum date.
   */
  extractDates() {
    //get min and max dates from transactions
    if (this.purchaseDevice.transactions.length > 0) {
      let min = this.purchaseDevice.transactions.reduce(function (a, b) {
        return new Date(a.transactionDate) < new Date(b.transactionDate) ? a : b;
      });
      let max = this.purchaseDevice.transactions.reduce(function (a, b) {
        return new Date(a.transactionDate) > new Date(b.transactionDate) ? a : b;
      });
      this.purchaseDevice.dateAbuseBegan = new Date(min.transactionDate);
      this.purchaseDevice.dateAbuseEnded = new Date(max.transactionDate);
    }
  }

  /**
   * Stops the propagation of the given event.
   *
   * @param event - The event to stop propagation for.
   */
  stopProp(event: Event) {
    event.stopPropagation();
  }

  /**
   * Updates the state of the purchase device component.
   * Emits an event to notify the parent component about the state update.
   */
  updateState() {
    this.updateStatePD.emit();
  }

  /**
   * Checks if the specified input control has an error.
   * @param inputControl - The input control to check.
   * @returns A boolean indicating whether the input control has an error.
   */
  hasError(inputControl: any): boolean {
    return this.pdGroup.get(inputControl)?.status === "INVALID" ? true : false;
  }

  /**
   * Subscribes to the value changes of the form controls in the purchase-device component.
   * Updates the corresponding properties in the purchaseDevice object based on the changes.
   * Sets the requiredFieldMissing and isValid properties accordingly.
   */
  subscribeToControls() {
    this.pdGroup
      .get("cardholderFirst")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("cardholderFirst")) {
          if((changes === 'N/A' || changes == null || changes == undefined) && this.purchaseDevice.cardHolderNameAppliesChanged == false){
            this.purchaseDevice.requiredFieldMissing = true;
            this.requiredFieldMissing = true;
          }else{
            this.purchaseDevice.cardholderFirst = changes;
            this.purchaseDevice.requiredFieldMissing = false;
            this.requiredFieldMissing = false;
          }
        }
      });
    this.pdGroup
      .get("cardholderLast")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("cardholderLast")) {
          if((changes === 'N/A' || changes == null || changes == undefined) && this.purchaseDevice.cardHolderNameAppliesChanged == false){
            this.purchaseDevice.requiredFieldMissing = true;
            this.requiredFieldMissing = true;
          }else{
            this.purchaseDevice.cardholderLast = changes;
            this.purchaseDevice.requiredFieldMissing = false;
            this.requiredFieldMissing = false;
          }
        }
      });
    this.pdGroup
      .get("cardHolderNameApplies")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("cardHolderNameApplies")) {
          if(changes){
            this.purchaseDevice.requiredFieldMissing = false;
            this.requiredFieldMissing = false;
          }else{
            this.purchaseDevice.requiredFieldMissing = true;
            this.requiredFieldMissing = true;
          }
        }
      });
    this.pdGroup
      .get("cardNumber")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("cardNumber")) {
          this.purchaseDevice.cardNumber = changes;
        }
      });
    this.pdGroup
      .get("vehicleAssetUnitDriverId")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("vehicleAssetUnitDriverId")) {
          this.purchaseDevice.vehicleAssetUnitDriverId = changes;
        }
      });
    this.pdGroup
      .get("newCardNumber")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("newCardNumber")) {
          this.purchaseDevice.newCardNumber = changes;
        }
      });
    //function to return status to parent (as a boolean)
    this.pdGroup.statusChanges.subscribe(
      Common.debounce((status: any) => {
        this.purchaseDevice.isValid = status === "VALID";
        this.updateState();
      }, 1200)
    );
  }
}
