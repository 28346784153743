import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "cardNumber" })
export class CardNumberMask implements PipeTransform {
  transform(phrase: string) {
    if (phrase.length == 7) {
      phrase = "•••" + phrase[2] + " - " + phrase.slice(3, 7);
    }
    return phrase;
  }
}
