import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { SFDisputeDTO } from "../models/sf-dispute.dto";
import { LineOfBusiness } from "../dispute-form-module/globalvar";

@Injectable({
  providedIn: "root",
})
export class SalesforceInfoService {
  private _isValid$ = new BehaviorSubject<boolean>(false);
  isValid$ = this._isValid$.asObservable();
  private _sfDisputeInfo$ = new BehaviorSubject<SFDisputeDTO>({} as SFDisputeDTO);
  sfDisputeInfo$ = this._sfDisputeInfo$.asObservable();

  private _sfRecordId$ = new BehaviorSubject<string>("");
  sfRecordId$ = this._sfRecordId$.asObservable();
  private _sfKey$ = new BehaviorSubject<string>("");
  sfKey$ = this._sfKey$.asObservable();

  constructor() {}

  setValid(valid: boolean) {
    this._isValid$.next(valid);
  }

  getValid(): boolean {
    return this._isValid$.getValue();
  }

  setSFInfo(info: SFDisputeDTO) {
    //check info for required fields
    if (
      !info.accountNumber ||
      !info.platformLob ||
      !info.caseNumber ||
      (info.platformLob != LineOfBusiness.NAF && info.platformLob != LineOfBusiness.OTR)
    ) {
      this.setValid(false);
    } else {
      this.setValid(true);
      this._sfDisputeInfo$.next(info);
    }
  }

  getSFInfo(): SFDisputeDTO {
    return this._sfDisputeInfo$.getValue();
  }

  setSFRecordId(id: string) {
    this._sfRecordId$.next(id);
  }

  getSFRecordId(): string {
    return this._sfRecordId$.getValue();
  }

  setSFKey(id: string) {
    this._sfKey$.next(id);
  }

  getSFKey(): string {
    return this._sfKey$.getValue();
  }
}
