import { Component, OnInit } from "@angular/core";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { Router } from "@angular/router";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { LineOfBusiness, FraudType, NavigationEnum, eProductType } from "../../globalvar";
import { ApiService } from "src/app/services/api.service";
import { Step } from "src/app/models/navigation/navigation-path";
import { ReviewSubmitMap } from "src/app/utils/review-submit-map";
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SalesforceInfoService } from "src/app/services/sfInfo.service";
import { Transaction } from "src/app/models/purchase-device/transaction.model";

interface TransformedSteps extends Step {
  data: DisputeForm[keyof DisputeForm];
}

/**
 * Represents the ReviewSubmitClaimComponent class.
 * This component is responsible for displaying and handling the review and submission of a claim form.
 */
@Component({
  selector: "app-review-submit-claim",
  templateUrl: "./review-submit-claim.component.html",
  styleUrls: ["./review-submit-claim.component.scss"],
})
export class ReviewSubmitClaimComponent implements OnInit {
  gDisputeForm!: DisputeForm;
  navInfo: NavigationInfo = new NavigationInfo();
  maxSteps: number = 0;
  reviewMap = new ReviewSubmitMap();

  cardInPossessionUndefined = false;
  hasSuspectsUndefined = false;

  lineOfBusiness = LineOfBusiness;
  fraudType = FraudType;

  steps: Step[] = [];

  stepCondition: string = "";

  transformedSteps: TransformedSteps[] = [];

  constructor(
    private _dfService: DisputeFormService,
    public router: Router,
    private _navigationService: NavigationService,
    private _progressBarService: ProgressBarService,
    private _api: ApiService,
    private _translate: TranslateService,
    private _sfInfoService: SalesforceInfoService
  ) {}

  /**
   * Handles the click event when the edit button is clicked.
   *
   * @param path - The path to navigate to.
   */
  onEditClick = (path: string) => {this.router.navigate([`${path}`], {state: { review: true },});
  };

  /**
   * Initializes the component and sets up the necessary data and configurations.
   * This method is called after the component has been created and the input properties have been set.
   */
  ngOnInit(): void {
    // this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
    this.gDisputeForm = this._dfService.getDisputeForm();
    this.navInfo.showBack = false;
    this.navInfo.nextButtonText = "ReviewSubmit.ButtonSubmitClaim";
    this.navInfo.nextRoute = NavigationEnum.CONFIRMATION;
    this.navInfo.canContinue = this.validateDisputeForm();;
    this.navInfo.nextButtonAction = () => {
      var loading = Swal.mixin({
        title: this._translate.instant("DisputeForm.SendingForm"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      loading.fire();

      //return a new observable object with the knowledge of how to handle next/error/complete
      return new Observable((observer) => {
        try {
          this._api.postForm(this.gDisputeForm).subscribe({
            next: (res) => {
              observer.next(res);
            },
            error: (err) => {
              observer.error(err);
              Swal.fire({
                icon: "error",
                title: this._translate.instant("Error.Error"),
                html:
                  "<div style='text-align: left;'> " +
                  this._translate.instant("Error.DisputeFormMissingFields") +
                  "<br/> Form Errors: (" +
                  err?.message.split(",").join("<br/>") +
                  ")</div>",
                didOpen: () => {
                  Swal.hideLoading();
                },
              });
            },
            complete: () => {
              loading.close();
              observer.complete();
            },
          });
        } catch (err: any) {
          loading.close();
          observer.error(err);
          Swal.fire({
            icon: "error",
            title: this._translate.instant("Error.Error"),
            text: this._translate.instant("Error.DisputeFormServerError") + "(" + err.message + ")",
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      });
    };

    this._navigationService.updateNavigationInfo(this.navInfo);

    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.maxSteps = DFUtils.getStepInfo(
        this.gDisputeForm.lineOfBusiness as string,
        NavigationEnum.PRODUCT_SELECTION, //get the first step to get the max steps
        this.gDisputeForm.productType
      )?.maxSteps as number;
    } else if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      this.maxSteps = DFUtils.getStepInfo(
        this.gDisputeForm.lineOfBusiness as string,
        NavigationEnum.FRAUD_TYPE, //use first step to grab max steps
        this.gDisputeForm.fraudType == FraudType.COMPROMISED //need to account for the compromised path having multiple paths
          ? this.gDisputeForm.cardInPossession
            ? "IN_PHYSICAL_POSSESSION"
            : "NO_PHYSICAL_POSSESSION"
          : this.gDisputeForm.fraudType
      )?.maxSteps as number;
    }

    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "PageName.ReviewAndSubmit",
        nextStepName: "PageName.Confirmation",
        totalSteps: this.maxSteps + 1,
        currentStep: this.maxSteps,
      })
    );

    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_POSSESSION" : "NO_POSSESSION";
      this.stepCondition = this.gDisputeForm.productType == eProductType.MONEYCODE ? "MONEYCODE" : this.stepCondition;
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
      } else {
        this.stepCondition = this.gDisputeForm.fraudType as string;
      }
    }

    this.steps = DFUtils.getSteps(this.gDisputeForm.lineOfBusiness as string, this.stepCondition || "") || [];
    this.transformedSteps = this.steps.map((step) => {
      const formKey = this.getFormData(step.component!);
      const stepData = { ...step, data: formKey };
      return stepData;
    });
  }

  /**
   * Retrieves the form data for the specified component.
   *
   * @param component - The component name.
   * @returns The form data for the specified component, or `false` if no component is provided.
   */
  getFormData(component?: string) {
    if (component) {
      const formKey = this.reviewMap.getData(component) as keyof DisputeForm;
      return this.gDisputeForm[formKey];
    } else {
      return false;
    }
  }

  /**
   * Determines whether to show the possession section based on the line of business and fraud type.
   *
   * @returns {boolean} True if the possession section should be shown, false otherwise.
   */
  showPossession() {
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      if (this.gDisputeForm.productType != eProductType.MONEYCODE) {
        return true;
      }
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if (this.gDisputeForm.fraudType != FraudType.NO_POSSESSION) {
        return true;
      }
    }
    return false;
  }

  /**
   * Calculates the sum of transaction amounts.
   *
   * @param transactions - An array of transactions.
   * @returns The sum of transaction amounts.
   */
  sumTransactions(transactions: Transaction[]) {
    const initVal = 0;
    return transactions.reduce((acc, cur) => acc + cur.transactionAmount, initVal);
  }

  /**
   * Validates the dispute form.
   *
   * @returns {boolean} Returns true if the form is valid, otherwise false.
   */
  validateDisputeForm()
  {
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.OTR) {
      if (this.gDisputeForm.productType == eProductType.MONEYCODE) {
        return true;
      } else if (this.gDisputeForm.productType == eProductType.SMARTFUNDS) {
        return true;
      } else { //FLEETCARD
        return true;
      }
    }
    if (this.gDisputeForm.lineOfBusiness == LineOfBusiness.NAF) {
      if(this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
        if(this.gDisputeForm.cardInPossession === undefined){
          this.navInfo.requiredFieldMissing = true;
          this.cardInPossessionUndefined = true;
          return false;
        }
      } else if (this.gDisputeForm.fraudType == FraudType.DRIVER_MISUSE) {
        if(this.gDisputeForm.cardInPossession === undefined || this.gDisputeForm.hasSuspects === undefined ){
          if(this.gDisputeForm.hasSuspects === undefined) this.hasSuspectsUndefined = true;
          if(this.gDisputeForm.cardInPossession === undefined) this.cardInPossessionUndefined = true;
          this.navInfo.requiredFieldMissing = true;
          return false;
        }
      }
    }

    this.navInfo.requiredFieldMissing = false;
    return true;
  }
}
