import { ChangeDetectorRef, Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { debounceTime } from "rxjs";
import { Transaction } from "src/app/models/purchase-device/transaction.model";
import { Common } from "src/app/utils/common";
import { DateMatcher } from "src/app/utils/error-state";
import { LineOfBusiness, eProductType } from "../../globalvar";

@Component({
  selector: "app-transaction-detail",
  templateUrl: "./transaction-detail.component.html",
  styleUrls: ["./transaction-detail.component.scss"],
})
/**
 * Represents the TransactionDetailComponent class.
 * This component is responsible for displaying and managing the details of a transaction.
 * It handles input and output properties, form controls, and event emitters.
 * The component also subscribes to changes in form controls and emits events accordingly.
 */
export class TransactionDetailComponent implements OnInit {
  @Input() transaction!: Transaction;
  @Output() extractDates: EventEmitter<any> = new EventEmitter();
  @Output() recalculatePdTotal: EventEmitter<any> = new EventEmitter();
  @Output() removeTrx: EventEmitter<string> = new EventEmitter();
  @Output() updateStateTrx: EventEmitter<any> = new EventEmitter();

  DateMatcher = new DateMatcher();

  trxGroup!: FormGroup;

  maxDate: Date = new Date();
  minDate: Date = new Date();
  trxIdMask = "";

  public productTypeEnum = eProductType;

  constructor(private _cdr: ChangeDetectorRef, private fb: FormBuilder) {}

  /**
   * Initializes the component and sets up the form controls.
   * - Sets transactionId mask for NAF if lineOfBusiness is NAF.
   * - Creates form controls for merchant, transactionAmount, transactionDate, and merchantContactDate.
   * - Disables the form controls if transaction is pre-populated.
   * - Adds transactionId form control based on lineOfBusiness and productType.
   * - Subscribes to form control changes.
   * - Sets the minimum date to today - 120 days.
   */
  ngOnInit(): void {
    //set transactionId mask for NAF
    if (this.transaction.lineOfBusiness === LineOfBusiness.NAF) {
      this.trxIdMask = "000000000009999"; //4-15 digits
    }

    this.trxGroup = this.fb.group({
      merchant: new FormControl(this.transaction?.merchant, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(60),
      ]),
      transactionAmount: new FormControl(this.transaction?.transactionAmount, [
        Validators.required,
        Validators.min(0.01),
        Validators.max(99999999999999.99),
      ]),
      transactionDate: new FormControl(this.transaction?.transactionDate, [Validators.required]),
      merchantContactDate: new FormControl(this.transaction?.merchantContactDate),
    });

    if(this.transaction.isPrePop){
      this.trxGroup.disable();
    }
    //transactionId control char limit for NAF
    if (this.transaction.lineOfBusiness === LineOfBusiness.NAF) {
      this.trxGroup.addControl(
        "transactionId",
        new FormControl(this.transaction?.transactionId, [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(15),
        ])
      );
    } else if (this.transaction.productType !== eProductType.MONEYCODE) {
      //transactionId control not used for MoneyCode
      this.trxGroup.addControl(
        "transactionId",
        new FormControl(this.transaction?.transactionId, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(40),
        ])
      );
    }

    this.subscribeToControls();

    //set mindate to today-120 days
    this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() - 120));
  }

  /**
   * Deletes the transaction.
   */
  deleteTransaction(): void {
    this.removeTrx.emit(this.transaction.id);
  }

  /**
   * Checks if the specified input control has an error.
   * @param inputControl - The input control to check.
   * @returns A boolean indicating whether the input control has an error.
   */
  hasError(inputControl: any): boolean {
    return this.trxGroup.get(inputControl)?.status == "INVALID" ? true : false;
  }

  /**
   * Stops the propagation of the given event.
   *
   * @param event - The event to stop propagation for.
   */
  stopProp(event: Event) {
    event.stopPropagation();
  }

  //these calls are verbose and auto-format in an unladylike fashion. i'm sticking them down here for cleanliness.
  /**
   * Subscribes to the form controls and listens for value changes.
   * Updates the corresponding properties of the transaction object based on the changes.
   * Emits events to recalculate the total, extract dates, and update the transaction state.
   */
  subscribeToControls() {
    this.trxGroup
      .get("merchant")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("merchant")) {
          this.transaction.merchant = changes;
        }
      });
    this.trxGroup
      .get("transactionAmount")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("transactionAmount")) {
          this.transaction.transactionAmount = changes as number;
          this.recalculatePdTotal.emit();
        }
      });
    this.trxGroup
      .get("transactionId")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("transactionId")) {
          this.transaction.transactionId = changes;
        }
      });
    this.trxGroup
      .get("transactionDate")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("transactionDate")) {
          this.transaction.transactionDate = changes; //might need to be cast to date
          this.extractDates.emit();
        }
      });
    this.trxGroup
      .get("merchantContactDate")
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((changes) => {
        if (!this.hasError("merchantContactDate")) {
          this.transaction.merchantContactDate = changes;
        }
      });
    //subscribe to the form and its value changes
    this.trxGroup.statusChanges.subscribe(
      Common.debounce((status: any) => {
        this.transaction.isValid = status === "VALID";
        this.updateStateTrx.emit();
      }, 500)
    );
  }
}
