<h1>{{ "PhysicalPossession.Header" | translate }}</h1>
<p>{{ "PhysicalPossession.Detail" | translate }}</p>

<mat-button-toggle-group name="hasPhysicalPossession" vertical [(ngModel)]="hasPhysicalPossession">
  <mat-button-toggle class="green" [value]="true" (click)="canProceed()"
    ><mat-icon>check</mat-icon> {{ "PhysicalPossession.ButtonYes" | translate }}</mat-button-toggle
  >
  <mat-button-toggle class="red" [value]="false" (click)="canProceed()"
    ><mat-icon>close</mat-icon> {{ "PhysicalPossession.ButtonNo" | translate }}</mat-button-toggle
  >
</mat-button-toggle-group>