<h1>{{ "CurrentLocation.Header" | translate }}</h1>
<p>{{ "CurrentLocation.Detail" | translate }}</p>
<mat-button-toggle-group aria-label="label" vertical [(ngModel)]="isKnown">
  <mat-button-toggle [value]="true" id="locationToggle" (click)="checkForNext()">
    <mat-icon>check</mat-icon>
    {{ "CurrentLocation.Yes" | translate }}
  </mat-button-toggle>
  <mat-button-toggle [value]="false" id="noLocationToggle" (click)="checkForNext()">
    <mat-icon>close</mat-icon>
    {{ "CurrentLocation.No" | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
