<h1>{{ "AdditionalInfo.Header" | translate }}</h1>
<p>
  <strong>{{ "AdditionalInfo.ThisStepIsOptional" | translate }}</strong> {{ "AdditionalInfo.Detail" | translate }}
</p>
<form [formGroup]="addInfoForm">
  <mat-form-field appearance="outline" class="text-area">
    <mat-label>{{ "Common.DescribeWhatHappened" | translate }}</mat-label>
    <textarea
      formControlName="additionalInfo"
      matInput
      #autosize="cdkTextareaAutosize"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      maxlength="7500"
    ></textarea>
    <mat-error *ngIf="addInfoForm.get('additionalInfo')?.hasError('required')">
      {{ "Error.Required" | translate }}
    </mat-error>
    <mat-error
      *ngIf="addInfoForm.get('additionalInfo')?.hasError('minlength') ||
      addInfoForm.get('additionalInfo')?.hasError('maxlength')">
      {{ "Error.MustBe1-7500Chars" | translate }}
    </mat-error>
  </mat-form-field>
</form>
