<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.8125 11.125L12.3125 1.625C13.0938 0.84375 14.375 0.84375 15.1562 1.625L16.375 2.84375C16.4688 2.9375 16.5625 3.0625 16.625 3.15625C17.1562 3.9375 17.0625 5 16.375 5.6875L6.875 15.1875C6.84375 15.2188 6.78125 15.25 6.75 15.3125C6.4375 15.5625 6.09375 15.75 5.71875 15.875L1.9375 16.9688C1.6875 17.0625 1.40625 17 1.21875 16.7812C1 16.5938 0.9375 16.3125 1 16.0625L2.125 12.2812C2.25 11.8438 2.5 11.4375 2.8125 11.125ZM3.5625 12.7188L2.84375 15.1562L5.28125 14.4375C5.46875 14.375 5.65625 14.2812 5.8125 14.125L12.9688 6.96875L11 5.03125L3.875 12.1875C3.84375 12.1875 3.84375 12.2188 3.8125 12.25C3.6875 12.375 3.625 12.5312 3.5625 12.7188Z"
    fill="#243746"
  />
</svg>
