<h1>{{ "PoliceReport.Header" | translate }}</h1>
<p>{{ "PoliceReport.Detail" | translate }}</p>
<mat-button-toggle-group aria-label="label" vertical [(ngModel)]="yesReport">
  <mat-button-toggle class="green" [value]="true" (click)="checkForNext()">
    <mat-icon>check</mat-icon>
    {{ "PoliceReport.ButtonYes" | translate }}
  </mat-button-toggle>
  <mat-button-toggle class="red" [value]="false" (click)="checkForNext()">
    <mat-icon>close</mat-icon>
    {{ "PoliceReport.ButtonNo" | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
<div class="report" *ngIf="yesReport">
  <p>{{ "PoliceReport.AdditionalInfoLabel" | translate }}</p>
  <form [formGroup]="reportForm" class="reportForm">
    <input type="hidden" [value]="report.id" formControlName="id" />
    <mat-form-field appearance="outline" class="reportedDate">
      <mat-label>{{ "PoliceReport.DateReported" | translate }}</mat-label>
      <input matInput [matDatepicker]="reportedDate" formControlName="dateOfReport" [min]="minDate" [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="reportedDate"></mat-datepicker-toggle>
      <mat-datepicker #reportedDate></mat-datepicker>
      <mat-error
        *ngIf="
          reportForm.get('dateOfReport')?.hasError('matDatepickerMin') ||
          reportForm.get('dateOfReport')?.hasError('matDatepickerMax') ||
          reportForm.get('dateOfReport')?.hasError('required')
        "
      >
        {{ "Error.ValidDateRequired" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="firstName" appearance="outline">
      <mat-label>{{ "PoliceReport.OfficerFirst" | translate }}</mat-label>
      <input matInput type="text" formControlName="agencyOfficerFirstName" maxlength="40" />
      <mat-error *ngIf="reportForm.get('agencyOfficerFirstName')?.hasError('maxlength')">
        {{ "Error.MustBe2-40Chars" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="lastName" appearance="outline">
      <mat-label>{{ "PoliceReport.OfficerLast" | translate }}</mat-label>
      <input matInput type="text" formControlName="agencyOfficerLastName" maxlength="40" />
      <mat-error *ngIf="reportForm.get('agencyOfficerLastName')?.hasError('maxlength')">
        {{ "Error.MustBe2-40Chars" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="reportNumber" appearance="outline">
      <mat-label>{{ "PoliceReport.ReportNumber" | translate }}</mat-label>
      <input matInput type="text" formControlName="reportNumber" maxlength="50" />
      <mat-error *ngIf="reportForm.get('reportNumber')?.hasError('maxlength')">
        {{ "Error.MustBeLessThan50Chars" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="referenceId" appearance="outline">
      <mat-label>{{ "PoliceReport.RefId" | translate }}</mat-label>
      <input matInput type="text" formControlName="referenceId" maxlength="50" />
      <mat-error *ngIf="reportForm.get('referenceId')?.hasError('maxlength')">
        {{ "Error.MustBeLessThan50Chars" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="phoneNumber" appearance="outline">
      <mat-label>{{ "PoliceReport.PhoneNumber" | translate }}</mat-label>
      <input matInput type="text" formControlName="phoneNumber" mask="(000) 000-0000" />
      <mat-error *ngIf="reportForm.get('phoneNumber')?.hasError">
        {{ "Error.MustBeValidPhone" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="emailAddress" appearance="outline">
      <mat-label>{{ "PoliceReport.EmailAddress" | translate }}</mat-label>
      <input
        matInput
        type="text"
        formControlName="emailAddress"
        maxlength="255"
        pattern="[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
      />
      <mat-error *ngIf="reportForm.get('emailAddress')?.hasError('pattern')">
        {{ "Error.MustBeValidEmail" | translate }}
      </mat-error>
      <mat-error *ngIf="reportForm.get('emailAddress')?.hasError('maxlength')">
        {{ "Error.MustBe1-255Chars" | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
