import { Component, OnInit } from "@angular/core";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { ProgressInfo } from "src/app/services/models/progressInfo.model";
import { DisputeFormService } from "src/app/services/dispute-form.service";
import { DisputeForm } from "src/app/models/dispute-form.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Common } from "src/app/utils/common";
import { FraudType, NavigationEnum } from "../../globalvar";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";
import { DFUtils } from "src/app/utils/dispute-form.utils";
import { TranslateService } from "@ngx-translate/core";

/**
 * Represents the current step in the navigation process.
 * @type {NavigationEnum}
 */
const currentStep = NavigationEnum.ACTIVITY_KNOWLEDGE;

/**
 * Represents the ActivityKnowledgeComponent class.
 * This component is responsible for managing the activity knowledge form in the dispute form module.
 */
@Component({
  selector: "app-activity-knowledge",
  templateUrl: "./activity-knowledge.component.html",
  styleUrls: ["./activity-knowledge.component.scss"],
})
export class ActivityKnowledgeComponent implements OnInit {
  gDisputeForm: DisputeForm;
  actForm: FormGroup;
  readyToNext: boolean = false;
  navInfo: NavigationInfo = new NavigationInfo();
  stepCondition: string = "";
  constructor(
    private _progressBarService: ProgressBarService,
    private _navigationService: NavigationService,
    private _dfService: DisputeFormService,
    private _fb: FormBuilder,
    private _translate: TranslateService
  ) {
    //turn the back button back on
    this._navigationService.updateNavigationInfo(this.navInfo);

    this.gDisputeForm = this._dfService.getDisputeForm();
    this.actForm = this._fb.group({
      activityKnowledge: [this.gDisputeForm.activityKnowledge, Validators.required],
    });
    //different step number based on fraud type AND possession status if compromised.
    if (this.gDisputeForm.fraudType == FraudType.COMPROMISED) {
      this.stepCondition = this.gDisputeForm.cardInPossession ? "IN_PHYSICAL_POSSESSION" : "NO_PHYSICAL_POSSESSION";
    } else {
      this.stepCondition = this.gDisputeForm.fraudType as string;
    }

    this.updateProgressBar(this.stepCondition);
  }

  /**
   * Initializes the component.
   * Subscribes to the statusChanges event of the actForm control and debounces the callback function.
   * Calls the nextCheck and saveActivityKnowledge methods.
   */
  ngOnInit(): void {
    this.actForm.statusChanges.subscribe(
      Common.debounce(() => {
        this.nextCheck();
        this.saveActivityKnowledge();
      }, 500)
    );
    this.nextCheck();
  }
  /**
   * Updates the progress bar based on the given condition and next path.
   *
   * @param condition - The condition to determine the progress bar update.
   * @param nextPath - The next path to navigate to after the progress bar update (optional).
   */
  updateProgressBar(condition: any, nextPath?: NavigationEnum | null) {
    let stepInfo = DFUtils.getStepInfo(this.gDisputeForm.lineOfBusiness as string, currentStep, condition, nextPath);
    const nextStepName = this._translate.instant(
      DFUtils.getNextRouteText(this.gDisputeForm.lineOfBusiness as string, currentStep) as string
    );
    this._progressBarService.updateProgressInfo(
      Object.assign(new ProgressInfo(), {
        isVisible: true,
        stepName: "Knowledge of activity",
        nextStepName: nextStepName,
        totalSteps: stepInfo?.maxSteps,
        currentStep: stepInfo?.step,
      })
    );
  }
  /**
   * Saves the activity knowledge from the form.
   */
  saveActivityKnowledge() {
    this._dfService.setActivityKnowledge(this.actForm.value.activityKnowledge);
  }

  /**
   * Checks if the form is valid and updates the navigation information.
   */
  nextCheck() {
    if (this.actForm.valid) {
      this.readyToNext = true;
    } else {
      this.readyToNext = false;
    }
    this.navInfo.nextRoute = DFUtils.getRoute(this.gDisputeForm.lineOfBusiness as string, currentStep) as string;
    this.navInfo.canContinue = this.readyToNext;
    this._navigationService.updateNavigationInfo(this.navInfo);
  }
}
