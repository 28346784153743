<h1>
  {{ "ActivityKnowledge.Header" | translate }}
</h1>
<p>{{ "ActivityKnowledge.Detail" | translate }}</p>
<form [formGroup]="actForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ "Common.DescribeWhatHappened" | translate }}</mat-label>
    <textarea
      formControlName="activityKnowledge"
      matInput
      #autosize="cdkTextareaAutosize"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      minlength="1"
      maxlength="7500"
    ></textarea>
    <mat-error *ngIf="actForm.get('activityKnowledge')?.hasError('required')">
      {{ "Error.Required" | translate }}
    </mat-error>
    <mat-error
      *ngIf="actForm.get('activityKnowledge')?.hasError('minlength') ||
        actForm.get('activityKnowledge')?.hasError('maxlength')">
      {{ "Error.MustBe1-7500Chars" | translate }}
    </mat-error>
  </mat-form-field>
</form>
