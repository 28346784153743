import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationInfo } from "src/app/services/models/navigationInfo.model";
import { NavigationService } from "src/app/services/navigation.service";

/**
 * Represents the WexNavigationComponent class.
 * This component is responsible for handling navigation within the dispute form module.
 */
@Component({
  selector: "wex-navigation",
  templateUrl: "./wex-navigation.component.html",
  styleUrls: ["./wex-navigation.component.scss"],
})
export class WexNavigationComponent implements OnInit {
  // canContinue: boolean;
  // showBack: boolean;
  // showNext: boolean;
  // nextRoute: string;
  navInfo: NavigationInfo = new NavigationInfo();
  fromReview: boolean = false;
  private nextText: string = "Common.Next";
  private backText: string = "Common.Back";
  /**
   * Constructs a new instance of the WexNavigationComponent.
   *
   * @param _navigationService - The navigation service.
   * @param _aRoute - The activated route.
   * @param _router - The router.
   * @param _translate - The translate service.
   */
  constructor(
    private _navigationService: NavigationService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService
  ) {
    // this.canContinue = ni.canContinue;
    // this.showBack = ni.showBack;
    // this.nextRoute = ni.nextRoute;

    this._aRoute.url.subscribe(() => {
      this.fromReview = !!this._router.getCurrentNavigation()?.extras?.state?.["review"];
    });

    this._navigationService.navigationInfo.subscribe((info) => {
      if (Object.keys(info).length > 0) {
        this.nextText = info.nextButtonText;
        this.backText = info.backButtonText;

        this.navInfo.canContinue = info.canContinue;
        this.navInfo.nextRoute = info.nextRoute;
        this.navInfo.showBack = info.showBack;
        this.navInfo.showNext = info.showNext;
        this.navInfo.requiredFieldMissing = info.requiredFieldMissing;
        this.navInfo.purchaseDeviceDuplicate = info.purchaseDeviceDuplicate;
        this.navInfo.backButtonText = this._translate.instant(info?.backButtonText);
        this.navInfo.nextButtonText = this._translate.instant(info?.nextButtonText);
        this.navInfo.nextButtonAction = info?.nextButtonAction;
      }
    });

    this._translate.onLangChange.subscribe((_) => {
      this.navInfo.backButtonText = this._translate.instant(this.backText);
      this.navInfo.nextButtonText = this._translate.instant(this.nextText);
    });
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {}

  /**
   * Navigates to the next route based on the provided navigation information.
   * If a nextButtonAction function is defined, it will be executed before navigating to the next route.
   * @returns {void}
   */
  navigateNext() {
    if (
      this.navInfo.nextButtonAction &&
      this.navInfo.nextButtonAction instanceof Function &&
      this.navInfo.nextButtonAction !== undefined
    ) {
      this.navInfo.nextButtonAction().subscribe({
        next: (result) => {
          this._navigationService.next(this.navInfo.nextRoute);
        },
        error: (err) => {
          throw new Error(err);
        },
      });
    } else {
      this._navigationService.next(this.navInfo.nextRoute);
    }
  }

  /**
   * Navigates to the review and submit claim page.
   */
  navigateReview() {
    this._navigationService.next("dispute/review-submit-claim");
  }
}
